/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useFundTxStore = defineStore("fundtx", {
  actions: {
    async fundTxListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "txDate:desc",
        },
        filter: {
          "txDate:ge": payload.start,
          "txDate:le": payload.end,
          fundCategory: payload.fundCategory,
          fundTranTypeId: payload.fundTranTypeId,
          txType: payload.txType,
          sourceType: payload.sourceType,
          sourceUid: payload.sourceUid,
          targetType: payload.targetType,
          targetUid: payload.targetUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/manage-tx/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async fundTxInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/manage-tx/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async getTxTypeFetch() {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/types/tx-type";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async getfundCategoryInfoFetch() {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/types/fund-category";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async fundTxAddFetch(payload) {
      const body = {
        txDate: payload.txDate,
        fundCategory: payload.fundCategory,
        fundTranTypeId: payload.fundTranTypeId,
        txType: payload.txType,
        sourceType: payload.sourceType,
        sourceUid: payload.sourceUid,
        targetType: payload.targetType,
        targetUid: payload.targetUid,
        txAmount: payload.txAmount,
        detail: payload.detail,
        refDocUid: payload.refDocUid,
        targetAccNumber: payload.targetAccNumber,
        sourceAccNumber: payload.sourceAccNumber,
        cusName: payload.cusName,
        refNumber: payload.refNumber,
        fundTranTypeName: payload.fundTranTypeName,
        sourceAccName: payload.sourceAccName,
        targetAccName: payload.targetAccName,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/manage-tx/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async fundTxUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        txDate: payload.txDate,
        fundCategory: payload.fundCategory,
        fundTranTypeId: payload.fundTranTypeId,
        txType: payload.txType,
        sourceType: payload.sourceType,
        sourceUid: payload.sourceUid,
        targetType: payload.targetType,
        targetUid: payload.targetUid,
        txAmount: payload.txAmount,
        detail: payload.detail,
        refDocUid: payload.refDocUid,
        targetAccNumber: payload.targetAccNumber,
        sourceAccNumber: payload.sourceAccNumber,
        cusName: payload.cusName,
        refNumber: payload.refNumber,
        version: payload.version,
        fundTranTypeName: payload.fundTranTypeName,
        sourceAccName: payload.sourceAccName,
        targetAccName: payload.targetAccName,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/manage-tx/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async fundTxDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/manage-tx/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
