<template>
  <div v-if="modelValue" class="camera">
    <div class="wrapper">
      <button type="button" class="btn button-close icon-only" @click="closedCamera">
        <i class="material-icons material-symbols-outlined fs-3">close</i>
      </button>

      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <h4 class="mb-3">{{ $t("content.amntakephoto") }}</h4>
            <video v-show="isCameraOpen" class="camera-video" ref="cameraRef" :width="picWidth" :height="picHeight"
              autoplay playsinline></video>
          </div>

          <div class="col-lg-6">
            <h4 class="mb-3">{{ preview }}</h4>
            <canvas id="photoTaken" v-show="isPhotoTaken" class="canvas-photo" ref="canvasRef" :width="picWidth"
              :height="picHeight">
            </canvas>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 d-flex justify-content-end align-items-cneter">
            <!-- <a
              v-if="isPhotoTaken && isCameraOpen"
              id="downloadPhoto"
              download="Sample.jpg"
              class="d-block btn btn-primary btn-lg"
              role="button"
              @click="downloadImage"
            >
              Download
            </a> -->
            <!-- <button
              type="button"
              class="d-block btn btn-lg"
              :class="!isCameraOpen ? 'btn-primary' : 'btn-default'"
              @click="toggleCamera()"
            >
              <span v-if="!isCameraOpen">{{ open }}</span>
              <span v-else>{{ close }}</span>
            </button> -->

            <!-- <button
              v-if="!isPhotoTaken && isCameraOpen"
              type="button"
              class="d-block btn btn-primary btn-lg mx-2"
              @click="takePhoto"
            >
              {{ retake }}
            </button> -->

            <button v-if="!isPhotoTaken && isCameraOpen" type="button" class="d-block btn btn-primary btn-lg mx-2"
              @click="takePhoto">
              {{ take }}
            </button>

            <button v-if="isPhotoTaken && isCameraOpen" type="button" class="d-block btn btn-primary btn-lg mx-2"
              @click="takePhoto">
              {{ retake }}
            </button>

            <button v-if="isPhotoTaken && isCameraOpen" class="d-block btn btn-primary btn-lg mx-2"
              @click="saveImage()">
              {{ save }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// onMounted, onBeforeUnmount, toRefs
import { ref, computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const cameraRef = ref("cameraRef");
const canvasRef = ref("canvasRef");

const isCameraOpen = ref(false);
const isPhotoTaken = ref(false);

const picWidth = ref(400);
const picHeight = ref(300);

const photoFile = ref(null);



const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
    required: true,
  },
  deviceId: {
    type: String,
    default: "",
  },
  cameras: {
    type: Array,
    default: () => [],
  },
  isInit: {
    type: Boolean,
    default: false,
  },
});
const { modelValue } = toRefs(props);

const emit = defineEmits([
  "close:modelValue",
  "clear",
  "stop",
  "start",
  "pause",
  "resume",
  "error",
  "unsupported",
  "init",
  "photoTaken",
  "save",
  "download",
]);

///// computed
// const close = computed(() => t("buttons.closecam"));
// const open = computed(() => t("buttons.opencam"));
const preview = computed(() => t("buttons.preview"));
const save = computed(() => t("buttons.save"));
const take = computed(() => t("buttons.takePhoto"));
const retake = computed(() => t("buttons.retakePhoto"));

const closedCamera = () => {
  isCameraOpen.value = false;
  isPhotoTaken.value = false;
  isCameraOpen.value ? stopCameraStream() : null;
  console.log("closed");
  emit("close");
};

const startCameraStream = () => {
  const constraints = (window.constraints = {
    audio: false,
    video: true ?? {
      facingMode: {
        exact: "environment",
      },
    },
  });

  navigator.mediaDevices
    .getUserMedia(constraints)
    .then((stream) => {
      cameraRef.value.srcObject = stream;
    })
    .catch((error) => {
      alert(error, "May the browser didn't support or there is some errors.");
    });
};

const stopCameraStream = () => {
  //cameraRef.value.srcObject.getVideoTracks()[0].stop();
  const tracks = cameraRef.value.srcObject.getTracks().stop();

  tracks.forEach((track) => {
    track.stop();
    cameraRef.value.srcObject = null;
    cameraRef.value.source = null;
    cameraRef.value.srcObject.getVideoTracks()[0].stop();
  });

  console.log("CameraClosed");
};

// const toggleCamera = () => {
//   if (isCameraOpen.value) {
//     isCameraOpen.value = false;
//     isPhotoTaken.value = false;
//     isCameraOpen.value ? stopCameraStream() : null;
//     console.log("closed");
//   } else {
//     isCameraOpen.value = true;
//     startCameraStream();
//     console.log("open");
//   }
// };

const callStartCamera = () => {
  isCameraOpen.value = true;
  startCameraStream();
};

callStartCamera();

const takePhoto = () => {
  photoFile.value = null
  isPhotoTaken.value = !isPhotoTaken.value;

  const context = canvasRef.value.getContext("2d");
  const photoFromVideo = cameraRef.value;

  context.drawImage(photoFromVideo, 0, 0, picWidth.value, picHeight.value);

  const image_data_url = document
    .getElementById("photoTaken")
    .toDataURL("image/jpeg")
  photoFile.value = image_data_url
  emit("photoTaken");
};

const saveImage = () => {
  isCameraOpen.value = false;
  isPhotoTaken.value = false;
  isCameraOpen.value ? stopCameraStream() : null;
  emit("save", photoFile.value);
};

// const downloadImage = () => {
//   const download = document.getElementById("downloadPhoto");
//   const canvas = document
//     .getElementById("photoTaken")
//     .toDataURL("image/jpeg")
//     .replace("image/jpeg", "image/octet-stream");
//   download.setAttribute("href", canvas);
//   emit("download");
// };
</script>

<style lang="scss" scoped>
.camera {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11000;
}

.wrapper {
  position: relative;
  display: block;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 80%;
  min-height: 520px;
  background-color: white;
  border-radius: 16px;
  padding: 24px;
}

button {
  cursor: pointer;
}

.button-open {
  position: absolute;
  bottom: 25px;
  right: 50px;
}

.button-close {
  position: absolute;
  top: 25px;
  right: 10px;
}
</style>
