<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <ul id="seizeTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="tab1-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab"
              aria-controls="tab1" @click="onClickTab('1')">
              {{ $t("content.amnseize") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="tab2-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab"
              aria-controls="tab2" @click="onClickTab('2')">
              {{ $t("content.historyseize") }}
            </button>
          </li>
        </ul>

        <div id="seizeTabContent" class="tab-content">
          <div id="tab1" class="tab-pane fade" role="tabpanel" aria-labelledby="tab1-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amnseize") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusName_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.ticketno") }}</label>
                    <material-input type="text" v-model="tk_search" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-3 d-flex justify-content-between align-items-center"></div>
                  <div class="col-9 d-flex justify-content-end align-items-center">
                    <div class="me-3">
                      <material-button id="cardSearch-fn" color="primary" class="float-lg-start float-sm-end mb-3"
                        :disabled="tk_search === '' && cusName_search === ''" @click="loadDataApi">
                        <i class="material-icons material-symbols-outlined">search</i>
                        {{ btnSearch }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                        @click="cancelDataBtn">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ cancel }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.lasttrf") }}</th>
                          <th>{{ $t("content.names") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.transtype") }}</th>
                          <th>{{ $t("content.amnweightvalall") }}</th>
                          <th>{{ $t("content.amnqtyunitall") }}</th>
                          <th>{{ $t("content.allprice") }}</th>
                          <th>{{ $t("content.statusticket") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>
                          <th>{{ $t("content.lostcount") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listdata" :key="index">
                          <td class="text-sm font-weight-normal">{{ formatNumberString((currentPage - 1) * perPage +
                            index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.createDate) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.customerName }}
                            </span>
                            <span v-else>
                              {{ row.tkCustomerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.refNumber }}</td>
                          <td class="text-sm font-weight-normal"> {{ checkTransaction(row.type) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.quantity) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.newPawnValue) }}</td>
                          <td class="text-sm font-weight-normal">{{ checkTicketStatus(row.status) }}</td>
                          <td class="text-sm font-weight-normal">{{ row.shopManagerName }}</td>
                          <td class="text-sm font-weight-normal">{{ row.lostCount }}</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              :title="t('buttons.seed')" @click="openDetail(row)">
                              <i class="material-icons material-symbols-outlined">visibility</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lookupModal" id="detailModal">
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              :title="t('content.managelost')" @click="openDialogLostAdd(row)"
                              :disabled="row.status !== 'FINISH'">
                              <i class="material-icons material-symbols-outlined">insert_page_break</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lostTicketModal" id="lostmodal">
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              :title="t('content.managefreeze')" @click="openDialogFreezeAdd(row)"
                              :disabled="row.status !== 'FINISH'">
                              <i class="material-icons material-symbols-outlined">block</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#seizeModal" id="freezemodal">
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('content.historyview')"
                              :disabled="row.lostCount === 0" @click="viewHistory(row)">
                              <i class="material-icons material-symbols-outlined">navigate_next</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="listdata.length === 0">
                          <td colspan="11" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="14" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listdata.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                          @click="onClickHandler" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="tab2" class="tab-pane fade" role="tabpanel" aria-labelledby="tab2-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.historyseize") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-12 mb-4">
                    <div class="d-flex align-items-center w-100">
                      <label class="form-label m-0">{{ $t("content.item") }}</label>
                      <material-radio id="seize2" v-model="selectSeize" name="selInt" class="radio-horizon" value="0">
                        {{ $t("content.amnticketlost") }}
                      </material-radio>
                      <material-radio id="seize3" v-model="selectSeize" name="selInt" class="radio-horizon" value="1">
                        {{ $t("content.amnseizeact") }}
                      </material-radio>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusNameH_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.ticketno") }}</label>
                    <material-input type="text" v-model="tkH_search" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-3 d-flex justify-content-between align-items-center"></div>
                  <div class="col-9 d-flex justify-content-end align-items-center">
                    <div class="me-3">
                      <material-button id="cardSearch-fn" color="primary" class="float-lg-start float-sm-end mb-3"
                        :disabled="cusNameH_search === '' && tkH_search === ''" @click="chkSelectHistory">
                        <i class="material-icons material-symbols-outlined">search</i>
                        {{ btnSearch }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                        @click="cancelHistoryBtn">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ cancel }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th style="width: 70px">#</th>
                          <th>{{ $t("content.amndantinfo") }}</th>
                          <th style="min-width: 200px">{{ $t("content.names") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.note") }}</th>
                          <th>{{ $t("content.file") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listhistory" :key="index">
                          <td class="text-sm font-weight-normal">{{ formatNumberString((currentPage - 1) * perPage +
                            index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.createDate) }}</td>
                          <td class="text-sm font-weight-normal">{{ row.customerName }}</td>
                          <td class="text-sm font-weight-normal">{{ row.refNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ row.remark }}</td>
                          <td class="text-sm font-weight-normal">
                            <!-- :disabled="row.plDocUid === '0' || selectRdo === '0' && index !== listhistory.length - 1" -->
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              v-if="row.plDocUid !== '0' || selectRdo !== '0' && filterLastestIndex(row)"
                              @click="getFileTicket(row)" :title="t('content.fileview')">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="modal" data-bs-target="#attachmentsModal" v-show="false"
                              id="attachmenDialog">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              :title="t('buttons.seed')" @click="openDetail(row)"
                              v-show="selectRdo === '1' || selectRdo === '0' && filterLastestIndex(row)">
                              <i class=" material-icons material-symbols-outlined">visibility</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lookupModal" id="detailModal">
                            </material-button>
                            <material-button v-show="selectRdo === '0' && filterLastestIndex(row)" color="warning"
                              variant="outline" class="rounded-circle" @click="openDialogUpdate(row)"
                              :title="t('content.updatelost')">
                              <i class="material-icons material-symbols-outlined">edit_note</i>
                            </material-button>
                            <material-button v-show="selectRdo === '1'" color="warning" variant="outline"
                              class="rounded-circle" @click="openDialogUpdate(row)" :title="t('content.updatefreeze')">
                              <i class="material-icons material-symbols-outlined">edit_note</i>
                            </material-button>
                            <material-button v-show="false" color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="modal" data-bs-target="#updateLostModal" id="updateLost">
                            </material-button>
                            <material-button v-show="false" color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="modal" data-bs-target="#updateFreezeModal" id="updateFreeze">
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              v-show="selectRdo === '0' && filterLastestIndex(row)" @click="lostTicketGen(row)"
                              :title="t('content.printticketlost')">
                              <i class="material-icons material-symbols-outlined">insert_page_break</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              v-show="selectRdo === '1'" @click="openDialogCopy(row)" :title="t('content.copyprint')">
                              <i class="material-icons material-symbols-outlined">print</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" id="copyModal"
                              data-bs-toggle="modal" data-bs-target="#copyPrintModal" v-show="false">
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              v-show="selectRdo === '1'" @click="freezeNoTicketGen(row)"
                              :title="t('content.printticketcopy')">
                              <i class="material-icons material-symbols-outlined">file_copy</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle" id="unsizeModal"
                              data-bs-toggle="modal" data-bs-target="#unseizeModal" v-show="off">
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              v-show="selectRdo === '1'" @click="editRowItemsFreeze(row)"
                              :title="t('content.unfreeze')">
                              <i class="material-icons material-symbols-outlined">lock_open</i>
                            </material-button>

                          </td>
                        </tr>
                        <tr v-if="listhistory.length === 0">
                          <td colspan="10" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="14" style="text-align: right">Total Rows : {{ totalRows_sH }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listhistory.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageH" :total-items="totalRowsH"
                          :items-per-page="perPageH" @click="onClickHandlerH" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Attachments Dialog -->
        <div id="attachmentsModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style="max-width: 520px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
                  @click="visible_g = false">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div id="attachmentsImages" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-indicators" id="demo2">
                  </div>
                  <div class="carousel-inner">
                    <ul className="list-unstyled">
                      <li v-for="(item, index) in images.length" :key="index">
                        <span v-if="index === 0">
                          <div class="carousel-item active">
                            <vue-image-zoomer :regular="images[index].src" img-class="img-fluid" class="d-block w-100"
                              :zoom-amount="3" :click-zoom="true" close-pos="top-right" message-pos="top" />
                          </div>
                        </span>
                        <span v-else>
                          <div class="carousel-item">
                            <vue-image-zoomer :regular="images[index].src" img-class="img-fluid" class="d-block w-100"
                              :zoom-amount="3" :click-zoom="true" close-pos="top-right" message-pos="top" />
                          </div>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Inform Ticket Lost Dialog -->
        <div id="lostTicketModal" class="modal fade" tabindex="-1" aria-labelledby="lostTicketModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.informlost") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="lostclose">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.note") }}
                        </label>
                      </div>
                      <Textarea rows="3" v-model="lost_detail.remark" class="w-100" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <material-button color="primary" class="float-lg-start float-sm-end mb-3" data-bs-dismiss="modal"
                  :disabled="lost_detail.remark === ''" @click="checkbeforeScan">
                  <i class="material-icons material-symbols-outlined">fingerprint</i>
                  {{ save }}
                </material-button>
                <material-button id="fingerModal" color="primary" class="float-lg-start float-sm-end mb-3"
                  data-bs-toggle="modal" data-bs-target="#fingerModalNew" v-show="false">
                </material-button>
              </div>
            </div>
          </div>
        </div>

        <!-- Inform Seize Dialog -->
        <div id="seizeModal" class="modal fade" tabindex="-1" aria-labelledby="seizeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.informseize") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.file") }}
                        </label>
                      </div>
                      <input id="uploadAddFreeze" type="file" accept="application/pdf,image/*" @change="addFile" />
                    </div>

                    <div class="col-lg-6">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.edate") }}
                        </label>
                      </div>
                      <VueDatePicker v-model="freeze_detail.freezeToDate" :format="format" auto-apply :teleport="true"
                        :min-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                        :text-input="textInputOptions" fluid />
                    </div>

                    <div class="col-12 mt-2">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.note") }}
                        </label>
                      </div>
                      <Textarea rows="3" v-model="freeze_detail.remark" class="w-100" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  :disabled="freeze_detail.remark === ''" @click="addFreeze">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Update Lost Dialog -->
        <div id="updateLostModal" class="modal fade" tabindex="-1" aria-labelledby="seizeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.informlost") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.file") }}
                        </label>
                      </div>
                      <input id="uploadUpdateLost" type="file" accept="application/pdf,image/*" @change="addFile" />
                    </div>


                    <div class="col-12 mt-2">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.note") }}
                        </label>
                      </div>
                      <Textarea rows="3" v-model="lost_detail.remark" class="w-100" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  :disabled="lost_detail.remark === ''" @click="updateLost">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Update Freeze Dialog -->
        <div id="updateFreezeModal" class="modal fade" tabindex="-1" aria-labelledby="seizeModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.informseize") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.file") }}
                        </label>
                      </div>
                      <input id="uploadUpdateFreeze" type="file" accept="application/pdf,image/*" @change="addFile" />
                    </div>

                    <div class="col-lg-6">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.edate") }}
                        </label>
                      </div>
                      <VueDatePicker v-model="freeze_detail.freezeToDate" :format="format" auto-apply :teleport="true"
                        :enable-time-picker="false" month-name-format="long" :clearable="false"
                        :text-input="textInputOptions" fluid />
                    </div>

                    <div class="col-12 mt-2">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.note") }}
                        </label>
                      </div>
                      <Textarea rows="3" v-model="freeze_detail.remark" class="w-100" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  :disabled="freeze_detail.remark === ''" @click="updateFreeze">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Copy Print Dialog -->
        <div id="copyPrintModal" class="modal fade" tabindex="-1" aria-labelledby="copyPrintModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.copyprint") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.officername") }}
                        </label>
                      </div>
                      <material-input id="officename" v-model="freeze_detail.plName" type="text" />
                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.position") }}
                        </label>
                      </div>
                      <material-input id="position" v-model="freeze_detail.plPosition" type="text" />
                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.placeon") }}
                        </label>
                      </div>
                      <material-input id="placeon" v-model="freeze_detail.plWork" type="text" />
                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.date") }}
                        </label>
                      </div>
                      <!-- <material-input id="tel" type="tel" /> -->
                      <VueDatePicker v-model="freeze_detail.plCutDate" :format="formatdt" auto-apply :teleport="true"
                        :enable-time-picker="true" month-name-format="long" :clearable="false"
                        :text-input="textInputOptionsdt" />
                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.caseno") }}
                        </label>
                      </div>
                      <material-input id="caseno" v-model="freeze_detail.plCaseId" type="text" />
                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.fromplace") }}
                        </label>
                      </div>
                      <material-input id="fromplace" v-model="freeze_detail.plCaseOwner" type="text" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  :disabled="freeze_detail.plName === '' || freeze_detail.plPosition === '' || freeze_detail.plWork === ''
                    || freeze_detail.plCaseId === '' || freeze_detail.plCaseOwner === '' || freeze_detail.plCutDate === null" @click="updateFreezeGen">
                  {{ savePrint }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Unseize Dialog -->
        <div id="unseizeModal" class="modal fade" tabindex="-1" aria-labelledby="unseizeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.unseize") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.file") }}
                        </label>
                      </div>
                      <input id="uploadUnfreeze" type="file" accept="application/pdf,image/*" @change="addFile" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="unFreeze">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <!-- <th style="width: 100px">{{ $t("content.ccode") }}</th> -->
                          <th>#</th>
                          <th>{{ $t("content.cnameth") }}</th>
                          <th>{{ $t("content.cnameen") }}</th>
                          <th>{{ $t("content.docno") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td></td>
                          <td>
                            <material-input v-model="nameThC" type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input v-model="nameEnC" type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input v-model="docNumberC" type="text" :label="t('content.docno')" />
                          </td>
                          <td>
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              :disabled="nameThC.length < 2 && nameEnC.length < 2 && docNumberC.length < 5"
                              @click="loadCustomerAfListApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-for="(row, index) in customerlist" :key="index" @click="selectCusAf(row)">
                          <td>{{ formatNumberString((currentPageC - 1) * perPageC + index + 1) }}</td>
                          <td>{{ row.nameTh }}</td>
                          <td>{{ row.nameEn }}</td>
                          <td colspan="2">{{ row.docNumber }}</td>
                        </tr>
                        <tr v-if="customerlist.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="customerlist.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                          :items-per-page="perPageC" @click="onClickHandlerC" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Detail Modal -->
        <div id="lookupModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width:1240px;">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">

                <div class="row">
                  <div class="col-lg-6 mt-2">
                    <div id="lookupImages" class="carousel slide" data-bs-ride="carousel" style="max-width: 520px">
                      <div class="carousel-indicators" id="demo1">
                      </div>
                      <div class="carousel-inner">
                        <ul className="list-unstyled">
                          <li v-for="(item, index) in images.length" :key="index">
                            <span v-if="index === 0">
                              <div class="carousel-item active">
                                <vue-image-zoomer :regular="images[index].src" img-class="img-fluid"
                                  class="d-block w-100" :zoom-amount="3" :click-zoom="true" close-pos="top-right"
                                  message-pos="top" />
                              </div>
                            </span>
                            <span v-else>
                              <div class="carousel-item">
                                <vue-image-zoomer :regular="images[index].src" img-class="img-fluid"
                                  class="d-block w-100" :zoom-amount="3" :click-zoom="true" close-pos="top-right"
                                  message-pos="top" />
                              </div>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#lookupImages"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#lookupImages"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-6 mt-2">
                    <div v-if="workcaseDetail !== null" style="overflow-x:auto;">
                      <div>
                        <table class="table table-sm table-borderless">
                          <tbody>
                            <tr>

                              <td class="fs-14 text-bold">{{ $t("content.ticketno") }} :
                                <span v-if="workcaseDetail.tkRefNumber !== ''">
                                  {{ workcaseDetail.tkRefNumber }} &nbsp;
                                  <span @click='copyBtn()' style="cursor: pointer">
                                    <i class="inline-icon material-icons">content_copy</i>
                                  </span>
                                </span>
                                <span v-else>-</span>
                              </td>
                              <td class="fs-14 text-bold">
                              </td>

                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.trans") }} :
                                {{ checkTransaction(workcaseDetail.transactionType) }}
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.statusticket") }} :
                                <span v-if="workcaseDetail.status === 'FINISH'" style="color:green;">
                                  {{ tk_status }}
                                </span>
                                <span
                                  v-else-if="workcaseDetail.status === 'FREEZE' || workcaseDetail.status === 'TERMINATE'"
                                  style="color:red;">
                                  {{ tk_status }}
                                </span>
                                <span v-else>
                                  {{ tk_status }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount === 0">
                              <td class="fs-14 text-bold"> {{ $t("content.lasttrf") }} : {{
                                format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.amnduedate") }} :
                                <span style="color:red;">
                                  {{ format_date(workcaseDetail.dueDate) }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.lasttrf") }} : {{
                                format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2">
                                {{ $t("content.amnduedate") }} :
                                <span style="color:red;">
                                  {{ format_date(workcaseDetail.dueDate) }} (ขอเลื่อนเป็นวันที่ {{
                                    format_date(workcaseDetail.postponeDate) }})
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2">
                                {{ $t("content.deferpast") }} :
                                <span @click='NewTab()' style="text-decoration: underline;color:red; cursor: pointer">
                                  {{ workcaseDetail.postponeCount }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">
                                {{ $t("content.monthlyprof") }} : {{
                                  formatNumberDecimalString(workcaseDetail.interestPerMonth) }}
                              </td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.currentprof") }} :
                                <span style="color:red;">
                                  {{ formatNumberDecimalString(int_cal) }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">
                                {{ $t("content.prointerest") }} :
                                <span
                                  v-if="workcaseDetail.interestPromotion === null || workcaseDetail.interestPromotion === 0">
                                  -
                                </span>
                                <span style="color:blue;" v-else>
                                  {{ formatNumberDecimalString(workcaseDetail.interestPromotion) }}
                                </span>
                              </td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.periodpromotion") }} :
                                <span v-if="workcaseDetail.promotionDuration === 0">
                                  -
                                </span>
                                <span style="color:blue;" v-else>
                                  {{ workcaseDetail.promotionDuration }} เดือน
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.periodpawn") }} :
                                <span style="color:blue;">
                                  {{ int_period }} เดือน
                                </span>
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.amnbookno") }}
                                <span style="color:blue;">
                                  {{ workcaseDetail.tkVolNo }}
                                </span>
                                &nbsp;{{ $t("content.amnno") }}
                                <span style="color:blue;">
                                  {{ workcaseDetail.tkNo }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.amnqtypiece") }} :
                                {{ formatNumberString(workcaseDetail.ticket.totalQuantity) }}
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.allweight") }} :
                                {{ formatNumberDecimalString(workcaseDetail.ticket.totalWeight) }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.pawnvalpv") }} :
                                <span v-if="workcaseDetail.transactionType === 'PAWN'">
                                  {{ formatNumberDecimalString(0) }}
                                </span>
                                <span v-else>
                                  {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                </span>
                                <!-- <span v-if="workcaseDetail.transactionType === 'REDEEM'">
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span>
                                  <span v-else-if="workcaseDetail.transactionType === 'PAWN'">
                                    {{ formatNumberDecimalString(0) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  </span> -->
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.actpawn3") }}/{{ $t("content.actpawn4") }} :
                                {{ formatNumberDecimalString(workcaseDetail.diffValue) }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.amnanalysval") }} :
                                {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                <!-- <span v-if="workcaseDetail.transactionType !== 'REDEEM'">
                                    {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span> -->
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.amnmaxvalall") }} :
                                {{ formatNumberDecimalString(workcaseDetail.ticket.totalFullValue) }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.statusticketlost") }} :
                                <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> ไม่มี
                                </span>
                                <span v-else style="color:red;">
                                  มี ({{ workcaseDetail.lostCount }})
                                </span>
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.refnolost") }} :
                                <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> -
                                </span>
                                <span v-else style="color:red;">
                                  {{ workcaseDetail.lostRefNumber }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.workItems[0].represent || workcaseDetail.shopRepresent">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.represent") }} :
                                <span style="color:red;">
                                  {{ workcaseDetail.workItems[0].customerName }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.workItems[0].changeName">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.changeName") }} :
                                <span style="color:red;">
                                  {{ workcaseDetail.workItems[0].tkCustomerName }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.amnlocationasset") }} :
                                <span v-if="location !== ''">
                                  {{ location.fullName }}
                                </span>
                                <span v-else>
                                  -
                                </span>

                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.stockno") }} :
                                <span v-if="workcaseDetail.ticket.stockNumber !== null">
                                  {{ workcaseDetail.ticket.stockNumber }}
                                </span>
                                <span v-else>
                                  -
                                </span>

                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.migraref") }} :
                                <span v-if="workcaseDetail.migrateRefNumber !== null">
                                  {{ workcaseDetail.migrateRefNumber }}
                                </span>
                                <span v-else>
                                  -
                                </span>

                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <span v-if="listinfo.length !== 0">
                          <table class="table table-sm table-borderless">
                            <tbody>
                              <tr>
                                <td class="fs-14 text-bold">
                                  <span style="text-decoration:underline;">
                                    {{ $t("content.amnassetdetail") }}
                                  </span>
                                </td>
                              </tr>

                              <tr v-for="(row, index) in listinfo" :key="index">
                                <td class="fs-14 text-bold">
                                  {{ formatNumberString(index + 1) }}. {{ row.remark }}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                        </span>
                        <span v-else>
                          <tr>
                            <td class="fs-14 text-bold">
                              <span style="text-decoration:underline;">
                                {{ $t("content.amnassetdetail") }}
                              </span>
                              : -
                            </td>

                          </tr>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr v-if="paymentE !== ''" />
                <div class="col-12 mt-2" v-if="paymentE !== ''">
                  <div class="fs-14 text-bold">
                    <span style="text-decoration:underline;">
                      {{ $t("content.amnfinanceinfo") }}
                    </span>
                  </div>
                  <pre></pre>
                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-flush">
                        <thead class="thead-light">
                          <tr>
                            <th>#</th>
                            <th>{{ $t("content.ticketno") }}</th>
                            <th>{{ $t("content.amnbiz") }}</th>
                            <th colspan="5" class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amnincome") }}
                              </span>
                            </th>
                            <th colspan="4" class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amnoutcome") }}
                              </span>
                            </th>
                            <th colspan="4" class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.fee") }}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th colspan="3"></th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amncash") }}
                              </span>
                            </th>
                            <th colspan="2" class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amnbanktransfer") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amncreditcard") }}
                              </span>

                            </th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amncheque") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amncash") }}
                              </span>
                            </th>
                            <th colspan="3" class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amnbanktransfer") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amncash") }}
                              </span>
                            </th>
                            <th colspan="2" class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amnbanktransfer") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amncreditcard") }}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th colspan="3"></th>
                            <th style="background-color:#dfffdd;"></th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amnbankacc") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                QR Code
                              </span>
                            </th>
                            <th colspan="2" style="background-color:#dfffdd;"></th>
                            <th style="background-color:#fecac7;"></th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amnbankacc") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amntransfer_promt_mobile") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amntransfer_promt_taxid") }}
                              </span>
                            </th>
                            <th style="background-color:#CFEBFD;"></th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amnbankacc") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                QR Code
                              </span>
                            </th>
                            <th style="background-color:#CFEBFD;"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, index) in paymentE.workCases" :key="index">
                            <template v-if="row.ticket !== null">
                              <td class="text-sm font-weight-normal text-center">{{ formatNumberString(index + 1) }}
                              </td>
                              <td class="text-sm font-weight-normal text-center">{{ row.tkRefNumber }}</td>
                              <td class="text-sm font-weight-normal text-center">{{
                                checkTransaction(row.transactionType) }}</td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[0].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[0].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[1].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[1].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[6].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[6].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[2].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[2].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[3].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[3].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[0].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[0].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[1].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[1].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[4].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[4].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[5].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[5].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[0].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[0].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[1].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[1].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[6].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[6].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[2].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[2].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- New Finger Scan Dialog  -->
        <div id="fingerModalNew" class="modal fade" tabindex="-1" aria-labelledby="fingerModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-sm" style="max-width: 500px;height:auto">
            <div class="modal-content">
              <div class="modal-header justify-content-center">
                <h5 class="modal-title text-center">{{ $t("content.amnfingerscan") }} : {{ cus_detail.nameTh }}</h5>
              </div>

              <div class="modal-body">
                <h5 v-if="template_1 !== '' && template_2 !== ''" class="text-center mt-2"
                  :class="matchResult > idQuality ? `text-success` : `text-danger`">
                  MATCH SCORE : {{ matchResult }}
                </h5>
                <section class="panel">
                  <div class="row" v-if="bmpBase64f_1 !== '' || bmpBase64f_2 !== ''">
                    <div class="col-12">

                    </div>
                    <div class="col-6">
                      <figure class="figure w-100">
                        <figcaption class="figure-caption text-center">
                          <b>Before</b>
                        </figcaption>
                        <img id="FPImage1" :src="Finger1" class="img img-fluid figure-img rounded" width="300"
                          height="250" />
                      </figure>
                    </div>
                    <div class="col-6">
                      <figure class="figure w-100">
                        <figcaption class="figure-caption text-center">
                          <b>After</b>
                        </figcaption>
                        <img id="FPImage2" class="img img-fluid figure-img rounded" width="300" height="250" />
                      </figure>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-12 text-center">
                      <figure class="figure w-100">
                        <figcaption class="figure-caption text-center">
                        </figcaption>
                        <img id="FPImage2" class="img img-fluid figure-img rounded" width="300" height="250" />
                      </figure>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-center">
                      <button type="button" class="btn btn-info btn-lg" :disabled="secugen_lic === ''"
                        @click="CallSGIFPGetData(SuccessFunc2, ErrorFunc)">
                        {{ datas.scanStep ? newscan : startscan }}
                      </button>

                    </div>
                  </div>
                </section>
              </div>

              <div class="modal-footer">
                <span class="me-auto">
                  <button type="button" class="btn btn-outline-warning" @click="requestOTP"
                    v-show="count_fg >= 3 && template_2 !== ''">
                    {{ $t(`content.reqotpcode`) }}
                  </button>
                </span>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="addLost" :disabled="((matchResult < idQuality && template_1 !== '') && secugen_lic !== '')
                  || ((template_1 === '' && template_2 === '') && secugen_lic !== '')">
                  {{ confirm }}
                </button>
              </div>
            </div>
          </div>
        </div>


        <!-- OTP Dialog -->
        <div id="otpModal" class="modal fade" tabindex="-1" aria-labelledby="otpModalLabel" aria-hidden="true"
          data-bs-keyboard="false" data-bs-backdrop="static">
          <div class="modal-dialog" style="max-width: 800px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-center">{{ $t("content.amnotpcode") }}</h4>
                <button v-show="false" type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
                  id="closeOtp">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>
              <div class="modal-body">
                <p class="text-center mt-3" v-if="otpMsg !== '' && otpMsg.indexOf('ไม่สำเร็จ') > -1">
                  <span style="color: red">
                    {{ otpMsg }}
                  </span>
                </p>
                <p class="text-center mt-3" v-else-if="otpMsg !== ''">
                  <span style="color: green">
                    {{ otpMsg }}
                  </span>
                </p>
                <p class=" text-center mt-3">
                  {{ $t(`content.amnotpref`) }} :
                  <span style="color: blue">
                    {{ otpRef }}
                  </span>
                </p>
                <section class="panel">
                  <div class="d-flex justify-content-center mt-3">
                    <InputOtp v-model="otpValue" integerOnly :length="6" style="gap: 1" size="large" />
                  </div>
                  <p class="text-center mt-3">
                    {{ $t(`content.amnotptime`) }}
                    <span style="color: #05472A" id="timer-otp"></span>
                    {{ $t(`content.amnmin`) }}
                  </p>
                </section>

                <pre></pre>
                <pre></pre>
                <pre></pre>


                <div style="position: relative;width:100%;">
                  <div style="position:absolute;left:0px;">
                    <button type="button" class="btn btn-warning" :disabled="otpResend" @click="generateOTP('resend')">
                      {{ $t(`content.resend_otp`) }}
                    </button>&nbsp;
                    <span v-show="otpResend">
                      {{ $t(`content.reotptime`) }}
                      <span style="color: #05472A" id="timer-resend"></span>
                      {{ $t(`content.amnmin`) }}</span>
                  </div>

                  <div style="position:absolute;right:0px;">
                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                      :disabled="String(otpValue).length !== 6" @click="validateOTP">
                      {{ confirm }}{{ $t("content.amnotpcode") }}
                    </button>
                  </div>
                </div>
              </div>

              <pre></pre>
              <pre></pre>
              <pre></pre>

              <hr />

              <p class="text-center mt-2">
                <span @click="onOpenFingerScan()" style="text-decoration: underline;color:blue; cursor: pointer">
                  {{ $t("content.amnfingerprint") }}
                </span>
              </p>
              <pre></pre>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import ProgressSpinner from 'primevue/progressspinner';
import { reactive, computed, ref, inject, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import Textarea from 'primevue/textarea';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import ExampleFive from "@/examples/ExampleOne";
import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
import InputOtp from 'primevue/inputotp';
import Modal from 'bootstrap/js/dist/modal';

import axios from "axios";
import * as st from "@/config/setting.js";
import moment from 'moment'
import noImg from "@/assets/img/noimg.png"
import nofinger from "@/assets/img/noimg.png"


import { useCustomerStore } from '@/stores/customer'
import { useWorkCaseStore } from '@/stores/workcase'
import { useInterestStore } from '@/stores/interest'
import { useLocationStore } from '@/stores/location'
import { usePaymentGroupStore } from '@/stores/paymentgroup'
import { useLostStore } from '@/stores/lost'
import { useFreezeStore } from '@/stores/freeze'
import { useDocumentStore } from '@/stores/document'
import { useUserSecurityStore } from "@/stores/usersecurity";
import { useCashAccountStore } from '@/stores/cashaccount'


const storecus = useCustomerStore()
const storewc = useWorkCaseStore()
const storei = useInterestStore()
const storel = useLocationStore()
const storepay = usePaymentGroupStore()
const storelost = useLostStore()
const storefreeze = useFreezeStore()
const storedoc = useDocumentStore()
const userSStore = useUserSecurityStore();
const storeca = useCashAccountStore()


const { t } = useI18n();
const swal = inject("$swal");

const selectSeize = ref('0');
const selectRdo = ref('');

const activeTab = ref('tab1');

const save = computed(() => t("buttons.save"));
const cancel = computed(() => t("buttons.canc"));
const savePrint = computed(() => t("buttons.savePrint"));

const btnEdit = computed(() => t("buttons.edit"));
const btnSearch = computed(() => t("buttons.sear"));
const btnFile = computed(() => t('buttons.seed') + t("content.file"));
const confirm = computed(() => t("buttons.conf"));
const startscan = computed(() => t("buttons.sscan"));
const newscan = computed(() => t("buttons.nscan"));

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRows_sC = ref();

const currentPageH = ref(1);
const totalRowsH = ref(1);
const perPageH = ref(10);
const totalRows_sH = ref();

const nameThC = ref('')
const nameEnC = ref('')
const docNumberC = ref('')


const listinfo = ref([]);
const workcaseDetail = ref(null);
const info_cat = ref('');
const int_cal = ref(0);
const tk_status = ref('');
const location = ref('');
const int_period = ref('');

const cusUid_search = ref('');
const cusName_search = ref('');
const customerlist = ref([])

const images = ref([])
const images_f = ref([])
const images_l = ref([])

const payment_l = ref([])
const paymentE = ref('');

const loading = ref(false);

const tk_search = ref('');
const listdata = ref([])
const listhistory = ref([])

const cusUidH_search = ref('');
const cusNameH_search = ref('');
const tkH_search = ref('');

const thumb_id = ref('');
const fingerlost_uid = ref('0');
const fingerlost_temp = ref('0');

const Finger1 = ref(null);
const template_1 = ref('');
const template_2 = ref('');
const bmpBase64 = ref('');
const matchResult = ref(0);
const templatef_1 = ref('');
const templatef_2 = ref('');
const bmpBase64f_1 = ref('');
const bmpBase64f_2 = ref('');
const urls = ref([]);
const secugen_lic = ref('');
const idQuality = ref(100);
const count_scan = ref(0);
const count_fg = ref(0);
const paymentGDetail = ref(null);
const finger = ref(false);

const cus_detail = ref({
  nameTh: '',
  nameEn: '',
  birthOrRegDate: new Date(),
  address: {
    address1: "",
    subDistrict: "",
    district: "",
    province: "",
    postcode: "",
    country: ""
  },
  issuedBy: '',
  issuedDate: new Date(),
  expiredDate: new Date(),
  version: '',
  age: 0,
  docTypeUid: '0',
  docNumber: '',
  lifetime: false,
  cusShop: {
    cusTypeUid: "0",
    creditAmount: 0,
    email: "",
    phoneNo1: "",
    phoneNo2: "",
    phoneNo3: "",
    fingerPrint1Uid: "0",
    fingerPrint2Uid: "0",
    fgTemplate1Uid: "0",
    fgTemplate2Uid: "0",
    warningMsg: "",
    notifyWarning: false,
  }
})

const lost_detail = ref({
  uid: '',
  workCaseUid: '', // always required
  remark: '',
  version: '',
  plDocUid: '',
  file: null,
  fpImageUid: '0',
  fpTemplateUid: '0',
})


const freeze_detail = ref({
  uid: '',
  workCaseUid: '', // always required
  remark: '',
  version: '',
  plDocUid: '0',
  file: null,
  freezeToDate: null,
  plName: '',
  plPosition: '',
  plWork: '',
  plCaseId: '',
  plCaseOwner: '',
  plCutDate: new Date(),
  unFreezeDocUid: '0',
})

const regex = /data:.*base64,/
const mimeType = ref("");
const thumb_name = ref('');
const thumb = ref(null);

const otpValue = ref("");
const otpTime = ref(null);
const otpResend = ref(null);
const otpMsg = ref('');
const otpRef = ref('');
const otpValidate = ref(0);
const otpReTime = ref(0);

const shop_detail = ref(null)

const datas = reactive({
  hide: false,
  scanStep: false,
});


const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const formatdt = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY HH:mm');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};

const textInputOptionsdt = {
  format: 'dd/MM/yyyy HH:mm'
};

const onClickHandler = (page) => {
  // console.log('onClickHandler :' + page);
  updatePagination()
};

const onClickHandlerC = (page) => {
  updatePaginationC()
};

const onClickHandlerH = (page) => {
  // updatePaginationC()
  if (selectRdo.value === '0') {
    updatePaginationLost()
  } else if (selectRdo.value === '1') {
    updatePaginationFreeze()
  }
};


const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val);
};

const NewTab = async () => {
  sessionStorage.removeItem('deferWorkcaseUid');
  sessionStorage.setItem("deferWorkcaseUid", workcaseDetail.value.uid);
  if (sessionStorage.getItem('deferWorkcaseUid') !== null) {
    const win = window.open("#/generals/defer_items", "_blank");
    win.focus();
    sessionStorage.removeItem('deferWorkcaseUid');
  }
}

function copyBtn() {
  navigator.clipboard.writeText(workcaseDetail.value.tkRefNumber);
}



function cancelHistoryBtn() {
  cusUidH_search.value = '';
  cusNameH_search.value = '';
  tkH_search.value = '';
  currentPageH.value = 1
  listhistory.value = []
  selectSeize.value = '0';
  selectRdo.value = '';
  totalRows_sH.value = ''
}

function cancelDataBtn() {
  cusUid_search.value = '';
  cusName_search.value = '';
  tk_search.value = '';
  currentPage.value = 1
  totalRows_s.value = ''
  listdata.value = []
  currentPage.value = 1;
}

const cashAccShopApi = async (val) => {
  shop_detail.value = null
  const response = await storeca.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("shop_detail : " + JSON.stringify(response.data.data));
        shop_detail.value = response.data.data
        if (shop_detail.value.fscanLicense !== null) {
          secugen_lic.value = shop_detail.value.fscanLicense
        }

        if (shop_detail.value.fmatchScore !== 0) {
          idQuality.value = shop_detail.value.fmatchScore
        }

      }
    }
  }
}

cashAccShopApi()


const loadDataApi = async (val) => {
  let data = "";

  if (cusUid_search.value !== '' || tk_search.value !== '') {
    currentPage.value = 1
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    cusUid: cusUid_search.value,
    refNumber: tk_search.value,
    fromDate: "",
    toDate: "",
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storelost.lostSearchFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listdata.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}


async function onClickTab(val) {
  if (val === '1') {
    activeTab.value = 'tab1'
    if (cusUid_search.value !== '' || tk_search.value !== '') {
      loadDataApi()
    }
    await setfirstTab()
  } else if (val === '2') {
    activeTab.value = 'tab2'
    if (cusUidH_search.value !== '' || tkH_search.value !== '') {
      chkSelectHistory()
    }
    await setsecondTab()
  }
}

async function setfirstTab() {
  if (document.getElementById("tab2-tab")) {
    document.getElementById("tab2-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab2")) {
    document.getElementById("tab2").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.add('show', 'active')
  }
}


async function setsecondTab() {

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.remove('show', 'active')
  }

  if (document.getElementById("tab2-tab")) {
    document.getElementById("tab2-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab2")) {
    document.getElementById("tab2").classList.add('show', 'active')
  }
}


async function addFile(e) {
  thumb.value = null
  thumb_name.value = ""
  mimeType.value = ""
  let date = new Date()
  if (e.target.files[0] !== undefined) {
    thumb.value = await toBase64(e.target.files[0])
    thumb_name.value = 'file-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
    if (e.target.files[0].type === "image/jpeg") {
      thumb_name.value = thumb_name.value + ".jpg"
      mimeType.value = "image/jpeg"
    } else if (e.target.files[0].type === "image/png") {
      thumb_name.value = thumb_name.value + ".png"
      mimeType.value = "image/png"
    } else if (e.target.files[0].type === 'application/pdf') {
      thumb_name.value = thumb_name.value + ".pdf"
      mimeType.value = "application/pdf"
    }
  }
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace('data:', '')
    .replace(/^.+,/, ''));
  reader.onerror = reject;
});



async function openDetail(item) {

  workcaseDetail.value = null
  images.value = []
  images_l.value = []
  listinfo.value = []
  info_cat.value = ''
  tk_status.value = ''
  int_cal.value = 0
  location.value = ''
  int_period.value = ''
  if (activeTab.value === 'tab1') {
    await getworkCaseInfo(item.uid)
  } else if (activeTab.value === 'tab2') {
    await getworkCaseInfo(item.workCaseUid)
  }

  if (workcaseDetail.value != null) {
    loading.value = true
    // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
    await detailPayment(workcaseDetail.value.paymentGroupUid)
    images_l.value = workcaseDetail.value.ticket.images
    listinfo.value = workcaseDetail.value.ticket.collaterals
    await locationInfo()
    if (workcaseDetail.value.transactionType !== 'REDEEM') {
      await calIntInt();
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ปกติ'
      } else if (workcaseDetail.value.status === 'FREEZE') {
        tk_status.value = 'อายัด'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      } else if (workcaseDetail.value.status === 'ACTIVE') {
        tk_status.value = 'กำลังดำเนินการ'
      }

    } else {
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ไถ่ถอน'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      }
    }

    if (images_l.value.length > 0) {
      getTicketImage()
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }
  } else {
    // console.log('workcaseDetail null : ' + item.uid);
    loading.value = false
  }
}


function clearLostItem() {
  thumb.value = null
  count_fg.value = 0
  fingerlost_uid.value = '0';
  fingerlost_temp.value = '0';
  lost_detail.value = {
    uid: '',
    workCaseUid: '', // always required
    remark: '',
    version: '',
    plDocUid: '0',
    file: null,
    fpImageUid: '0',
    fpTemplateUid: '0',
  }

}

function clearFreezeItem() {
  thumb.value = null
  freeze_detail.value = {
    uid: '',
    workCaseUid: '', // always required
    remark: '',
    version: '',
    plDocUid: '0',
    file: null,
    freezeToDate: null,
    plName: '',
    plPosition: '',
    plWork: '',
    plCaseId: '',
    plCaseOwner: '',
    plCutDate: new Date(),
    unFreezeDocUid: '0',

  }

}

async function openDialogLostAdd(item) {
  // console.log('lost : ' + JSON.stringify(item));
  clearLostItem()
  lost_detail.value.workCaseUid = item.uid
  document.getElementById("lostmodal").click();
}


async function openDialogFreezeAdd(item) {
  // console.log('freeze : ' + JSON.stringify(item));
  clearFreezeItem()
  freeze_detail.value.workCaseUid = item.uid
  document.getElementById("uploadAddFreeze").value = null;
  document.getElementById("freezemodal").click();

}

async function openDialogUpdate(item) {
  if (selectRdo.value === '0') {
    clearLostItem()
    lost_detail.value = {
      uid: item.uid,
      workCaseUid: item.workCaseUid, // always required
      remark: item.remark,
      version: item.version,
      plDocUid: item.plDocUid,
      file: null,
      fpImageUid: item.fpImageUid,
      fpTemplateUid: item.fpTemplateUid,
    }
    document.getElementById("uploadUpdateLost").value = null;
    document.getElementById("updateLost").click()
  } else if (selectRdo.value === '1') {
    // console.log('openDialogUpdate : ' + JSON.stringify(item));
    clearFreezeItem()
    freeze_detail.value = {
      uid: item.uid,
      workCaseUid: item.workCaseUid, // always required
      remark: item.remark,
      version: item.version,
      plDocUid: item.plDocUid,
      file: null,
      plName: item.plName,
      plPosition: item.plPosition,
      plWork: item.plWork,
      plCaseId: item.plCaseId,
      plCaseOwner: item.plCaseOwner,
      unFreezeDocUid: item.unFreezeDocUid
    }
    if (item.freezeToDate !== null) {
      freeze_detail.value.freezeToDate = new Date(item.freezeToDate)
    }
    document.getElementById("uploadUpdateFreeze").value = null;
    document.getElementById("updateFreeze").click()
  }
}

async function openDialogCopy(item) {
  clearFreezeItem()
  freeze_detail.value = {
    uid: item.uid,
    workCaseUid: item.workCaseUid, // always required
    remark: item.remark,
    version: item.version,
    plDocUid: item.plDocUid,
    file: null,
    freezeToDate: item.freezeToDate,
    plName: item.plName,
    plPosition: item.plPosition,
    plWork: item.plWork,
    plCaseId: item.plCaseId,
    plCaseOwner: item.plCaseOwner,
    plCutDate: item.plCutDate,
    unFreezeDocUid: item.unFreezeDocUid

  }
  if (item.plCutDate !== null) {
    freeze_detail.value.plCutDate = new Date(item.plCutDate)
  } else {
    freeze_detail.value.plCutDate = new Date()
  }

  if (item.plName === null) {
    freeze_detail.value.plName = ''
  }

  if (item.plPosition === null) {
    freeze_detail.value.plPosition = ''
  }
  if (item.plWork === null) {
    freeze_detail.value.plWork = ''
  }

  if (item.plCaseId === null) {
    freeze_detail.value.plCaseId = ''
  }

  if (item.plCaseOwner === null) {
    freeze_detail.value.plCaseOwner = ''
  }


  document.getElementById("copyModal").click()
}

async function viewHistory(item) {
  tkH_search.value = item.refNumber
  selectSeize.value = '0'
  onClickTab('2')
}


async function openDialogCus() {

  if (activeTab.value === 'tab1') {
    cusUid_search.value = ''
    cusName_search.value = ''
  } else if (activeTab.value === 'tab2') {
    cusUidH_search.value = ''
    cusNameH_search.value = ''
  }
  nameThC.value = ''
  nameEnC.value = ''
  docNumberC.value = ''
  customerlist.value = []
  document.getElementById("searchNameCus").click();
}


async function selectCusAf(item) {
  // console.log('selectCusAf : ' + JSON.stringify(item));
  if (activeTab.value === 'tab1') {
    cusUid_search.value = item.uid
    cusName_search.value = item.nameTh
  } else if (activeTab.value === 'tab2') {
    cusUidH_search.value = item.uid
    cusNameH_search.value = item.nameTh
  }
  document.getElementById("closeModal").click();
}


async function loadCustomerAfListApi() {
  currentPageC.value = 1
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        customerlist.value = listd;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}



async function updatePaginationC() {
  customerlist.value = []
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerlist.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}


async function updatePagination() {
  listdata.value = []
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    cusUid: cusUid_search.value,
    refNumber: tk_search.value,
    fromDate: "",
    toDate: "",
  };

  const payload = data;

  const response = await storelost.lostSearchFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listdata.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}

async function chkSelectHistory() {
  selectRdo.value = selectSeize.value
  listhistory.value = []
  if (selectRdo.value === '0') {
    await loadLostApi()
  } else if (selectRdo.value === '1') {
    await loadFreezeApi()
  }
}

async function loadLostApi() {
  if (cusUidH_search.value !== '' || tkH_search.value !== '') {
    currentPageH.value = 1
  }
  let data = "";
  data = {
    index: currentPageH.value - 1,
    size: perPageH.value,
    cusUid: cusUidH_search.value,
    refNumber: tkH_search.value,
  };

  const payload = data;
  // console.log("pay : " + JSON.stringify(payload));
  const response = await storelost.lostListFetch(payload);
  // console.log("loadLostApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRowsH.value = response.data.data.ext.total
        totalRows_sH.value = formatNumberString(totalRowsH.value)
      }
    }
  }

}




async function loadFreezeApi() {
  if (cusUidH_search.value !== '' || tkH_search.value !== '') {
    currentPageH.value = 1
  }
  let data = "";
  data = {
    index: currentPageH.value - 1,
    size: perPageH.value,
    cusUid: cusUidH_search.value,
    refNumber: tkH_search.value,
  };

  const payload = data;
  // console.log("pay : " + JSON.stringify(payload));
  const response = await storefreeze.freezeListFetch(payload);
  // console.log("loadFreezeApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRowsH.value = response.data.data.ext.total
        totalRows_sH.value = formatNumberString(totalRowsH.value)
      }
    }
  }

}

async function updatePaginationLost() {
  listhistory.value = []
  let data = "";
  data = {
    index: currentPageH.value - 1,
    size: perPageH.value,
    cusUid: cusUidH_search.value,
    refNumber: tkH_search.value,
  };

  const payload = data;
  const response = await storelost.lostSearchFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRowsH.value = response.data.data.ext.total
        totalRows_sH.value = formatNumberString(totalRowsH.value)
      }
    }
  }

}


async function updatePaginationFreeze() {
  listhistory.value = []
  let data = "";
  data = {
    index: currentPageH.value - 1,
    size: perPageH.value,
    cusUid: cusUidH_search.value,
    refNumber: tkH_search.value,
  };

  const payload = data;
  const response = await storefreeze.freezeListFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRowsH.value = response.data.data.ext.total
        totalRows_sH.value = formatNumberString(totalRowsH.value)
      }
    }
  }

}

function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  }
  return side
}

function checkTicketStatus(str) {
  let side = ''
  if (str === 'FINISH') {
    side = 'ปกติ'
  } else if (str === 'FREEZE') {
    side = 'อายัด'
  } else if (str === 'LOST') {
    side = 'หาย'
  }
  return side
}

async function getFileTicket(item) {
  loading.value = true
  let url = []
  images.value = []
  url.push(String(st.url_api + "/doc-svc/document/info/" + item.plDocUid));
  if (url.length > 0) {
    await getFile(url)
    if (images.value.length > 0) {
      if (images.value[0].title === 'application/pdf') {
        window.open(images.value[0].src, '_blank').focus();
      } else {
        setGallery2()
        document.getElementById("attachmenDialog").click();
      }

      loading.value = false
    }
  }
}

async function getTicketImage() {

  if (images.value.length === 0) {
    let url = []
    images.value = []
    for (let i = 0; i < images_l.value.length; i++) {
      // console.log("logoUid : " + item.images[i].imageUid);
      if (images_l.value[i].imageUid !== "0") {
        url.push(String(st.url_api + "/doc-svc/document/info/" + images_l.value[i].imageUid));
      }
    }

    if (url.length > 0) {
      await getFile(url)
      // setTimeout(3000);
      if (images.value.length > 0) {
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      } else {
        let obj = {
          src: noImg,
          thumbnailImageSrc: noImg,
          alt: '',
          title: ''
        }
        images.value.push(obj)
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      }
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }

  } else {
    setGallery()
    document.getElementById("detailModal").click();
    loading.value = false
  }
}

async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {

        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L[i].data.data))
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            // console.log('file : ' + file.size + ' file t ' + file.type)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: file.type,
              filename: file_L[i].data.data.name,
            }
            images.value.push(obj)
          } else {
            // console.log('no dataFile ')
            loading.value = false
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}

function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  var ext = fileName.split('.').pop();
  let type = '';
  let imageContent = null
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  } else if (ext === 'pdf') {
    type = 'application/pdf'
  }

  if (type === 'application/pdf') {
    imageContent = atob(string64);
  } else {
    imageContent = atob(trimmedString);
  }

  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}

async function getworkCaseInfo(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;

  const response = await storewc.workCaseinfoFetch(payload);
  // console.log("getworkCaseInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value = response.data.data
      }
    }
  }
}

function setGallery() {

  var i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      // console.log('itemImg xxx : ' + i + " " + JSON.stringify(images.value[i].src))
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      // console.log('itemImg zzz : ' + i + " " + JSON.stringify(images.value[i].src))
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo1").innerHTML = y;


}


function setGallery2() {

  var i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo2").innerHTML = y;
}


async function calIntInt() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
  let postponeDate = ''
  if (workcaseDetail.value.postponeCount != 0) {
    postponeDate = moment(workcaseDetail.value.postponeDate).format('YYYY-MM-DD')
  }
  let data = "";
  data = {
    pawnDate: moment(workcaseDetail.value.workItems[0].startTime).format('YYYY-MM-DD'),
    previousTxDate: '',
    postponeDate: postponeDate,
    pawnValue: workcaseDetail.value.newPawnValue,
    promotionUid: workcaseDetail.value.promotionUid,
    interestUid: workcaseDetail.value.interestUid,
  };
  const payload = data;
  const response = await storei.interestCalculateFetch(payload);
  // console.log("calIntInt : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        res = response.data.data
        int_cal.value = Number(res.overdueInterest) + Number(res.dueInterest)
        int_period.value = res.interestPeriod
      }
    }
  }
}

async function locationInfo() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));

  let data = "";
  data = {
    shopUid: '',
    uid: workcaseDetail.value.ticket.locationUid,
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storel.locationInfoFetch(payload);
  // console.log("locationInfo : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        location.value = response.data.data
      }
    }
  }
}

async function detailPayment(item) {
  // console.log('detailPayment : ' + item);
  let data = "";
  data = {
    uid: item,
  };
  const payload = data;
  // const response = await store.dispatch("unitUpdateFetch", payload);

  const response = await storepay.paymentGroupinfoFetch(payload);

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value = response.data.data
        filterPayType()
        // console.log('detailPayment : ' + JSON.stringify(paymentE.value));
      }
    }
  }
}


function filterPayType() {
  payment_l.value = []
  let pt = [{ name: 'CASH' },
  { name: 'BANK_TRANSFER' },
  { name: 'CREDIT_CARD' },
  { name: 'CHEQUE' },
  { name: 'PROMPT_PAY_MOBILE' },
  { name: 'PROMPT_PAY_TAX_ID' },
  { name: 'QR_CODE' },
  ]
  let lsum = []
  for (let i = 0; i < pt.length; i++) {
    lsum = filterSumPay(pt[i].name)
    if (lsum.length > 0) {
      payment_l.value.push({ name: pt[i].name, pay: lsum[0].pay, receive: lsum[0].receive, fee: lsum[0].fee })
    } else {
      payment_l.value.push({ name: pt[i].name, pay: 0, receive: 0, fee: 0 })
    }
  }
}

function filterSumPay(str) {
  let nump = 0, numr = 0, numf = 0
  let lp = paymentE.value.payments.filter((c) => c.type === str);
  if (lp.length > 0) {
    let pay = 0
    let receive = 0
    let fee = 0
    for (let i = 0; i < lp.length; i++) {
      pay = pay + lp[i].pay
      receive = receive + lp[i].receive
      fee = fee + lp[i].feeReceive
      // fee = fee + lp[i].fee
    }
    nump = pay
    numr = receive
    numf = fee
  }
  let lockL = []
  lockL.push({ pay: nump, receive: numr, fee: numf })
  // console.log('lockL : ' + JSON.stringify(lockL));
  return lockL
}
function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm:ss')
  }
}

function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

async function lostTicketGen(item) {
  await getlostTicket(item.uid)
}

async function freezeGen(item) {
  await getfreeze(item.uid)
}

async function freezeNoTicketGen(item) {
  await getfreezeNoTicket(item.uid)
}


function filterLastestIndex(item) {
  let bool = true
  let ld = listhistory.value.filter((c) => c.refNumber === item.refNumber);
  if (ld.length > 1) {
    if (ld[ld.length - 1].uid !== item.uid) {
      bool = false
    }
  }
  return bool
}


async function getlostTicket(id) {
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/lostTicket/` + id, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    // console.log('blob - response ', response);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    // console.log('url : ' + url)
    window.open(url, '_blank').focus();
  } else {
    swal("โหลด PDF ไม่สำเร็จ", "", "error");
  }
}

async function getfreeze(id) {
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/freeze/` + id, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank').focus();
  } else {
    swal("โหลด PDF ไม่สำเร็จ", "", "error");
  }
}

async function getfreezeNoTicket(id) {
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/freeze/noTicket/` + id, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank').focus();
  } else {
    swal("โหลด PDF ไม่สำเร็จ", "", "error");
  }
}

async function addLost() {
  fingerlost_uid.value = '0';
  fingerlost_temp.value = '0';
  if ((cus_detail.value.cusShop.fingerPrint1Uid !== '0' || cus_detail.value.cusShop.fingerPrint2Uid !== '0')
    && template_2.value !== '') {
    await uploadFingerLost()
    lost_detail.value.fpImageUid = fingerlost_uid.value
    lost_detail.value.fpTemplateUid = fingerlost_temp.value
  }
  const payload = lost_detail.value;
  const response = await storelost.lostNewFetch(payload);
  // console.log('addLost r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function updateLost() {
  const payload = lost_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.plDocUid = thumb_id.value
  }
  const response = await storelost.lostUpdateFetch(payload);
  // console.log('addLost r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        chkSelectHistory()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function addFreeze() {
  // console.log('thumb : ' + thumb.value);
  // console.log('thumb n : ' + thumb_name.value);
  const payload = freeze_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.plDocUid = thumb_id.value
  } else {
    payload.plDocUid = '0'
  }
  if (payload.freezeToDate !== undefined) {
    payload.freezeToDate = moment(new Date(payload.freezeToDate)).format('YYYY-MM-DDT00:00:00')
  }
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storefreeze.freezeNewFetch(payload);
  if (response) {
    // console.log('addFreeze r : ' + JSON.stringify(response.data));
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateFreeze(val) {
  const payload = freeze_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
  }

  if (val !== 'unfreeze') {
    payload.plDocUid = thumb_id.value
  } else {
    payload.unFreezeDocUid = thumb_id.value
  }

  // console.log('freezeToDate : ' + payload.freezeToDate);
  if (payload.freezeToDate !== undefined) {
    payload.freezeToDate = moment(new Date(payload.freezeToDate)).format('YYYY-MM-DDT00:00:00')
  } else {
    payload.freezeToDate = null
  }
  // console.log('payload : ' + JSON.stringify(payload.freezeToDate));
  const response = await storefreeze.freezeUpdateFetch(payload);
  if (response) {
    // console.log('updateFreeze r : ' + JSON.stringify(response.data));
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (val !== 'unfreeze') {
          swal("บันทึกสำเร็จ", "", "success");
          chkSelectHistory()
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function updateFreezeGen() {
  const payload = freeze_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.plDocUid = thumb_id.value
  }

  if (payload.freezeToDate !== undefined) {
    payload.freezeToDate = moment(new Date(payload.freezeToDate)).format('YYYY-MM-DDT00:00:00')
  } else {
    payload.freezeToDate = null
  }
  if (payload.plCutDate !== undefined) {
    payload.plCutDate = moment(new Date(payload.plCutDate)).format('YYYY-MM-DDTHH:mm:ss')
  } else {
    payload.plCutDate = null
  }

  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storefreeze.freezeUpdateFetch(payload);
  if (response) {
    // console.log('addFreeze r : ' + JSON.stringify(response.data));
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await freezeGen(payload)
        chkSelectHistory()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function unFreeze() {
  const payload = freeze_detail.value;
  if (thumb.value !== null) {
    await updateFreeze('unfreeze')
  }
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storefreeze.unFreezeFetch(payload);
  if (response) {
    // console.log('unFreeze r : ' + JSON.stringify(response.data));
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        chkSelectHistory()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function addfileApi() {
  let data = "";
  let trimmedString = String(thumb.value).replace(regex, "")
  data = {
    name: thumb_name.value,
    data: trimmedString,
    mimeType: mimeType.value,
  };

  const payload = data;
  const response = await storedoc.addFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = response.data.data.uid
      } else {
        swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function editRowItemsFreeze(item) {
  clearFreezeItem()
  freeze_detail.value = {
    uid: item.uid,
    workCaseUid: item.workCaseUid, // always required
    remark: item.remark,
    version: item.version,
    plDocUid: item.plDocUid,
    file: null,
    plName: item.plName,
    plPosition: item.plPosition,
    plWork: item.plWork,
    plCaseId: item.plCaseId,
    plCaseOwner: item.plCaseOwner,
    unFreezeDocUid: item.unFreezeDocUid
  }

  document.getElementById("uploadUnfreeze").value = null;
  document.getElementById("unsizeModal").click()
}

async function clearItemCustomerInfo() {
  cus_detail.value = {
    nameTh: '',
    nameEn: '',
    birthOrRegDate: new Date(),
    address: {
      address1: "",
      subDistrict: "",
      district: "",
      province: "",
      postcode: "",
      country: ""
    },
    issuedDate: new Date(),
    expiredDate: new Date(),
    version: '',
    age: 0,
    docTypeUid: '0',
    docNumber: '',
    cusShop: {
      cusTypeUid: "0",
      creditAmount: 0,
      email: "",
      phoneNo1: "",
      phoneNo2: "",
      phoneNo3: "",
      fingerPrint1Uid: "0",
      fingerPrint2Uid: "0",
      fgTemplate1Uid: "0",
      fgTemplate2Uid: "0",
      warningMsg: "",
      notifyWarning: false,
    }
  }
}


async function getCustomerInfo(uid) {

  let data = "";
  data = {
    uid: uid,
  };

  const payload = data;
  const response = await storecus.customerInfoFetch(payload);
  // console.log("getCustomerInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        cus_detail.value = response.data.data
        if (count_fg.value === 0) {
          onScanFinger()
        } else {
          document.getElementById("fingerModal").click()
        }
      }
    }
  }
}


async function checkbeforeScan() {
  if (secugen_lic.value !== '') {
    workcaseDetail.value = null
    clearItemCustomerInfo()
    await getworkCaseInfo(lost_detail.value.workCaseUid)
    if (workcaseDetail.value != null) {
      await getCustomerInfo(workcaseDetail.value.tkCustomerInfo.customerUid)
    }
  } else {
    addLost()
  }

}


async function onScanFinger() {
  matchResult.value = 0
  count_scan.value = 0
  template_1.value = ''
  template_2.value = ''

  if (urls.value.length === 0) {
    templatef_1.value = '';
    templatef_2.value = '';
    bmpBase64f_1.value = '';
    bmpBase64f_2.value = '';
    if (cus_detail.value.cusShop.fingerPrint1Uid !== '0') {
      urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cus_detail.value.cusShop.fingerPrint1Uid));
    }
    if (cus_detail.value.cusShop.fgTemplate1Uid !== '0') {
      urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cus_detail.value.cusShop.fgTemplate1Uid));
    }
    if (cus_detail.value.cusShop.fingerPrint2Uid !== '0') {
      urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cus_detail.value.cusShop.fingerPrint2Uid));
    }
    if (cus_detail.value.cusShop.fgTemplate2Uid !== '0') {
      urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cus_detail.value.cusShop.fgTemplate2Uid));
    }

    if (urls.value.length > 0) {
      await getFilefinger()
      if (bmpBase64f_1.value !== '') {
        document.getElementById('FPImage1').src = bmpBase64f_1.value
        template_1.value = templatef_1.value
      } else if (bmpBase64f_2.value !== '') {
        document.getElementById('FPImage1').src = bmpBase64f_2.value
        template_1.value = templatef_2.value
      }
      document.getElementById('FPImage2').src = nofinger
      document.getElementById("fingerModal").click()
    } else {
      document.getElementById('FPImage2').src = nofinger
      document.getElementById("fingerModal").click()
    }

  } else if (bmpBase64f_1.value !== '' || bmpBase64f_2.value !== '') {
    if (bmpBase64f_1.value !== '') {
      document.getElementById('FPImage1').src = bmpBase64f_1.value
      template_1.value = templatef_1.value
    } else if (bmpBase64f_2.value !== '') {
      document.getElementById('FPImage1').src = bmpBase64f_2.value
      template_1.value = templatef_2.value
    }
    document.getElementById('FPImage2').src = nofinger
    document.getElementById("fingerModal").click()
  } else {
    document.getElementById('FPImage2').src = nofinger
    document.getElementById("fingerModal").click()
  }

}


function SuccessFunc2(result) {
  if (result.ErrorCode == 0) {
    /* 	Display BMP data in image tag
        BMP data is in base 64 format 
    */
    if (result != null && result.BMPBase64.length > 0) {
      document.getElementById('FPImage2').src = "data:image/bmp;base64," + result.BMPBase64;
      bmpBase64.value = result.BMPBase64
    }
    template_2.value = result.TemplateBase64;
    if (template_1.value !== "" && template_2.value !== "") {
      startMatch()
    }
  }
  else {
    console.log("Fingerprint Capture Error Code:  " + result.ErrorCode + ".\nDescription:  " + ErrorCodeToString(result.ErrorCode) + ".")
  }
}

function ErrorFunc(status) {
  /* 	
      If you reach here, user is probabaly not running the 
      service. Redirect the user to a page where he can download the
      executable and install it. 
  */
  // alert("Check if SGIBIOSRV is running; status = " + status + ":");
  console.log("Check if SGIBIOSRV is running; status = " + status + ":")
}

function CallSGIFPGetData(successCall, failCall) {
  template_2.value = ''
  document.getElementById('FPImage2').src = nofinger
  var uri = "https://localhost:8443/SGIFPCapture";
  var xmlhttp = new XMLHttpRequest();
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
      let fpobject = JSON.parse(xmlhttp.responseText);
      successCall(fpobject);
    }
    else if (xmlhttp.status == 404) {
      failCall(xmlhttp.status)
    }
  }
  xmlhttp.onerror = function () {
    failCall(xmlhttp.status);
  }
  var params = "Timeout=" + "10000";
  params += "&Quality=" + "50";
  params += "&licstr=" + encodeURIComponent(secugen_lic.value);
  params += "&templateFormat=" + "ISO";
  xmlhttp.open("POST", uri, true);
  xmlhttp.send(params);
}

function startMatch() {
  if (template_1.value == "" || template_2.value == "") {
    alert("Please scan two fingers to verify!!");
    return;
  } else {
    count_scan.value = 0
    matchScore(succMatch, failureFunc)
  }
}

function matchScore(succFunction, failFunction) {

  var uri = "https://localhost:8443/SGIMatchScore";

  var xmlhttp = new XMLHttpRequest();
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
      let fpobject = JSON.parse(xmlhttp.responseText);
      succFunction(fpobject);
    }
    else if (xmlhttp.status == 404) {
      failFunction(xmlhttp.status)
    }
  }

  xmlhttp.onerror = function () {
    failFunction(xmlhttp.status);
  }
  var params = "template1=" + encodeURIComponent(template_1.value);
  params += "&template2=" + encodeURIComponent(template_2.value);
  params += "&licstr=" + encodeURIComponent(secugen_lic.value);
  params += "&templateFormat=" + "ISO";
  xmlhttp.open("POST", uri, false);
  xmlhttp.send(params);
}

function succMatch(result) {
  // var idQuality = document.getElementById("quality").value;
  matchResult.value = 0

  if (result.ErrorCode == 0) {
    matchResult.value = Number(result.MatchingScore)
    if (result.MatchingScore >= idQuality.value) {
      // alert("MATCHED ! (" + result.MatchingScore + ")");
    } else {
      // alert("NOT MATCHED ! (" + result.MatchingScore + ")");
      if (bmpBase64f_1.value !== '' && bmpBase64f_2.value !== '' && count_scan.value === 0) {
        findOtherfinger()
      } else {
        if (count_scan.value === 0) {
          count_fg.value = count_fg.value + 1
        }
      }

    }
  } else {
    alert("Error Scanning Fingerprint ErrorCode = " + result.ErrorCode);
  }
}

function failureFunc(error) {
  alert("On Match Process, failure has been called");
}

function findOtherfinger() {
  count_scan.value = 1
  if (template_1.value === templatef_1.value) {
    document.getElementById('FPImage1').src = bmpBase64f_2.value
    template_1.value = templatef_2.value
    matchScore(succMatch, failureFunc)
  } else if (template_1.value === templatef_2.value) {
    document.getElementById('FPImage1').src = bmpBase64f_1.value
    template_1.value = templatef_1.value
    matchScore(succMatch, failureFunc)
  }
  if (Number(matchResult.value) < idQuality.value) {
    count_fg.value = count_fg.value + 1
  }
}



function ErrorCodeToString(ErrorCode) {
  var Description;
  switch (ErrorCode) {
    // 0 - 999 - Comes from SgFplib.h
    // 1,000 - 9,999 - SGIBioSrv errors 
    // 10,000 - 99,999 license errors
    case 51:
      Description = "System file load failure";
      break;
    case 52:
      Description = "Sensor chip initialization failed";
      break;
    case 53:
      Description = "Device not found";
      break;
    case 54:
      Description = "Fingerprint image capture timeout";
      break;
    case 55:
      Description = "No device available";
      break;
    case 56:
      Description = "Driver load failed";
      break;
    case 57:
      Description = "Wrong Image";
      break;
    case 58:
      Description = "Lack of bandwidth";
      break;
    case 59:
      Description = "Device Busy";
      break;
    case 60:
      Description = "Cannot get serial number of the device";
      break;
    case 61:
      Description = "Unsupported device";
      break;
    case 63:
      Description = "SgiBioSrv didn't start; Try image capture again";
      break;
    default:
      Description = "Unknown error code or Update code to reflect latest result";
      break;
  }
  return Description;
}


async function getFilefinger() {
  let file_L = []
  const keyA = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = keyA.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.value.map((url) => axios.get(url));
  await axios.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {

        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L[i].data.data) + ' file_L 2 : ' + JSON.stringify(file_L[i].data.data.name));
          if (file_L[i].data.data !== null) {
            if (file_L[i].data.data.name.includes('fingerprint1')) {
              // document.getElementById('FPImage1').src = "data:image/bmp;base64," + file_L[i].data.data.data;
              bmpBase64f_1.value = "data:image/bmp;base64," + file_L[i].data.data.data;
            } else if (file_L[i].data.data.name.includes('fingerprint2')) {
              // document.getElementById('FPImage2').src = "data:image/bmp;base64," + file_L[i].data.data.data;
              bmpBase64f_2.value = "data:image/bmp;base64," + file_L[i].data.data.data;
            } else if (file_L[i].data.data.name.includes('template1')) {
              // document.getElementById('FPImage2').src = "data:image/bmp;base64," + file_L[i].data.data.data;
              templatef_1.value = file_L[i].data.data.data;
            } else if (file_L[i].data.data.name.includes('template2')) {
              // document.getElementById('FPImage2').src = "data:image/bmp;base64," + file_L[i].data.data.data;
              templatef_2.value = file_L[i].data.data.data;
            }

          }
        }
      }
    }
  })
}

async function requestOTP() {
  document.getElementById("fingerModal").click()
  if (cus_detail.value.cusShop.phoneNo1 === null || cus_detail.value.cusShop.phoneNo1 === '') {
    swal("กรุณาเพิ่มเบอร์มือถือ", "", "error");
  } else {
    if (otpTime.value) {
      const myModal = document.getElementById('otpModal');
      const modal = new Modal(myModal); // Instantiates your modal
      modal.show();
    } else {
      await generateOTP('new')
    }
  }
}


async function generateOTP(val) {
  otpMsg.value = ''
  otpRef.value = ''
  let data = "";
  data = {
    userLevel: 'customer',
    userUid: cus_detail.value.uid,
    transactionUid: cus_detail.value.uid,
  };
  const payload = data;
  userSStore.generateOTP(payload).then((data) => {
    // console.log("generateOTP x : ", JSON.stringify(data));
    otpRef.value = data.otpPrefix
    otpValidate.value = data.validity
    if (otpRef.value !== '' && otpValidate.value !== null && val === 'new') {
      onOpenOTPModal()
    } else if (otpRef.value !== '' && otpValidate.value !== null && val === 'resend') {
      otpValue.value = ''
      otpTime.value = true
      otpResend.value = true
      otpReTime.value = 30
      timerOtp(true)
      timerResend(true)
    }
  }).catch((err) => {
    if (err.response) {
      console.error("", err);
      otpMsg.value = 'ไม่สำเร็จ : ' + err.response.data.message
      otpReTime.value = 30
      timerResend(true)
    }
  });
}

async function validateOTP() {
  otpMsg.value = ''
  let data = "";
  data = {
    transactionUid: cus_detail.value.uid,
    otpPrefix: otpRef.value,
    otp: otpValue.value,
  };

  const payload = data;
  userSStore.validateOTP(payload).then((data) => {
    // console.log("validateOTP x : ", JSON.stringify(data));
    otpMsg.value = data.message
    if (data.message === 'OK') {
      document.getElementById("closeOtp").click()
      otpValidate.value = 1
      timerOtp(false)
      otpReTime.value = 1
      timerResend(false)
      addLost()
    }
  }).catch((err) => {
    if (err.response) {
      console.error("", err);
      otpMsg.value = 'ไม่สำเร็จ : ' + err.response.data.message
      otpValidate.value = 1
      timerOtp(false)
      if (document.getElementById('timer-otp')) {
        document.getElementById('timer-otp').innerHTML = '00:00';
      }

    }
  });
}

async function onOpenOTPModal() {
  otpValue.value = ''
  otpMsg.value = ''
  otpTime.value = true
  otpResend.value = true
  const myModal = document.getElementById('otpModal');
  const modal = new Modal(myModal); // Instantiates your modal
  modal.show();
  timerOtp(true)
  otpReTime.value = 30
  timerResend(true)
}
async function onOpenFingerScan() {
  document.getElementById("closeOtp").click()
  document.getElementById("fingerModal").click()
}

function timerOtp(timerOn) {
  var m = Math.floor(otpValidate.value / 60);
  var s = otpValidate.value % 60;
  m = m < 10 ? '0' + m : m;
  s = s < 10 ? '0' + s : s;
  if (document.getElementById('timer-otp')) {
    document.getElementById('timer-otp').innerHTML = m + ':' + s;
  }

  otpValidate.value -= 1;



  if (otpValidate.value >= 0 && timerOn) {
    setTimeout(function () {
      timerOtp(otpValidate.value, timerOn);
    }, 1000);
    return;
  }


  if (otpValidate.value <= 0) {
    otpTime.value = false
  }


  if (!timerOn) {
    // Do validate stuff here
    return;
  }
  // Do timeout stuff here


}


function timerResend(timerOn) {
  var m = Math.floor(otpReTime.value / 60);
  var s = otpReTime.value % 60;
  m = m < 10 ? '0' + m : m;
  s = s < 10 ? '0' + s : s;
  if (document.getElementById('timer-resend')) {
    document.getElementById('timer-resend').innerHTML = m + ':' + s;
  }
  otpReTime.value -= 1;

  if (otpReTime.value >= 0 && timerOn) {
    setTimeout(function () {
      timerResend(timerOn);
    }, 1000);
    return;
  }

  if (otpReTime.value <= 0) {
    otpResend.value = false
  }

  if (!timerOn) {
    // Do validate stuff here
    return;
  }
  // Do timeout stuff here
}


async function uploadFingerLost() {
  let name = ''
  let date = new Date()
  if (template_2.value !== '') {
    if (cus_detail.value.cusShop !== null) {
      name = 'fingerlost-' + date.getFullYear() + date.getMonth() + 1
        + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
      await addFingerApi(name, bmpBase64.value, "image/bmp")
      if (thumb_id.value !== '0') {
        fingerlost_uid.value = thumb_id.value
      }

      name = 'templatelost-' + date.getFullYear() + date.getMonth() + 1
        + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
      await addFingerApi(name, template_2.value, "")
      if (thumb_id.value !== '0') {
        fingerlost_temp.value = thumb_id.value
      }
    }

  }
}


async function addFingerApi(name, datafile, type) {
  thumb_id.value = '0'
  let data = "";
  data = {
    name: name + ".bmp",
    data: datafile,
    mimeType: type,
  };

  const payload = data;
  // console.log('addfileApi : ' + JSON.stringify(payload));
  const response = await storedoc.addFetch(payload);
  // console.log('addFingerApi : ' + JSON.stringify(response.data));
  if (response.status === 200) {
    if (response.data.rtnDesc === "success") {
      thumb_id.value = response.data.data.uid
    } else {
      swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
    }
  }
}


onMounted(() => {

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.add('show', 'active')
  }
});


</script>

<style lang="scss" scoped>
.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
