<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <ul id="financeTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="first-finance" class="nav-link" data-bs-toggle="tab" data-bs-target="#firstFinance"
              type="button" role="tab" aria-controls="firstFinance" aria-selected="true" @click="onClickTab('1')">
              {{ $t("content.amnfinancelist") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-finance" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondFinance"
              type="button" role="tab" aria-controls="secondFinance" aria-selected="false" @click="onClickTab('2')">
              {{ $t("content.amnalljobs") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="third-finance" class="nav-link" data-bs-toggle="tab" data-bs-target="#thirdFinance"
              type="button" role="tab" aria-controls="thirdFinance" aria-selected="false" :disabled="uid_edit === ''"
              @click="onClickTab('3')">
              {{ $t("content.amnfinancenmanage") }}
            </button>
          </li>
        </ul>


        <div id="financeTabContent" class="tab-content">
          <div id="firstFinance" class="tab-pane fade" role="tabpanel" aria-labelledby="first-finance">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amnfinancesearch") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-10">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusName_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                      @click="loadDataQ02Api">
                      <i class="material-icons material-symbols-outlined">search</i> {{ $t("buttons.sear") }}
                    </material-button>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.jobId") }}</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnanalysvalnew") }}</th>
                          <th>{{ $t("content.amnanalysvalreal") }}</th>
                          <th>{{ $t("content.statuspay") }}</th>
                          <th>{{ $t("content.status") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>
                        </tr>
                      </thead>

                      <tbody v-for="(i, pay1) in listQ02" :key="pay1" :data-group-index="pay1">
                        <tr class="group-id-ticket" :class="i.status === 'Finish' ? '' : 'bg-working'">
                          <td class="text-sm font-weight-normal">
                            <material-checkbox v-model="i.chk" :checked="i.chk" :disabled="i.status !== 'Finish'">
                            </material-checkbox>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="8">
                            <div class="group-tickect" style="padding: 0.5rem !important">
                              <h6 class="mb-0">
                                <b>{{ t("content.amngroupticket") }} #{{ pay1 + 1 }} ({{ $t("content.jobId") }}:
                                  {{ i.jobId }}) -
                                  {{ i.customerName }}
                                  ({{ $t("content.amntotalval") }} : {{
                                    formatNumberString(i.roundTotalPay) }}
                                  {{ $t("content.curency") }})
                                </b>
                              </h6>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="white" class="float-lg-start float-sm-end mb-0"
                              @click="toggleRowQ02(pay1)">
                              <i v-if="i.tg" class="material-icons material-symbols-outlined">expand_less</i>
                              <i v-else class="material-icons material-symbols-outlined">expand_more</i>
                            </material-button>
                          </td>
                        </tr>
                        <template v-if="i.tg">
                          <tr v-for="(j, set1) in i.items" :key="set1">
                            <template v-if="j.ticket !== null">
                              <td class="text-sm font-weight-normal">{{ set1 + 1 }}</td>
                              <td class="text-sm font-weight-normal">{{ j.jobId }}</td>
                              <td class="text-sm font-weight-normal">{{ i.start }}
                              </td>
                              <td class="text-sm font-weight-normal">
                                {{ j.tkRefNumber }}
                              </td>
                              <td class="text-sm font-weight-normal">
                                {{ checkTransaction(j.type) }}
                              </td>
                              <td class="text-sm font-weight-normal" :class="j.totalReceive > 0
                                ? `text-success`
                                : j.totalPay > 0 ? `text-danger` : ''
                                ">
                                <span v-if="j.migration">
                                  <span
                                    v-if="j.migrationType === 'PAWN' || j.migrationType === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                                <span v-else>
                                  <span v-if="j.type === 'PAWN' || j.type === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-sm font-weight-normal" :class="j.totalReceive > 0
                                ? `text-success`
                                : j.totalPay > 0 ? `text-danger` : ''
                                ">
                                <span v-if="j.migration">
                                  <span
                                    v-if="j.migrationType === 'PAWN' || j.migrationType === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                                <span v-else>
                                  <span v-if="j.type === 'PAWN' || j.type === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-sm font-weight-normal">
                                <span v-if="j.status !== 'TERMINATE'">
                                  <span v-if="j.waitForCondition"> {{ $t("content.amnstateacting") }}</span>
                                  <span v-if="!j.waitForCondition"> {{ $t("content.amnstateending") }}</span>
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td class="text-sm font-weight-normal"> {{ checkProcess(j.status) }}</td>
                              <td class="text-sm font-weight-normal">{{ i.latestUserName }}</td>
                            </template>

                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12 d-flex justify-content-end">
                    <div class="me-3">
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3" @click="chkSelect">
                        <i class="material-icons material-symbols-outlined">lock</i>
                        {{ $t("content.amnselectlist") }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="secondFinance" class="tab-pane fade" role="tabpanel" aria-labelledby="second-finance">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amnalljobs") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-10">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusNameH_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                      @click="loadDataQ02OwnerApi">
                      <i class="material-icons material-symbols-outlined">search</i> {{ $t("buttons.sear") }}
                    </material-button>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.jobId") }}</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnanalysvalnew") }}</th>
                          <th>{{ $t("content.amnanalysvalreal") }}</th>
                          <th>{{ $t("content.statuspay") }}</th>
                          <th>{{ $t("content.status") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>

                      <tbody v-for="(i, pay2) in listQ02O" :key="pay2" :data-group-index="pay2">
                        <tr class="group-id-ticket" :class="i.status == 'Finish' ? '' : 'bg-working'">
                          <td class="text-sm font-weight-normal">
                            <div class="px-2">{{ pay2 + 1 }}</div>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="9">
                            <div class="group-tickect" style="padding: 0.5rem !important">
                              <h6 class="mb-0">
                                <b>{{ t("content.amngroupticket") }} #{{ pay2 + 1 }} ({{ $t("content.jobId") }}:
                                  {{ i.jobId }}) -
                                  {{ i.customerName }} ({{ $t("content.amntotalval") }} : {{
                                    formatNumberString(i.roundTotalPay)
                                  }}
                                  {{ $t("content.curency") }})
                                </b>
                              </h6>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="white" class="float-lg-start float-sm-end mb-0"
                              @click="toggleRowQ02O(pay2)">
                              <i v-if="i.tg" class="material-icons material-symbols-outlined">expand_less</i>
                              <i v-else class="material-icons material-symbols-outlined">expand_more</i>
                            </material-button>
                          </td>
                        </tr>
                        <template v-if="i.tg">
                          <tr v-for="(j, set2) in i.items" :key="set2">
                            <template v-if="j.ticket !== null">
                              <td class="text-sm font-weight-normal">{{ set2 + 1 }}</td>
                              <td class="text-sm font-weight-normal">{{ j.jobId }}</td>
                              <td class="text-sm font-weight-normal">{{ i.start }}
                              </td>
                              <td class="text-sm font-weight-normal">
                                {{ j.tkRefNumber }}
                              </td>
                              <td class="text-sm font-weight-normal">
                                {{ checkTransaction(j.type) }}
                              </td>
                              <td class="text-sm font-weight-normal" :class="j.totalReceive > 0
                                ? `text-success`
                                : j.totalPay > 0 ? `text-danger` : ''
                                ">
                                <span v-if="j.migration">
                                  <span
                                    v-if="j.migrationType === 'PAWN' || j.migrationType === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                                <span v-else>
                                  <span v-if="j.type === 'PAWN' || j.type === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-sm font-weight-normal" :class="j.totalReceive > 0
                                ? `text-success`
                                : j.totalPay > 0 ? `text-danger` : ''
                                ">
                                <span v-if="j.migration">
                                  <span
                                    v-if="j.migrationType === 'PAWN' || j.migrationType === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                                <span v-else>
                                  <span v-if="j.type === 'PAWN' || j.type === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-sm font-weight-normal">
                                <span v-if="j.status !== 'TERMINATE'">
                                  <span v-if="j.waitForCondition"> {{ $t("content.amnstateacting") }}</span>
                                  <span v-if="!j.waitForCondition"> {{ $t("content.amnstateending") }}</span>
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td class="text-sm font-weight-normal"> {{ checkProcess(j.status) }}</td>
                              <td class="text-sm font-weight-normal">{{ i.latestUserName }}</td>
                              <td v-if="set2 == 0" class="text-sm font-weight-normal" :rowspan="i.items.length">
                                <material-button color="info" variant="outline" class="rounded-circle"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('buttons.edit')"
                                  @click="openTab(i)">
                                  <i class="material-icons material-symbols-outlined">edit</i>
                                </material-button>
                                <material-button color="warning" variant="outline" class="rounded-circle"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="t('content.amnunlock')"
                                  @click="onUnLockDialog(i)">
                                  <i class="material-icons material-symbols-outlined">lock_open</i>
                                </material-button>
                              </td>
                            </template>

                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="thirdFinance" class="tab-pane fade show" role="tabpanel" aria-labelledby="third-finance">
            <div class="card mt-2">
              <div class="card-header">
                <h5>{{ $t("content.amnbankinfo") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.mbank") }}</label>
                    <select class="form-select" v-model="cus_bank.bankUid">
                      <option value="0" disabled selected>{{ $t('content.select') }}
                      </option>
                      <option v-for="(j, fs8) in listbank" :key="fs8" :value="j.uid">
                        {{ j.nameTh }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">
                      {{ $t("content.amnbankaccno") }}</label>
                    <material-input type="text" v-model="cus_bank.accNumber" @input="onlyDigitsBk" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t("content.cbankacc") }}</label>
                    <material-input type="text" v-model="cus_bank.accName" />
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3" :disabled="cus_bank.bankUid === '0' || cus_bank.accName === ''
                      || cus_bank.accNumber === ''" @click="saveNewItems">
                      <i class="material-icons material-symbols-outlined">add</i>
                      {{ $t("buttons.addd") }}
                    </material-button>
                  </div>

                </div>
                <div class="row mt-4">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>{{ $t('content.cdefault') }}</th>
                          <th>{{ $t('content.cbankname') }}</th>
                          <th>{{ $t('content.cbankno') }}</th>
                          <th>{{ $t('content.cbankacc') }}</th>
                          <th style="width: 120px;">{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(i, bd1) in listcusbank" :key="bd1" :data-group-index="bd1">
                          <template v-if="i.uid !== cus_bank.uid">
                            <td class="text-sm font-weight-normal">
                              <material-checkbox v-model="i.defaultValue" :checked="i.defaultValue" :disabled="true">
                              </material-checkbox>
                            </td>
                            <td class="text-sm font-weight-normal">{{ filterCusBank(i.bankUid)[0].nameTh }}</td>
                            <td class="text-sm font-weight-normal">{{ i.accNumber }}</td>
                            <td class="text-sm font-weight-normal">{{ i.accName }}</td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                                @click="editRowItems(i)">
                                <i class="material-icons material-symbols-outlined">edit</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                                @click="deleteRowItemsCB(i)">
                                <i class="material-icons material-symbols-outlined">delete</i>
                              </material-button>
                            </td>
                          </template>
                          <template v-else>
                            <td class="text-sm font-weight-normal">
                              <material-checkbox v-model="cus_bank.defaultValue" :checked="cus_bank.defaultValue">
                              </material-checkbox>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <select class="form-select" v-model="cus_bank.bankUid">
                                <option value="0" disabled selected>{{ $t('content.select') }}
                                </option>
                                <option v-for="(j, fs8) in listbank" :key="fs8" :value="j.uid">
                                  {{ j.nameTh }}
                                </option>
                              </select>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-input type="text" v-model="cus_bank.accNumber" @input="onlyDigitsBk" />
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-input type="text" v-model="cus_bank.accName" />
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" :disabled="cus_bank.bankUid === '0' || cus_bank.accName === ''
                                  || cus_bank.accNumber === ''" @click="saveEditRowItems()">
                                <i class="material-icons material-symbols-outlined">save</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnCancel"
                                @click="cancelEditRowItems()">
                                <i class="material-icons material-symbols-outlined">close</i>
                              </material-button>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                      <tfoot v-show="listcusbank.length > 0">
                        <tr>
                          <td colspan="7" style="text-align: right">Total Rows : {{ totalRowsBk }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <ul v-show="listcusbank.length > 0" class="pagination justify-content-end">
                  <div class="project-container">
                    <div class="example-one">
                      <vue-awesome-paginate v-model="currentPageBk" :total-items="totalRowsBk"
                        :items-per-page="perPageBk" @click="onClickHandlerBk" />
                    </div>
                  </div>
                </ul>
              </div>
            </div>

            <div class="card mt-4" v-show="cus_detail !== null">
              <div class="card-header mb-0">
                <div class="row no-gutters">
                  <div class="col-8 d-flex">
                    <h5 class="m-0">{{ $t('content.cabove') }}</h5>
                  </div>
                  <div class="col-4 d-flex justify-content-end align-items-center">
                    <div class="me-3">
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3" @click="openDialogCusAf"
                        :disabled="cus_detail === null">
                        <i class="material-icons material-symbols-outlined">search</i> {{ $t("buttons.sear") }}
                      </material-button>
                      <button id="searchCusAfModal" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchAfModal" v-show="false">
                      </button>
                    </div>
                    <div class="me-3">
                      <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                        @click="toggleInfo">
                        <i v-if="datas.hide" class="material-icons material-symbols-outlined">expand_less</i>
                        <i v-else class="material-icons material-symbols-outlined">expand_more</i>
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div v-if="datas.hide" class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th style="width: 70px;">#</th>
                          <th>{{ $t('content.names') + " (" + $t("content.th") + ")" }}</th>
                          <th>{{ $t('content.names') + " (" + $t("content.en") + ")" }}</th>
                          <th>{{ $t('content.amncredituse') }}</th>
                          <th style="width: 100px;">{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(row, index) in listcusaffiliate" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumber((currentPageCuf - 1) * perPageCuf + index + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.nameTh }}</td>
                          <td class="text-sm font-weight-normal">{{ row.nameEn }}</td>
                          <td class="text-sm font-weight-normal"></td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              :disabled="chkCusBankPay(row) || chkCusPromtMobile(row) || chkCusPromtTax(row)"
                              @click="deleteRowItemsCaf(row)">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-if="listcusaffiliate.length > 0">
                        <tr class="group-sub-header">
                          <td class="text-lg font-weight-bold" colspan="2"><b>{{ $t('content.amnrelatenum') }}</b>
                          </td>
                          <td class="text-lg font-weight-bold"><b>{{ formatNumber(totalRowsCuf) }}</b></td>
                          <td class="text-lg font-weight-bold"><b></b></td>
                          <td class="text-lg font-weight-bold"><b></b></td>
                        </tr>
                      </tfoot>
                    </table>
                    <ul v-show="listcusaffiliate.length > 0" class="pagination justify-content-end">
                      <div class="project-container">
                        <div class="example-one">
                          <vue-awesome-paginate v-model="currentPageCuf" :total-items="totalRowsCuf"
                            :items-per-page="perPageCuf" @click="onClickHandlerCuf" />
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4" v-show="cus_detail !== null">
              <div class="card-header mb-0">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0">{{ $t('content.mobileinfo') }}</h5>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.cmobile") }}1*</label>
                    <span v-if="cus_detail !== null">
                      <InputMask v-model="cus_detail.cusShop.phoneNo1" mask="(999) 999-9999" fluid />
                    </span>
                    <span v-else>
                      <material-input type="text" disabled />
                    </span>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.cmobile") }}2</label>
                    <span v-if="cus_detail !== null">
                      <InputMask v-model="cus_detail.cusShop.phoneNo2" mask="(999) 999-9999" fluid />
                    </span>
                    <span v-else>
                      <material-input type="text" disabled />
                    </span>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.cmobile") }}3</label>
                    <span v-if="cus_detail !== null">
                      <InputMask v-model="cus_detail.cusShop.phoneNo3" mask="(999) 999-9999" fluid />
                    </span>
                    <span v-else>
                      <material-input type="text" disabled />
                    </span>
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0"></label>
                    <div class="flex flex-wrap justify-center gap-4">
                      <div class="flex items-center d-inline-block">
                        <span v-if="cus_detail !== null">
                          <material-checkbox id="asGroup" v-model="cus_detail.cusShop.notifyWarning"
                            :checked="cus_detail.cusShop.notifyWarning">
                            {{ $t("content.cenanoti") }}
                          </material-checkbox>
                        </span>
                        <span v-else>
                          <material-checkbox id="asGroup" disabled>
                            {{ $t("content.cenanoti") }}
                          </material-checkbox>
                        </span>

                      </div>
                    </div>
                  </div>
                  <div class="col-lg-1 col-sm-3">
                    <label class="form-label ms-0 png"></label>
                    <material-button color="primary" class="float-lg-start float-sm-end mb-3"
                      :disabled="workcaseDetail === null || cus_detail === null || chkCusMobile()"
                      @click="updateCustomerShop('data')" :title="t('buttons.save') + $t('content.gcustomer')">
                      <i class="material-icons material-symbols-outlined">save</i>
                    </material-button>
                  </div>
                </div>
              </div>
            </div>


            <div class="card mt-4">
              <div class="card-header">
                <h5>{{ $t("content.amnfinanceinfo") }}</h5>
              </div>

              <div class="card-body pt-0">
                <div class="row">

                  <div class="table-responsive">
                    <table class="table table-flush table-hover">
                      <thead class="thead-light">
                        <tr>
                          <th>{{ $t("content.cname") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnfinanceoutput") }}</th>
                          <th>{{ $t("content.amnfinanceinput") }}</th>
                          <th>{{ $t("content.status") }}</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(row, index) in listpaymentD.items" :key="index"
                          :class="row.status != 'TERMINATE' ? '' : 'bg-working'">
                          <td class="text-sm font-weight-normal">{{ listpaymentD.customerName }}</td>
                          <td class="text-sm font-weight-normal">{{ row.tkRefNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ checkTransaction(row.type) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.totalPay !== 0" style="color:red;">
                              {{ formatNumberString(row.totalPay) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.totalReceive !== 0" style="color:green;">
                              {{ formatNumberString(row.totalReceive) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal"> {{ checkProcess(row.status) }}</td>
                        </tr>
                      </tbody>
                      <tfoot class="table-light">
                        <tr>
                          <td class="text-sm font-weight-bold" colspan="3">
                            {{ $t("content.amntotaltransact") }}
                          </td>
                          <td class="text-sm font-weight-bold">{{ formatNumberString(listpaymentD.roundTotalPay) }}
                          </td>
                          <td class="text-sm font-weight-bold">{{ formatNumberString(listpaymentD.roundTotalReceive)
                          }}
                          </td>
                          <td class="text-sm font-weight-bold">
                            {{ $t("content.amnpay") }}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <h5 :class="datas.showPaid ? `text-success` : ``">
                      {{
                        datas.showPaid
                          ? $t("content.amnpaycompleted")
                          : $t("content.amnfinanceprocess")
                      }}
                      <span class="text-success" :class="datas.showPaid ? `text-deprecated` : ``">({{
                        $t("content.amnpay") }} {{ formatNumberString(listpaymentD.roundTotalPay) }})</span>
                    </h5>
                  </div>

                  <!-- <div class="col-lg-6">
                    <label class="form-label ms-0">{{
                      $t("content.amnfinanceno")
                    }}</label>
                    <material-input id="issuePay" disabled="true" value="receipt-xxxxxxxx-xxx" />
                  </div> -->
                </div>

                <hr />
                <!-- <p>{{ balprice }}</p> -->
                <div class="row">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-end">
                      <div>
                        <!-- :disabled="parseFloat(balprice) <= 0" -->
                        <material-button color="primary" class="float-lg-start float-sm-end mb-3"
                          :disabled="parseFloat(balprice) === 0" @click="addNewItemsPay">
                          <i class="material-icons material-symbols-outlined">add</i>
                          {{ $t("content.amnaddnew") }}
                        </material-button>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th style="min-width: 200px;">{{ $t("content.amntypetrans") }}</th>
                          <th>{{ $t("content.amnbankaccno2") }}</th>
                          <th>{{ $t("content.amnhowtomoney") }}</th>
                          <th>{{ $t("content.amninputmoneytypenew") }}</th>
                          <th>{{ $t("content.amninputmoneyreceive") }}</th>
                          <th style="min-width: 150px;">{{ $t("content.amnsourcemoney") }}</th>
                          <th style="min-width: 150px;">{{ $t("content.toaccname") }}</th>
                          <th style="min-width: 200px;">{{ $t("content.amneffectdate") }}</th>
                          <!-- <th>pay uid</th> -->
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-if="addNewPay">
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal">
                            <select class="form-select" :disabled="datas.showPaid" v-model="payment_detail.type"
                              @change="getval()">
                              <option value="0" disabled selected>{{ $t("content.select") }}</option>
                              <option value="CASH">{{ $t("content.amncash") }}</option>
                              <option value="BANK_TRANSFER">{{ $t("content.amntransfer") }}</option>
                              <option value="PROMPT_PAY_MOBILE">{{ $t("content.amntransfer_promt_mobile") }}</option>
                              <option value="PROMPT_PAY_TAX_ID">{{ $t("content.amntransfer_promt_taxid") }}</option>
                              <option value="CHEQUE">{{ $t("content.amncheque") }}</option>
                              <option value="CREDIT_CARD" disabled selected>{{ $t("content.amncreditcard") }}</option>

                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input v-if="payment_detail.type !== 'BANK_TRANSFER' && payment_detail.type !== 'PROMPT_PAY_MOBILE'
                              && payment_detail.type !== 'PROMPT_PAY_TAX_ID'" :disabled="true" />

                            <select v-model="payment_detail.cusBankUid" class="form-select"
                              v-if="payment_detail.type === 'BANK_TRANSFER'">
                              <option value="0" disabled selected>{{ $t("content.select") }}</option>
                              <optgroup :label="listpaymentD.customerName">
                                <option v-for="(j, fs8) in listcusbankAll" :key="fs8" :value="j.uid">
                                  <span v-if="j.accName !== listpaymentD.customerName">
                                    {{ filterCusBank(j.bankUid)[0].nameTh }} {{ j.accNumber }} ({{ j.accName }})
                                  </span>
                                  <span v-else>
                                    {{ filterCusBank(j.bankUid)[0].nameTh }} {{ j.accNumber }}
                                  </span>
                                </option>
                              </optgroup>

                              <optgroup :label="j.nameTh" v-for="(j, fs8) in customerAflist" :key="fs8">
                                <option v-for="(i, fs9) in j.listbankcus" :key="fs9" :value="i.uid">

                                  {{ filterCusBank(i.bankUid)[0].nameTh }} {{ i.accNumber }}
                                </option>
                              </optgroup>
                            </select>

                            <select v-model="payment_detail.cusPhoneNumber" class="form-select"
                              v-if="payment_detail.type === 'PROMPT_PAY_MOBILE'">
                              <option value="" disabled selected>{{ $t("content.select") }}</option>
                              <optgroup :label="listpaymentD.customerName">
                                <option v-for="(j, fs8) in listcusmobileAll" :key="fs8" :value="j.mobile">
                                  {{ j.mobile }}
                                </option>
                              </optgroup>

                              <optgroup :label="j.nameTh" v-for="(j, fs8) in customerAflist" :key="fs8">
                                <option v-for="(i, fs9) in j.listpromtmobile" :key="fs9" :value="i.mobile">
                                  {{ i.mobile }}
                                </option>
                              </optgroup>
                            </select>

                            <select v-model="payment_detail.cusIdNumber" class="form-select"
                              v-if="payment_detail.type === 'PROMPT_PAY_TAX_ID'">
                              <option value="" disabled selected>{{ $t("content.select") }}</option>
                              <optgroup :label="listpaymentD.customerName">
                                <option v-for="(j, fs8) in customerTaxlist" :key="fs8" :value="j.taxId">
                                  {{ j.taxId }}
                                </option>
                              </optgroup>

                              <optgroup :label="j.nameTh" v-for="(j, fs8) in customerAflist" :key="fs8">
                                <option v-for="(i, fs9) in j.listpromttax" :key="fs9" :value="i.taxId">
                                  {{ i.taxId }}
                                </option>
                              </optgroup>

                            </select>

                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input v-model="payment_detail.chequeNumber"
                              :disabled="payment_detail.type !== 'CHEQUE'" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <InputNumber v-model="payment_detail.pay" :minFractionDigits="0" :maxFractionDigits="2"
                              autocomplete="off" @focus="$event.target.select()" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <InputNumber v-model="payment_detail.receive" :minFractionDigits="0" :maxFractionDigits="2"
                              autocomplete="off" @focus="$event.target.select()" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <select v-model="payment_detail.sourcemoney" class="form-select"
                              :disabled="payment_detail.type !== 'CASH' && payment_detail.type !== 'BANK_TRANSFER' && payment_detail.type !== 'PROMPT_PAY_MOBILE' && payment_detail.type !== 'PROMPT_PAY_TAX_ID'">
                              <option value="0" disabled selected>{{ $t("content.select") }}</option>
                              <optgroup :label="$t('apps.mcash')" :disabled="payment_detail.type !== 'CASH'">
                                <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                                  {{ item.name }}
                                </option>
                              </optgroup>
                              <optgroup :label="$t('content.amnbankacc')"
                                :disabled="payment_detail.type !== 'BANK_TRANSFER' && payment_detail.type !== 'PROMPT_PAY_MOBILE' && payment_detail.type !== 'PROMPT_PAY_TAX_ID'">
                                <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                                  {{ item.shortName }}
                                </option>
                              </optgroup>
                            </select>
                          </td>

                          <td class="text-sm font-weight-normal">
                            <material-input :disabled="true" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <div class="flex justify-center">
                              <VueDatePicker v-model="payment_detail.effectiveDateE" :format="format" auto-apply
                                :teleport="true" :enable-time-picker="false" month-name-format="long" :clearable="false"
                                :disabled="payment_detail.type !== 'CHEQUE'" :text-input="textInputOptions" fluid />
                            </div>
                          </td>

                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" :disabled="payment_detail.type === '0'
                                || ((payment_detail.pay === null || parseFloat(payment_detail.pay) === 0) &&
                                  (payment_detail.receive === null || parseFloat(payment_detail.receive) === 0)) ||
                                (payment_detail.sourcemoney === '0' && (payment_detail.type === 'CASH' || payment_detail.type
                                  === 'BANK_TRANSFER' || payment_detail.type === 'PROMPT_PAY_MOBILE' || payment_detail.type ===
                                  'PROMPT_PAY_TAX_ID'))
                                || payment_detail.type === 'BANK_TRANSFER' && payment_detail.cusBankUid === '0'
                                || payment_detail.type === 'PROMPT_PAY_MOBILE' && payment_detail.cusPhoneNumber.trim() === ''
                                || payment_detail.type === 'PROMPT_PAY_TAX_ID' && payment_detail.cusIdNumber.trim() === ''
                                || payment_detail.type === 'CHEQUE' && payment_detail.chequeNumber.trim() === ''
                                || parseFloat(payment_detail.pay) !== 0 && parseFloat(payment_detail.receive) !== 0
                                || filterPay(payment_detail.type)" @click="saveNewItemsPay">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelSaveNewItemsPay">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-for="(row, index) in paymentDetail" :key="index">
                          <td class="text-sm font-weight-normal">{{ formatNumber(index + 1) }}</td>
                          <td v-if="row.uid !== payment_detail.uid" class="text-sm font-weight-normal">
                            {{ checkPayType(row.type) }}
                          </td>

                          <td v-if="row.uid !== payment_detail.uid" class="text-sm font-weight-normal">
                            <span v-if="row.type === 'BANK_TRANSFER'">
                              {{ filterCusBankPay(row.cusBankUid) }}
                            </span>
                            <span v-if="row.type === 'PROMPT_PAY_MOBILE'">
                              {{ createMaskPhone(row.cusPhoneNumber) }} {{ filterCusPromtMobile(row.cusPhoneNumber) }}
                            </span>
                            <span v-if="row.type === 'PROMPT_PAY_TAX_ID'">
                              {{ createMaskID(row.cusIdNumber) }} {{ filterCusPromtTax(row.cusIdNumber) }}
                            </span>
                          </td>
                          <td v-if="row.uid !== payment_detail.uid" class="text-sm font-weight-normal">
                            {{ row.chequeNumber }}
                          </td>
                          <td v-if="row.uid !== payment_detail.uid" class="text-sm font-weight-normal">
                            <span v-if="row.pay !== 0" style="color:red;">
                              {{ formatNumberString(row.pay) }}
                            </span>
                            <span v-else></span>
                          </td>
                          <td v-if="row.uid !== payment_detail.uid" class="text-sm font-weight-normal">
                            <span v-if="row.receive !== 0" style="color:green;">
                              {{ formatNumberString(row.receive) }}
                            </span>
                            <span v-else></span>
                          </td>
                          <td v-if="row.uid !== payment_detail.uid" class="text-sm font-weight-normal">
                            {{ checkMoneySource(row.sourcemoney) }}
                          </td>
                          <td v-if="row.uid !== payment_detail.uid" class="text-sm font-weight-normal">
                            <span v-if="row.toAccNameTh !== '' && row.toAccNameTh !== null" style="color:blue;">
                              {{ row.toAccNameTh }} ({{ row.toAccNameEn }})
                            </span>
                          </td>
                          <td v-if="row.uid !== payment_detail.uid" class="text-sm font-weight-normal">
                            <span v-if="row.effectiveDate !== null"> {{
                              format_date(row.effectiveDate) }}
                            </span>
                          </td>
                          <!-- <td v-if="row.uid !== payment_detail.uid" class="text-sm font-weight-normal">
                            {{ row.payable_uid }}
                          </td> -->
                          <td v-if="row.uid === payment_detail.uid" class="text-sm font-weight-normal">
                            <select class="form-select" v-model="payment_detail.type" @change="getval()">
                              <option value="0" disabled selected>{{ $t("content.select") }}</option>
                              <option value="CASH">{{ $t("content.amncash") }}</option>
                              <option value="BANK_TRANSFER">{{ $t("content.amntransfer") }}</option>
                              <option value="PROMPT_PAY_MOBILE">{{ $t("content.amntransfer_promt_mobile") }}</option>
                              <option value="PROMPT_PAY_TAX_ID">{{ $t("content.amntransfer_promt_taxid") }}</option>
                              <option value="CHEQUE">{{ $t("content.amncheque") }}</option>
                              <option value="CREDIT_CARD" disabled selected>{{ $t("content.amncreditcard") }}</option>
                            </select>
                          </td>
                          <td v-if="row.uid === payment_detail.uid" class="text-sm font-weight-normal">
                            <material-input v-if="payment_detail.type !== 'BANK_TRANSFER' && payment_detail.type !== 'PROMPT_PAY_MOBILE'
                              && payment_detail.type !== 'PROMPT_PAY_TAX_ID'" :disabled="true" />

                            <select v-model="payment_detail.cusBankUid" class="form-select"
                              v-if="payment_detail.type === 'BANK_TRANSFER'" @change="changebank()">
                              <option value="0" disabled selected>{{ $t("content.select") }}</option>
                              <optgroup :label="listpaymentD.customerName">
                                <option v-for="(j, fs8) in listcusbankAll" :key="fs8" :value="j.uid">
                                  <span v-if="j.accName !== listpaymentD.customerName">
                                    {{ filterCusBank(j.bankUid)[0].nameTh }} {{ j.accNumber }} ({{ j.accName }})
                                  </span>
                                  <span v-else>
                                    {{ filterCusBank(j.bankUid)[0].nameTh }} {{ j.accNumber }}
                                  </span>
                                </option>
                              </optgroup>

                              <optgroup :label="j.nameTh" v-for="(j, fs8) in customerAflist" :key="fs8" :value="j.uid">
                                <option v-for="(i, fs9) in j.listbankcus" :key="fs9" :value="i.uid">
                                  {{ filterCusBank(i.bankUid)[0].nameTh }} {{ i.accNumber }}
                                </option>
                              </optgroup>
                            </select>

                            <select v-model="payment_detail.cusPhoneNumber" class="form-select"
                              v-if="payment_detail.type === 'PROMPT_PAY_MOBILE'">
                              <option value="" disabled selected>{{ $t("content.select") }}</option>
                              <optgroup :label="listpaymentD.customerName">
                                <option v-for="(j, fs8) in listcusmobileAll" :key="fs8" :value="j.mobile">
                                  {{ j.mobile }}
                                </option>
                              </optgroup>

                              <optgroup :label="j.nameTh" v-for="(j, fs8) in customerAflist" :key="fs8">
                                <option v-for="(i, fs9) in j.listpromtmobile" :key="fs9" :value="i.mobile">
                                  {{ i.mobile }}
                                </option>
                              </optgroup>
                            </select>

                            <select v-model="payment_detail.cusIdNumber" class="form-select"
                              v-if="payment_detail.type === 'PROMPT_PAY_TAX_ID'">
                              <option value="" disabled selected>{{ $t("content.select") }}</option>
                              <optgroup :label="listpaymentD.customerName">
                                <option v-for="(j, fs8) in customerTaxlist" :key="fs8" :value="j.taxId">
                                  {{ j.taxId }}
                                </option>
                              </optgroup>

                              <optgroup :label="j.nameTh" v-for="(j, fs8) in customerAflist" :key="fs8">
                                <option v-for="(i, fs9) in j.listpromttax" :key="fs9" :value="i.taxId">
                                  {{ i.taxId }}
                                </option>
                              </optgroup>

                            </select>
                          </td>
                          <td v-if="row.uid === payment_detail.uid" class="text-sm font-weight-normal">
                            <material-input v-model="payment_detail.chequeNumber"
                              :disabled="payment_detail.type !== 'CHEQUE'" />
                          </td>
                          <td v-if="row.uid === payment_detail.uid" class="text-sm font-weight-normal">
                            <InputNumber v-model="payment_detail.pay" :minFractionDigits="0" :maxFractionDigits="2"
                              autocomplete="off" @focus="$event.target.select()" />
                          </td>
                          <td v-if="row.uid === payment_detail.uid" class="text-sm font-weight-normal">
                            <InputNumber v-model="payment_detail.receive" :minFractionDigits="0" :maxFractionDigits="2"
                              autocomplete="off" @focus="$event.target.select()" />
                          </td>
                          <td v-if="row.uid === payment_detail.uid" class="text-sm font-weight-normal">
                            <select v-model="payment_detail.sourcemoney" class="form-select"
                              :disabled="payment_detail.type !== 'CASH' && payment_detail.type !== 'BANK_TRANSFER' && payment_detail.type !== 'PROMPT_PAY_MOBILE' && payment_detail.type !== 'PROMPT_PAY_TAX_ID'">
                              <option value="0" disabled selected>{{ $t("content.select") }}</option>
                              <optgroup :label="$t('apps.mcash')" :disabled="payment_detail.type !== 'CASH'">
                                <option v-for="(item, index) in listcashAcc" :value="item.uid" :key="index">
                                  {{ item.name }}
                                </option>
                              </optgroup>
                              <optgroup :label="$t('content.amnbankacc')"
                                :disabled="payment_detail.type !== 'BANK_TRANSFER' && payment_detail.type !== 'PROMPT_PAY_MOBILE' && payment_detail.type !== 'PROMPT_PAY_TAX_ID'">
                                <option v-for="(item, index) in listbankpawn" :value="item.uid" :key="index">
                                  {{ item.shortName }}
                                </option>
                              </optgroup>
                            </select>
                          </td>
                          <td v-if="row.uid === payment_detail.uid" class="text-sm font-weight-normal">
                            <span v-if="payment_detail.toAccNameTh !== ''">
                              {{ payment_detail.toAccNameTh }} ({{ payment_detail.toAccNameEn }})
                            </span>
                          </td>
                          <td v-if="row.uid === payment_detail.uid" class="text-sm font-weight-normal">
                            <div class="flex justify-center">
                              <VueDatePicker v-model="payment_detail.effectiveDateE" :format="format" auto-apply
                                :teleport="true" :enable-time-picker="false" month-name-format="long" :clearable="false"
                                :disabled="payment_detail.type !== 'CHEQUE'" :text-input="textInputOptions" fluid />
                            </div>
                          </td>
                          <td v-if="row.uid !== payment_detail.uid" class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              :disabled="row.payable_uid !== '0' && row.payable_status === 'COMPLETE'"
                              @click="editRowItemsPay(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              :disabled="row.payable_uid !== '0' && row.payable_status === 'COMPLETE'"
                              @click="deleteRowItemsPay(row)">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                          <td v-if="row.uid === payment_detail.uid" class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" :disabled="payment_detail.type === '0' || ((payment_detail.pay
                                === null || parseFloat(payment_detail.pay) === 0) && (payment_detail.receive === null || parseFloat(payment_detail.receive) === 0)) ||
                                (payment_detail.sourcemoney === '0' && (payment_detail.type === 'CASH' || payment_detail.type === 'BANK_TRANSFER' || payment_detail.type === 'PROMPT_PAY_MOBILE' || payment_detail.type === 'PROMPT_PAY_TAX_ID'))
                                || payment_detail.type === 'BANK_TRANSFER' && payment_detail.cusBankUid === '0'
                                || payment_detail.type === 'PROMPT_PAY_MOBILE' && payment_detail.cusPhoneNumber.trim() === ''
                                || payment_detail.type === 'PROMPT_PAY_TAX_ID' && payment_detail.cusIdNumber.trim() === ''
                                || payment_detail.type === 'CHEQUE' && payment_detail.chequeNumber.trim() === ''
                                || parseFloat(payment_detail.pay) !== 0 && parseFloat(payment_detail.receive) !== 0
                                || filterPay(payment_detail.type)" @click="saveEditRowItemsPay">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelEditRowItemsPay">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>

                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>

                </div>

                <hr />


                <div class="row">
                  <div class="col-lg-12">
                    <label class="form-label ms-0">{{
                      $t("content.amnfinancenote")
                    }}</label>
                    <material-input v-model="detailRemark" id="detailPay" />
                  </div>


                  <div class="col-12 d-flex justify-content-end">
                    <div v-if="datas.paid" class="d-flex align-items-center me-auto">
                      <div class="spinner-border me-3" style="width: 2rem; height: 2rem" role="status"
                        aria-hidden="true">
                      </div>
                      <strong>{{ $t("buttons.load") }}</strong>
                    </div>
                    <div class="me-3">
                      <label class="form-label ms-0 png"></label>
                      <!-- :disabled="parseFloat(balprice) !== 0 || uid_edit === '' " -->
                      <material-button color="default" class="float-lg-start float-sm-end mb-3" v-if="!datas.showPaid"
                        :disabled="uid_edit === ''" @click="onCancelPayment">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ $t("buttons.canc") }}
                      </material-button>
                    </div>

                    <div>
                      <!-- :disabled="parseFloat(balprice) !== 0 || uid_edit === '' " -->
                      <label class="form-label ms-0 png"></label>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3"
                        :disabled="parseFloat(balprice) !== 0 || uid_edit === '' || cancom" @click="onConfirmPayment">
                        <i class=" material-icons material-symbols-outlined">check</i>
                        {{ $t("buttons.comp") }}
                      </material-button>
                    </div>
                  </div>
                </div>

                <div v-if="datas.showQR" class="row mt-4">
                  <div class="col-12 text-center">
                    <hr v-if="!datas.paid" />
                    <label v-if="!datas.paid" class="d-block form-label ms-0">{{
                      $t("content.amnqrrunapp")
                    }}</label>
                    <img v-if="!datas.paid" :src="QrCode" class="img img-fluid qr-scan-app" />
                    <div class="d-flex justify-content-center mt-2">
                      <material-button v-if="!datas.paid" color="primary" class="float-lg-start float-sm-end mb-3"
                        @click="onEditPayment">
                        <i class="material-icons material-symbols-outlined">edit</i>
                        {{ $t("buttons.edit") }}
                      </material-button>

                      <material-button v-if="!datas.paid" color="default" class="float-lg-start float-sm-end mb-3 ms-3"
                        @click="countdownTimer(10)">
                        <i class="material-icons material-symbols-outlined">restart_alt</i>
                        สมมติว่าชำระแล้ว
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <!-- <th style="width: 100px">{{ $t("content.ccode") }}</th> -->
                          <th>#</th>
                          <th>{{ $t("content.cnameth") }}</th>
                          <th>{{ $t("content.cnameen") }}</th>
                          <th>{{ $t("content.docno") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td></td>
                          <td>
                            <material-input v-model="nameThC" type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input v-model="nameEnC" type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input v-model="docNumberC" type="text" :label="t('content.docno')" />
                          </td>
                          <td>
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              :disabled="nameThC.length < 2 && nameEnC.length < 2 && docNumberC.length < 5"
                              @click="loadCustomerAfListApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-for="(row, index) in customerlist" :key="index" @click="selectCusAf(row)">
                          <td>{{ formatNumber((currentPageC - 1) * perPageC + index + 1) }}</td>
                          <td>{{ row.nameTh }}</td>
                          <td>{{ row.nameEn }}</td>
                          <td colspan="2">{{ row.docNumber }}</td>
                        </tr>
                        <tr v-if="customerlist.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="customerlist.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                          :items-per-page="perPageC" @click="onClickHandlerC" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- OTP Dialog -->
        <div id="otpModal" class="modal fade" tabindex="-1" aria-labelledby="otpModalLabel" aria-hidden="true">
          <div class="modal-dialog" style="max-width: 500px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-center">{{ $t("content.amnotpcode") }}</h4>
              </div>

              <div class="modal-body">
                <section class="panel">
                  <otp :digit-count="6" @update:otp="otpValue = $event" />
                  <p class="text-center mt-5">
                    {{ $t(`content.amnotptime`) }} : 5 {{ $t(`content.amnmin`) }}
                  </p>
                </section>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                  {{ confirm }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchAfModal" class="modal fade" tabindex="-1" aria-labelledby="searchAfModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeAfModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.cnameth") }}</th>
                          <th>{{ $t("content.cnameen") }}</th>
                          <th>{{ $t("content.docno") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td></td>
                          <td>
                            <material-input v-model="nameThCaf" type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input v-model="nameEnCaf" type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input v-model="docNumberCaf" type="text" :label="t('content.docno')" />
                          </td>
                          <td>
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              :disabled="nameThCaf.length < 2 && nameEnCaf.length < 2 && docNumberCaf.length < 5"
                              @click="getCustomerAfListApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-for="(row, index) in customerAfList" :key="index"
                          @click="canselectCusAf(row) ? selectCusAfl(row) : ''"
                          :class="!canselectCusAf(row) ? `text-deprecated` : ``" style="cursor: pointer">
                          <td class="text-sm font-weight-normal">{{ formatNumber((currentPageCaf - 1) *
                            perPageCaf
                            + index
                            + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.nameTh }}</td>
                          <td class="text-sm font-weight-normal">{{ row.nameEn }}</td>
                          <td class="text-sm font-weight-normal">{{ row.docNumber }}</td>
                          <td></td>
                        </tr>
                        <tr v-if="customerAfList.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="customerAfList.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageCaf" :total-items="totalRowsCaf"
                          :items-per-page="perPageCaf" @click="onClickHandlerCaf" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import ProgressSpinner from 'primevue/progressspinner';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import InputNumber from 'primevue/inputnumber';
import { ref, reactive, inject, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import ExampleFive from "@/examples/ExampleOne";
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";

import otp from "@/examples/OTP/otp.vue";
import QrCode from "@/assets/img/pawn/qr_sample.png";
import InputMask from 'primevue/inputmask';
import noimg from "@/assets/img/noimg.png"
import banknote from "@/assets/img/banknote.png"
import axios from "axios";
import * as st from "@/config/setting.js";

import { useWorkItemStore } from '@/stores/workItem'
import { usePaymentGroupStore } from '@/stores/paymentgroup'
import { useBankStore } from '@/stores/bank'
import { useCashAccountStore } from '@/stores/cashaccount'
import { usePaymentStore } from '@/stores/payment'
import { useShopBankStore } from '@/stores/shopbank'
import { useCustomerStore } from '@/stores/customer'
import { useCusBankStore } from '@/stores/cusbank'
import { useCusAffiliateStore } from '@/stores/cusaffiliate'
import { useDocTypeStore } from '@/stores/doctype'
import { usePayAbletStore } from '@/stores/payable'
import { useCusShopStore } from '@/stores/cusshop'


import moment from 'moment'

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};

const { t } = useI18n();
const swal = inject("$swal");

const otpValue = ref("");

const datas = reactive({
  showQR: false,
  searchBox1: "",
  paid: false,
  showPaid: false,
  hide: false,
});



const activeTab = ref('tab1');


const toggleRowQ02 = (index) => {
  // console.log('index :' + index + " : " + listQ02[index]);
  if (!listQ02.value[index].tg) {
    listQ02.value[index].tg = true;
  } else {
    listQ02.value[index].tg = false;
  }
};

const toggleRowQ02O = (index) => {
  // console.log('index :' + index + " : " + listQ02[index]);
  if (!listQ02O.value[index].tg) {
    listQ02O.value[index].tg = true;
  } else {
    listQ02O.value[index].tg = false;
  }
};

///// computed
// const checked = computed(() => t("content.amnrecheckdone"));
// const save = computed(() => t("buttons.save"))

const cancel = computed(() => t("buttons.canc"));
const confirm = computed(() => t("buttons.conf"));


const storew = useWorkItemStore()
const storep = usePaymentGroupStore()
const storeb = useBankStore()
const storec = useCashAccountStore()
const storepm = usePaymentStore()
const storesb = useShopBankStore()
const storecus = useCustomerStore()
const storecb = useCusBankStore()
const storecusa = useCusAffiliateStore()
const storedt = useDocTypeStore()
const storepay = usePayAbletStore()
const storecussh = useCusShopStore()


const listQ02 = ref([]);
const currentPage_Q02 = ref(1);
const totalRows_Q02 = ref(1);
const perPage_Q02 = ref(10);
const totalRows_s_Q02 = ref();

const listQ02O = ref([]);
const currentPage_Q02O = ref(1);
const totalRows_Q02O = ref(1);
const perPage_Q02O = ref(10);
const totalRows_s_Q02O = ref();

const paymentE = ref({});
const listpaymentE = ref([]);

const listpaymentD = ref([]);

const paymentDetail = ref([]);

const listbank = ref([]);
const listcashAcc = ref([]);
const listbankpawn = ref([]);

const uid_edit = ref('');
const version_edit = ref('');
const workItemUid = ref('');

const payment_detail = ref({
  uid: '',
  type: '0',
  pay: '',
  receive: '',
  change: '',
  cashAcctUid: '',
  acctNumber: '',
  cardNumber: '',
  cardName: '',
  bankUid: '',
  effectiveDate: new Date(),
  remark: '',
  paymentGroupUid: '',
  version: '',
  sourcemoney: '0',
  shopBankUid: '0',
  cusBankUid: '0',
  cusIdNumber: "",
  cusPhoneNumber: "0",
  toAccNameTh: "",
  toAccNameEn: "",
  chequeNumber: "",
  effectiveDateE: new Date(),
  payable_uid: "0",
  payable_version: "0",
  feeReceive: 0,
  feePay: 0,
  bankRefId: '',
  payable_status: "",
});

const shop_detail = ref(null)
const addNewPay = ref(false);
const balprice = ref(0)
const pay_e = ref({});

const lb = ref([]);
const urls = ref([]);

const detailRemark = ref('');
const cancom = ref(false);

const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRows_sC = ref();

const currentPageBk = ref(1);
const totalRowsBk = ref(1);
const perPageBk = ref(5);
const totalRows_sBk = ref();

const nameThC = ref('')
const nameEnC = ref('')
const docNumberC = ref('')
const cusUid_search = ref('');
const cusName_search = ref('');
const cusUidH_search = ref('');
const cusNameH_search = ref('');

const customerlist = ref([])
const listcusbank = ref([]);
const listcusbankAll = ref([]);
const listcusmobileAll = ref([]);


const customerAflist = ref([])
const doctypeAccList = ref([])
const listcusbankAf = ref([]);
const customerTaxlist = ref([])
const paylist = ref([])
const msginput = ref('');
const loading = ref(false);
const toAccNameTH = ref('');
const toAccNameEN = ref('');

const listcusaffiliate = ref([]);
const currentPageCuf = ref(1);
const totalRowsCuf = ref(1);
const perPageCuf = ref(5);
const totalRows_sCuf = ref();

const nameThCaf = ref('')
const nameEnCaf = ref('')
const docNumberCaf = ref('')

const customerAfList = ref([])
const currentPageCaf = ref(1);
const totalRowsCaf = ref(1);
const perPageCaf = ref(10);
const totalRows_sCaf = ref();

const cus_detail = ref({
  nameTh: '',
  nameEn: '',
  birthOrRegDate: new Date(),
  address: {
    address1: "",
    subDistrict: "",
    district: "",
    province: "",
    postcode: "",
    country: ""
  },
  issuedBy: '',
  issuedDate: new Date(),
  expiredDate: new Date(),
  version: '',
  age: 0,
  docTypeUid: '0',
  docNumber: '',
  lifetime: false,
  cusShop: {
    cusTypeUid: "0",
    creditAmount: 0,
    email: "",
    phoneNo1: "",
    phoneNo2: "",
    phoneNo3: "",
    fingerPrint1Uid: "0",
    fingerPrint2Uid: "0",
    fgTemplate1Uid: "0",
    fgTemplate2Uid: "0",
    warningMsg: "",
    notifyWarning: false,
  }
})

const cus_bank = ref({
  uid: '',
  cusUid: "",
  bankUid: "0",
  accNumber: "",
  accName: "",
  cusBankDocs: [],
  defaultValue: false,
  note: "",
  bankStatus: "0",
  version: '',
})


const cusAf_detail = ref({
  nameTh: '',
  nameEn: '',
  birthOrRegDate: new Date(),
  address: {
    address1: "",
    subDistrict: "",
    district: "",
    province: "",
    postcode: "",
    country: ""
  },
  issuedBy: '',
  issuedDate: new Date(),
  expiredDate: new Date(),
  version: '',
  age: 0,
  docTypeUid: '0',
  docNumber: '',
  cusShop: {
    cusTypeUid: "0",
    creditAmount: 0,
    email: "",
    phoneNo1: "",
    phoneNo2: "",
    phoneNo3: "",
  }
})





const bankStatus = ref([
  { val: 'NORMAL' },
  { val: 'CLOSE' },
  { val: 'OVERDUE' },
  { val: 'SUSPEND' }]
);

const onClickHandlerC = (page) => {
  updatePaginationC()
};

const onClickHandlerBk = (page) => {
  // console.log('onClickHandler :' + page);
  updatePaginationBk()
};

const onClickHandlerCaf = (page) => {
  updatePaginationCaf()
};

const onClickHandlerCuf = (page) => {
  // console.log('onClickHandler :' + page);
  updatePaginationCuf()
};

const loadDataQ02Api = async (val) => {

  let data = "";
  data = {
    index: '0',
    size: '100',
    cusUid: cusUid_search.value,
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storew.listPaymentQ02Fetch(payload);
  // console.log("loadDataApi Q02 : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {

        let listd = response.data.data.list;


        listd.map(function (x) {
          x.tg = false;
          x.chk = false;
          x.start = null
          x.status = null
          x.status_s = null



          return x
        });

        for (let i = 0; i < listd.length; i++) {
          // console.log("xx " + [i] + " : " + JSON.stringify(listd[i]));
          listd[i].start = format_datetime(new Date(listd[i].startTime))

          let exist = listd[i].items.filter((item) => {
            return item.waitForCondition === true && item.status !== 'TERMINATE';
          })
          // console.log("exist " + [i] + " : " + JSON.stringify(exist));
          if (exist.length > 0) {
            listd[i].status = 'Working'
            listd[i].status_s = 'กำลังดำเนินการ'
          } else {
            listd[i].status = 'Finish'
            listd[i].status_s = 'พร้อมชำระ'
            listd[i].tg = true
            // console.log("chk " + [i] + " : " + listd[i].chk);
          }

          // console.log(" customerInfo " + [i] + " : " + JSON.stringify(listd[i].workCases[0].customerInfo.name));
          // console.log(" workItems " + [i] + " : " + JSON.stringify(listd[i].workCases[0].workItems[0].startTime));
          // console.log(" workItems xx " + listd[i].workCases[0].workItems[0].waitForCondition);

        }

        listQ02.value = listd;
        // console.log("listd Q02 : " + JSON.stringify(listd));
        totalRows_Q02.value = response.data.data.ext.total
        totalRows_s_Q02.value = formatNumber(totalRows_Q02.value)
      }
    }
  }
};

loadDataQ02Api()


const loadDataQ02OwnerApi = async (val) => {

  let data = "";
  data = {
    index: '0',
    size: '100',
    cusUid: cusUidH_search.value
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storew.listPaymentQ02OwnerFetch(payload);
  // console.log("loadDataApi Q02O : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;


        listd.map(function (x) {
          x.tg = false;
          x.chk = false;
          x.start = null
          x.status = null
          x.status_s = null




          return x
        });

        for (let i = 0; i < listd.length; i++) {
          listd[i].start = format_datetime(new Date(listd[i].startTime))
          let exist = listd[i].items.filter((item) => {
            return item.waitForCondition === true && item.status !== 'TERMINATE';
          })
          // console.log("exist " + [i] + " : " + JSON.stringify(exist));
          if (exist.length > 0) {
            listd[i].status = 'Working'
            listd[i].status_s = 'กำลังดำเนินการ'
          } else {
            listd[i].status = 'Finish'
            listd[i].status_s = 'พร้อมชำระ'
            listd[i].tg = true
            // console.log("chk " + [i] + " : " + listd[i].chk);
          }


          // console.log(" customerInfo " + [i] + " : " + JSON.stringify(listd[i].workCases[0].customerInfo.name));
          // console.log(" workItems " + [i] + " : " + JSON.stringify(listd[i].workCases[0].workItems[0].startTime));
          // console.log(" workItems xx " + listd[i].workCases[0].workItems[0].waitForCondition);

        }

        listQ02O.value = listd;
        // console.log("listd Q02 : " + JSON.stringify(listd));
        totalRows_Q02O.value = response.data.data.ext.total
        totalRows_s_Q02O.value = formatNumber(totalRows_Q02.value)
      }
    }
  }
};

const loadBankDataApi = async (val) => {
  listbank.value = [];
  let data = "";
  data = {
    index: "0",
    size: "100",
    nameTh: "",
    nameEn: "",
  };
  const payload = data;
  const response = await storeb.bankListFetch(payload);
  // console.log("loadBankDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listbank.value = response.data.data.list;
        // listbank.value.forEach(function (obj) {
        //   obj.thumb = null;
        //   obj.file = null;
        //   obj.data = null;
        // });

        // for (let i = 0; i < listbank.value.length; i++) {
        //   // console.log("logoUid : " + lb[i].logoUid);
        //   if (listbank.value[i].logoUid !== "0") {
        //     urls.value.push(String(st.url_api + "/doc-svc/document/info/" + listbank.value[i].logoUid));
        //     // urls.value.push(axios.get((st.url_document + "/doc-svc/document/info/" + lb[i].logoUid)));
        //   }
        // }
        // // console.log("urls : " + JSON.stringify(urls.value));
        // if (urls.value.length > 0) {
        //   lb.value = listbank.value
        //   await getFileLoco()
        //   listbank.value = lb.value

        //   listbankpawn.value.forEach(function (obj) {
        //     const lf = filterLocoBank(obj.bankUid, listbank.value)
        //     // console.log("lf : " + JSON.stringify(lf));
        //     if (lf.length > 0) {
        //       obj.thumb = lf[0].thumb;
        //     } else {
        //       obj.thumb = noimg;
        //     }

        //   });
        // }
      }
    }
  }
}




const loadCashAccDataApi = async (val) => {
  listcashAcc.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storec.cashaccountListFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // listcashAcc.value = response.data.data.list;
        let listd = response.data.data.list;
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].channels.includes('FO_WEB')) {
            listcashAcc.value.push(listd[i])
          }
        }
      }
    }
  }
}

const loadBankPawnApi = async (val) => {
  listbankpawn.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    bankUid: '',
    shortName: '',
    acctNumber: '',
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesb.shopbankListFetch(payload);
  // console.log("loadBankPawnApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // listbankpawn.value = response.data.data.list;
        let listd = response.data.data.list;
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].channels.includes('FO_WEB')) {
            listbankpawn.value.push(listd[i])
          }
        }

      }
    }
  }
}

const cashAccShopApi = async (val) => {
  shop_detail.value = null
  const response = await storec.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("cashAccShopApi : " + JSON.stringify(response.data.data));
        shop_detail.value = response.data.data
        loadCashAccDataApi()
        loadBankPawnApi()
      }
    }
  }
}



const loadDocTypeAccListApi = async (val) => {
  // dev : 601433176200681039 , uat : 635673099141979585 , prod : 635691584740292063
  doctypeAccList.value = []
  let data = '';
  data = {
    index: 0,
    size: 100,
    name: "",
    docGroupCode: '001'
  };

  const payload = data;
  const response = await storedt.docTypeListFetch(payload);
  // console.log("loadDocTypeListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          doctypeAccList.value = response.data.data.list;
          // console.log('list : '+list.value.length)
        }
      }
    }
  }
};


async function loadtransferTypeFetch() {
  const response = await storepay.transferTypeFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("loadtransf : " + JSON.stringify(response.data));
      }
    }
  }
}

// loadtransferTypeFetch()


function getval() {
  // console.log('change : ' + payment_detail.value.type);
  payment_detail.value.cashAcctUid = ""
  payment_detail.value.cardNumber = ""
  payment_detail.value.cardName = ""
  payment_detail.value.effectiveDate = new Date()
  payment_detail.value.sourcemoney = "0"
  payment_detail.value.shopBankUid = "0"
  payment_detail.value.cusBankUid = "0"
  payment_detail.value.cusIdNumber = ""
  payment_detail.value.cusPhoneNumber = ""
  payment_detail.value.chequeNumber = ""
  payment_detail.value.effectiveDateE = new Date()
  payment_detail.value.toAccNameTh = ""
  payment_detail.value.toAccNameEn = ""
}

function changebank() {
  payment_detail.value.toAccNameTh = ""
  payment_detail.value.toAccNameEn = ""
}


function filterLocoBank(str, matchArr) {
  //  return matchArr.filter((c) => c.data.data.uid === str);
  return matchArr.filter((c) => c.uid === str);
}


function createMaskPhone(string) {
  var x = string.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  string = '(' + x[1] + ') ' + x[2] + '-' + x[3]
  return string;
}

function createMaskID(string) {
  var x = string.replace(/\D/g, '').match(/(\d{0,1})(\d{0,4})(\d{0,5})(\d{0,2})(\d{0,1})/);
  string = x[1] + ' ' + x[2] + ' ' + x[3] + ' ' + x[4] + ' ' + x[5]
  return string;
}


async function onClickTab(val) {
  if (val === '1') {
    activeTab.value = 'tab1';
    loadDataQ02Api()
    await setfirstTab()
  } else if (val === '2') {
    activeTab.value = 'tab2';
    loadDataQ02OwnerApi()
    await setsecondTab()
  } else if (val === '3') {
    activeTab.value = 'tab3';
    await setthirdTab()
  }
}



function formatNumberString(num) {
  if (num !== null && num !== undefined && num !== '') {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumber(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}


function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm:ss')
  }
}

function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}

function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  }
  return side
}


function filterPay(str) {
  let pro = false
  let name = paymentDetail.value.filter((c) => c.type === str && c.uid !== payment_detail.value.uid);
  if (name.length > 0 && (str !== 'BANK_TRANSFER' && str !== 'PROMPT_PAY_MOBILE' && str !== 'PROMPT_PAY_TAX_ID')) {
    pro = true
  }

  if (pro === false && name.length > 0 && str === 'BANK_TRANSFER' && payment_detail.value.receive > 0) {
    let lp = []
    lp = paymentDetail.value.filter((c) => c.cusBankUid === payment_detail.value.cusBankUid && c.receive > 0);
    if (lp.length > 0) {
      pro = true
    }
  }

  if (pro === false && name.length > 0 && str === 'PROMPT_PAY_MOBILE' && payment_detail.value.receive > 0) {
    let lp = []
    lp = paymentDetail.value.filter((c) => c.cusPhoneNumber === payment_detail.value.cusPhoneNumber && c.receive > 0);
    if (lp.length > 0) {
      pro = true
    }
  }

  if (pro === false && name.length > 0 && str === 'PROMPT_PAY_TAX_ID' && payment_detail.value.receive > 0) {
    let lp = []
    lp = paymentDetail.value.filter((c) => c.cusIdNumber === payment_detail.value.cusIdNumber && c.receive > 0);
    if (lp.length > 0) {
      pro = true
    }
  }
  return pro
}


function canComplete() {
  cancom.value = false
  for (let i = 0; i < paymentDetail.value.length; i++) {
    // console.log('i ' + i + ' : ' + paymentDetail.value[i].effectiveDate)
    if (paymentDetail.value[i].type === 'CASH') {
      if ((paymentDetail.value[i].pay === 0 && paymentDetail.value[i].receive === 0)
        || paymentDetail.value[i].sourcemoney === '0' || paymentDetail.value[i].effectiveDate === null) {
        cancom.value = true
        break
      }
    } else if (paymentDetail.value[i].type === 'BANK_TRANSFER') {
      let lp = []
      lp = paymentDetail.value.filter((c) => c.cusBankUid === paymentDetail.value[i].cusBankUid && c.receive > 0);
      if ((paymentDetail.value[i].pay === 0 && paymentDetail.value[i].receive === 0)
        || paymentDetail.value[i].sourcemoney === '0' || paymentDetail.value[i].effectiveDate === null
        || paymentDetail.value[i].cusBankUid === '0' || lp.length > 1) {
        cancom.value = true
        break
      }
    } else if (paymentDetail.value[i].type === 'PROMPT_PAY_MOBILE') {
      let lp = []
      lp = paymentDetail.value.filter((c) => c.cusPhoneNumber === paymentDetail.value[i].cusPhoneNumber && c.receive > 0);
      if ((paymentDetail.value[i].pay === 0 && paymentDetail.value[i].receive === 0)
        || paymentDetail.value[i].sourcemoney === '0' || paymentDetail.value[i].effectiveDate === null
        || paymentDetail.value[i].cusPhoneNumber === '' || lp.length > 1) {
        cancom.value = true
        break
      }
    } else if (paymentDetail.value[i].type === 'PROMPT_PAY_TAX_ID') {
      let lp = []
      lp = paymentDetail.value.filter((c) => c.cusIdNumber === paymentDetail.value[i].cusIdNumber && c.receive > 0);
      if ((paymentDetail.value[i].pay === 0 && paymentDetail.value[i].receive === 0)
        || paymentDetail.value[i].sourcemoney === '0' || paymentDetail.value[i].effectiveDate === null
        || paymentDetail.value[i].cusIdNumber === '' || lp.length > 1) {
        cancom.value = true
        break
      }
    } else if (paymentDetail.value[i].type === 'CHEQUE') {
      if ((paymentDetail.value[i].pay === 0 && paymentDetail.value[i].receive === 0)
        || paymentDetail.value[i].effectiveDate === null || paymentDetail.value[i].chequeNumber === '') {
        cancom.value = true
        break
      }
    }
  }
}


async function selectCusAf(item) {
  if (activeTab.value === 'tab1') {
    cusUid_search.value = item.uid
    cusName_search.value = item.nameTh
  } else if (activeTab.value === 'tab2') {
    cusUidH_search.value = item.uid
    cusNameH_search.value = item.nameTh
  }
  document.getElementById("closeModal").click();
}

async function openDialogCus() {
  if (activeTab.value === 'tab1') {
    cusUid_search.value = ''
    cusName_search.value = ''
  } else if (activeTab.value === 'tab2') {
    cusUidH_search.value = ''
    cusNameH_search.value = ''
  }
  nameThC.value = ''
  nameEnC.value = ''
  docNumberC.value = ''
  customerlist.value = []
  document.getElementById("searchNameCus").click();
}




async function loadCustomerAfListApi() {
  currentPageC.value = 1
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        customerlist.value = listd;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumber(totalRowsC.value)
      }
    }
  }
}

async function updatePaginationC() {
  customerlist.value = []
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerlist.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumber(totalRowsC.value)
      }
    }
  }
}

function clearItemCB() {
  datas.addNew = false
  cus_bank.value = {
    uid: '',
    cusUid: "",
    bankUid: "0",
    accNumber: "",
    accName: "",
    cusBankDocs: [],
    defaultValue: false,
    note: "",
    bankStatus: "0",
    version: '',
  }
}

function checkProcess(str) {
  let side = ''
  if (str === 'FINISH') {
    side = 'เสร็จสิ้น'
  } else if (str === 'ACTIVE') {
    side = 'กำลังดำเนินการ'
  } else if (str === 'FREEZE') {
    side = 'อายัด'
  } else if (str === 'TERMINATE') {
    side = 'ยกเลิก'
  }
  return side
}


async function onlyDigitsBk() {

  if (cus_bank.value.accNumber !== "") {
    if (cus_bank.value.accNumber.match(/^[0-9]+$/)) {
      cus_bank.value.accNumber = cus_bank.value.accNumber.match(/^[0-9]+$/);
    } else {
      cus_bank.value.accNumber = cus_bank.value.accNumber.slice(0, -1);
    }
  } else {
    cus_bank.value.accNumber = "";
  }
}


async function loadCusBankListAll() {

  let data = "";
  data = {
    index: 0,
    size: 100,
    cusUid: listpaymentD.value.customerUid,
  };
  // console.log("cus uid bk : " + cus_detail.value.uid);
  const payload = data;
  // console.log("loadCusBankListAll : " + JSON.stringify(payload));
  const response = await storecb.cusbankListFetch(payload);
  // console.log("loadCusBankListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcusbankAll.value = response.data.data.list;
        // console.log("loadCusBankListApi : " + JSON.stringify(listcusbankAll.value));
      }
    }
  }
}



async function loadCusBankListApi(val) {
  clearItemCB()
  let data = "";
  if (currentPageBk.value !== 1) {
    if (listcusbank.value.length === 1 && val === 'del') {
      currentPageBk.value = currentPageBk.value - 1
    }
  }
  data = {
    index: currentPageBk.value - 1,
    size: perPageBk.value,
    cusUid: listpaymentD.value.customerUid,
  };
  // console.log("cus uid bk : " + cus_detail.value.uid);
  const payload = data;
  // console.log("loadCusBankListApi : " + JSON.stringify(payload));
  const response = await storecb.cusbankListFetch(payload);
  // console.log("loadCusBankListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcusbank.value = response.data.data.list;
        totalRowsBk.value = response.data.data.ext.total
        totalRows_sBk.value = formatNumber(totalRowsBk.value)
        loadCusBankListAll()


      }
    }
  }
}

async function updatePaginationBk() {
  listcusbank.value = []
  let data = "";
  data = {
    index: currentPageBk.value - 1,
    size: perPageBk.value,
    cusUid: listpaymentD.value.customerUid,
  };

  const payload = data;
  const response = await storecb.cusbankListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcusbank.value = response.data.data.list;
        totalRowsBk.value = response.data.data.ext.total
        totalRows_sBk.value = formatNumber(totalRowsBk.value)

      }
    }
  }
}


async function addCusBank() {
  // if (thumb.value !== null && thumb_name.value !== '') {
  //   // await addfileApi()
  // }
  let data = "";
  data = {
    cusUid: listpaymentD.value.customerUid,
    bankUid: cus_bank.value.bankUid,
    accNumber: String(cus_bank.value.accNumber),
    accName: cus_bank.value.accName,
    cusBankDocs: [],
    defaultValue: cus_bank.value.defaultValue,
    note: '',
    bankStatus: cus_bank.value.bankStatus
  };

  const payload = data;
  // console.log('addCusBank : ' + JSON.stringify(payload));
  const response = await storecb.cusbankAddFetch(payload)
  // console.log('addCusBank : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadCusBankListApi('add')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function updateCusBank() {
  // if (thumb.value !== null && thumb_name.value !== '') {
  //   // await addfileApi()
  // }
  let data = "";
  data = {
    uid: cus_bank.value.uid,
    cusUid: listpaymentD.value.customerUid,
    bankUid: cus_bank.value.bankUid,
    accNumber: String(cus_bank.value.accNumber),
    accName: cus_bank.value.accName,
    cusBankDocs: [],
    defaultValue: cus_bank.value.defaultValue,
    note: '',
    bankStatus: cus_bank.value.bankStatus,
    version: cus_bank.value.version
  };
  const payload = data;
  // console.log('updateCusBank : ' + JSON.stringify(payload));
  const response = await storecb.cusbankUpdateFetch(payload)
  // console.log('updateCusBank : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadCusBankListApi('edit')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deleteCusBank(uid) {
  let data = "";
  data = {
    uid: uid,
  };
  const payload = data;
  // console.log('deleteCusBank : ' + JSON.stringify(payload));
  const response = await storecb.cusbankDeleteFetch(payload);
  // console.log('deleteCusBank : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadCusBankListApi('del')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function loadCusAffiliateListApi() {
  customerAflist.value = []
  let data = "";
  let list = []
  data = {
    index: 0,
    size: 100,
    cusUid: listpaymentD.value.customerUid,
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecusa.cusAffiliateListFetch(payload);
  // console.log("loadCusAffiliateListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        customerAflist.value = list
        if (customerAflist.value.length > 0) {
          await findCusAffiliate()
        }
        // customerAflist.value = list.filter((c) => c.affiliateCusUid === cusuid);
      }
    }
  }
};

async function loadBankAf(id) {
  let data = "";
  data = {
    index: 0,
    size: 100,
    cusUid: id,
  };

  const payload = data;
  const response = await storecb.cusbankListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcusbankAf.value = response.data.data.list;
        // console.log("loadBankAf : " + JSON.stringify(listcusbankAf.value));
      }
    }
  }
}

async function getCustomerInfo(uid) {
  cus_detail.value = null
  let data = "";
  data = {
    uid: uid,
  };

  const payload = data;
  // console.log("CustomerInfo p : " + JSON.stringify(payload));
  const response = await storecus.customerInfoFetch(payload);
  // console.log("getCustomerInfo r : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        cus_detail.value = response.data.data
      }
    }
  }
}

async function getCustomerAfInfo(uid) {

  let data = "";
  data = {
    uid: uid,
  };

  const payload = data;
  // console.log("getCustomerAfInfo p : " + JSON.stringify(payload));
  const response = await storecus.customerInfoFetch(payload);
  // console.log("getCustomerAfInfo r : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        cusAf_detail.value = response.data.data
      }
    }
  }
}

async function findCusAffiliate() {
  customerAflist.value.map(function (x) {
    x.listbankcus = [];
    x.listpromttax = [];
    x.listpromtmobile = [];
    return x
  });

  for (let i = 0; i < customerAflist.value.length; i++) {
    cusAf_detail.value = null
    listcusbankAf.value = []
    await getCustomerAfInfo(customerAflist.value[i].affiliateCusUid)
    await loadBankAf(customerAflist.value[i].affiliateCusUid)

    if (listcusbankAf.value.length > 0) {
      customerAflist.value[i].listbankcus = listcusbankAf.value
    }
    if (cusAf_detail.value !== null) {

      if (cusAf_detail.value.cusShop.phoneNo1 !== '' && cusAf_detail.value.cusShop.phoneNo1 !== null) {

        let obj = {
          mobile: cusAf_detail.value.cusShop.phoneNo1
        }

        customerAflist.value[i].listpromtmobile.push(obj)
      }

      if (cusAf_detail.value.cusShop.phoneNo2 !== '' && cusAf_detail.value.cusShop.phoneNo2 !== null) {

        let obj = {
          mobile: cusAf_detail.value.cusShop.phoneNo2
        }

        customerAflist.value[i].listpromtmobile.push(obj)

      }

      if (cusAf_detail.value.cusShop.phoneNo3 !== '' && cusAf_detail.value.cusShop.phoneNo3 !== null) {

        let obj = {
          mobile: cusAf_detail.value.cusShop.phoneNo3
        }

        customerAflist.value[i].listpromtmobile.push(obj)

      }

      if (cusAf_detail.value.docTypeUid !== null && cusAf_detail.value.docTypeUid !== '0') {
        let dn = doctypeAccList.value.filter((c) => c.uid === cusAf_detail.value.docTypeUid)
        if (dn.length > 0) {
          if (dn[0].name === 'บัตรประชาชน') {
            let obj = {
              taxId: cusAf_detail.value.docNumber
            }
            customerAflist.value[i].listpromttax.push(obj)

          }
        }
      }

    }
  }

  // console.log("customerAflist xx : " + JSON.stringify(customerAflist.value));

}



async function openTab(item) {
  loading.value = true
  uid_edit.value = ''
  detailRemark.value = ""
  listcusbank.value = []
  listcusbankAll.value = []
  listcusmobileAll.value = []
  currentPageBk.value = 1
  customerAflist.value = []
  customerTaxlist.value = []
  listcusaffiliate.value = []
  currentPageCuf.value = 1
  totalRows_sCuf.value = '';
  datas.hide = false


  if (listbank.value.length === 0) {
    await loadBankDataApi()
  }

  if (shop_detail.value === null) {
    await cashAccShopApi()
  }

  if (doctypeAccList.value.length === 0) {
    await loadDocTypeAccListApi()
  }

  await detailPayment(item)
  paymentDetail.value = paymentE.value.payments
  listpaymentD.value = item
  await loadCusBankListApi('')
  await getCustomerInfo(listpaymentD.value.customerUid)


  if (cus_detail.value !== null) {
    if (cus_detail.value.cusShop.phoneNo1 !== '' && cus_detail.value.cusShop.phoneNo1 !== null) {
      let obj = {
        mobile: cus_detail.value.cusShop.phoneNo1
      }
      listcusmobileAll.value.push(obj)
    }
    if (cus_detail.value.cusShop.phoneNo2 !== '' && cus_detail.value.cusShop.phoneNo2 !== null) {

      let obj = {
        mobile: cus_detail.value.cusShop.phoneNo2
      }
      listcusmobileAll.value.push(obj)

    }
    if (cus_detail.value.cusShop.phoneNo3 !== '' && cus_detail.value.cusShop.phoneNo3 !== null) {

      let obj = {
        mobile: cus_detail.value.cusShop.phoneNo3
      }
      listcusmobileAll.value.push(obj)
    }

    // console.log("listcusmobileAll : " + JSON.stringify(listcusmobileAll.value));

    if (cus_detail.value.docTypeUid !== null && cus_detail.value.docTypeUid !== '0') {
      let dn = doctypeAccList.value.filter((c) => c.uid === cus_detail.value.docTypeUid)
      // console.log("doctypeAccList : " + JSON.stringify(doctypeAccList.value));
      if (dn.length > 0) {
        if (dn[0].name === 'บัตรประชาชน') {
          let obj = {
            taxId: cus_detail.value.docNumber
          }
          customerTaxlist.value.push(obj)
        }
        // console.log("customerTaxlist : " + JSON.stringify(customerTaxlist.value));
      }
    }
  }


  await loadCusAffiliateListApi()
  if (customerAflist.value.length > 0) {
    await getCusAffiliateListApi('')
  }

  listpaymentE.value = paymentE.value.workCases

  uid_edit.value = item.paymentGroupUid
  version_edit.value = item.version
  workItemUid.value = item.workItemUid
  balprice.value = item.totalPay;
  detailRemark.value = paymentE.value.remark

  for (let i = 0; i < paymentDetail.value.length; i++) {
    if (paymentDetail.value[i].pay > 0 && (paymentDetail.value[i].type === 'BANK_TRANSFER'
      || paymentDetail.value[i].type === 'PROMPT_PAY_MOBILE' || paymentDetail.value[i].type === 'PROMPT_PAY_TAX_ID')) {
      if (paymentDetail.value[i].type === 'BANK_TRANSFER') {
        let lp = []
        lp = listcusbankAll.value.filter((c) => c.uid === paymentDetail.value[i].cusBankUid);
        if (lp.length === 0 && customerAflist.value.length > 0) {
          if (customerAflist.value[i].listbankcus !== undefined) {
            if (customerAflist.value[i].listbankcus.length > 0) {
              for (let i = 0; i < customerAflist.value.length; i++) {
                lp = customerAflist.value[i].listbankcus.filter((c) => c.uid === paymentDetail.value[i].cusBankUid);
                if (lp.length > 0) {
                  break
                }
              }
            }

          }
        }
        if (lp.length > 0) {
          let lc = filterCusBank(lp[0].bankUid)
          if (lc.length > 0) {
            if (lc[0].code !== '008' && lc[0].code !== '009' && lc[0].code !== '023' && lc[0].code !== '026'
              && lc[0].code !== '027' && lc[0].code !== '035' && lc[0].code !== '080') {
              if (lc[0].code === '004' && paymentDetail.value[i].pay > 5000000) {
                await splitPayment(paymentDetail.value[i], 5000000)
              } else if ((lc[0].code === '017' || lc[0].code === '030' || lc[0].code === '031' || lc[0].code === '071')
                && paymentDetail.value[i].pay > 700000) {
                await splitPayment(paymentDetail.value[i], 700000)
                paymentDetail.value = paymentE.value.payments
              } else if ((lc[0].code !== '004' && lc[0].code !== '017' && lc[0].code !== '030' && lc[0].code !== '031' && lc[0].code !== '071')
                && paymentDetail.value[i].pay > 2000000) {
                await splitPayment(paymentDetail.value[i], 2000000)
              }
            }
          }
        }
      } else if (paymentDetail.value[i].pay > 2000000) {
        // console.log("bk 4 :" + paymentDetail.value[i].type);
        await splitPayment(paymentDetail.value[i], 2000000)
      }
    }
  }




  await getPayAbletList()
  if (paymentDetail.value.length > 0) {
    paymentDetail.value.map(function (x) {
      x.sourcemoney = null
      x.payable_uid = "0"
      x.payable_version = '0'
      x.payable_status = ""
      return x
    });

    for (let i = 0; i < paymentDetail.value.length; i++) {
      balprice.value = balprice.value - paymentDetail.value[i].pay
      balprice.value = balprice.value + paymentDetail.value[i].receive
      let sourcm = '0'
      if (paymentDetail.value[i].cashAcctUid !== '0') {
        sourcm = paymentDetail.value[i].cashAcctUid
      }
      if (paymentDetail.value[i].shopBankUid !== '0') {
        sourcm = paymentDetail.value[i].shopBankUid
      }
      paymentDetail.value[i].sourcemoney = sourcm
      let lp = paylist.value.filter((c) => c.paymentUid === paymentDetail.value[i].uid);
      if (lp.length > 0) {
        paymentDetail.value[i].payable_uid = lp[0].uid
        paymentDetail.value[i].payable_version = lp[0].version
        paymentDetail.value[i].payable_status = lp[0].transferStatus
      }
    }
  }
  balprice.value = balprice.value.toFixed(2)
  canComplete()
  loading.value = false
  await setthirdTab()

}

async function setfirstTab() {

  if (document.getElementById("second-finance")) {
    document.getElementById("second-finance").classList.remove('show', 'active')
  }

  if (document.getElementById("secondFinance")) {
    document.getElementById("secondFinance").classList.remove('show', 'active')
  }

  if (document.getElementById("third-finance")) {
    document.getElementById("third-finance").classList.remove('show', 'active')
  }

  if (document.getElementById("thirdFinance")) {
    document.getElementById("thirdFinance").classList.remove('show', 'active')
  }

  if (document.getElementById("first-finance")) {
    document.getElementById("first-finance").classList.add('show', 'active')
  }

  if (document.getElementById("firstFinance")) {
    document.getElementById("firstFinance").classList.add('show', 'active')
  }

}


async function setsecondTab() {

  if (document.getElementById("first-finance")) {
    document.getElementById("first-finance").classList.remove('show', 'active')
  }

  if (document.getElementById("firstFinance")) {
    document.getElementById("firstFinance").classList.remove('show', 'active')
  }

  if (document.getElementById("third-finance")) {
    document.getElementById("third-finance").classList.remove('show', 'active')
  }

  if (document.getElementById("thirdFinance")) {
    document.getElementById("thirdFinance").classList.remove('show', 'active')
  }

  if (document.getElementById("second-finance")) {
    document.getElementById("second-finance").classList.add('show', 'active')
  }

  if (document.getElementById("secondFinance")) {
    document.getElementById("secondFinance").classList.add('show', 'active')
  }
}

async function setthirdTab() {

  if (document.getElementById("first-finance")) {
    document.getElementById("first-finance").classList.remove('show', 'active')
  }

  if (document.getElementById("firstFinance")) {
    document.getElementById("firstFinance").classList.remove('show', 'active')
  }

  if (document.getElementById("second-finance")) {
    document.getElementById("second-finance").classList.remove('show', 'active')
  }

  if (document.getElementById("secondFinance")) {
    document.getElementById("secondFinance").classList.remove('show', 'active')
  }


  if (document.getElementById("third-finance")) {
    document.getElementById("third-finance").classList.add('show', 'active')
  }

  if (document.getElementById("thirdFinance")) {
    document.getElementById("thirdFinance").classList.add('show', 'active')
  }

}

async function addNewItemsPay() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem();
      addNewPay.value = true;
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error");
      clearItem();
    }
  });
}

async function saveNewItemsPay() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (payment_detail.value.pay > 0 && (payment_detail.value.type === 'BANK_TRANSFER'
        || payment_detail.value.type === 'PROMPT_PAY_MOBILE' || payment_detail.value.type === 'PROMPT_PAY_TAX_ID')) {
        if (payment_detail.value.sourcemoney !== '0') {
          let ld = filterBank(payment_detail.value.sourcemoney)
          if (ld.length > 0) {
            payment_detail.value.shopBankUid = payment_detail.value.sourcemoney
            checkInputPay()
            if (msginput.value === '') {
              if (payment_detail.value.pay > 0 && payment_detail.value.shopBankUid !== '0') {
                let cb = filterBank(payment_detail.value.shopBankUid)
                if (cb.length > 0) {
                  let lc = filterCusBank(cb[0].bankUid)
                  if (lc.length > 0) {
                    if (lc[0].code === '004') {
                      if (cus_detail.value.cusShop.phoneNo1 !== '' && cus_detail.value.cusShop.phoneNo1 !== null) {
                        loading.value = true
                        addItems()
                      } else {
                        swal("กรุณาเพิ่มเบอร์มือถือ", "", "error");
                      }

                    }
                  }
                }
              } else {
                loading.value = true
                addItems()
              }

            } else {
              swal(msginput.value, "", "error");
            }
          }
        } else {
          swal("กรุณาเลือกแหล่งเงิน", "", "error");
        }
      } else {
        loading.value = true
        addItems()
      }
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการบันทึก", "", "error");
      clearItem()
      getPayAbletList()
    }
  });
}

async function cancelSaveNewItemsPay() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem();
    } else if (result.dismiss == "cancel") {
      addNewPay.value = true;
    }
  });
}

async function editRowItemsPay(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem();
      payment_detail.value = {
        uid: item.uid,
        type: item.type,
        pay: item.pay,
        receive: item.receive,
        change: item.change,
        cashAcctUid: item.cashAcctUid,
        acctNumber: item.acctNumber,
        cardNumber: item.cardNumber,
        cardName: item.cardName,
        bankUid: item.bankUid,
        effectiveDate: item.effectiveDate,
        remark: item.remark,
        paymentGroupUid: item.paymentGroupUid,
        version: item.version,
        sourcemoney: item.sourcemoney,
        shopBankUid: item.shopBankUid,
        cusBankUid: item.cusBankUid,
        cusIdNumber: item.cusIdNumber,
        cusPhoneNumber: item.cusPhoneNumber,
        toAccNameTh: item.toAccNameTh,
        toAccNameEn: item.toAccNameEn,
        chequeNumber: item.chequeNumber,
        effectiveDateE: item.effectiveDateE,
        payable_uid: item.payable_uid,
        payable_version: item.payable_version,
        feeReceive: item.feeReceive,
        feePay: item.feePay,
        bankRefId: item.bankRefId,
        payable_status: item.payable_status
      }
      if (payment_detail.value.effectiveDate === '' || payment_detail.value.effectiveDate === null) {
        payment_detail.value.effectiveDateE = new Date()
      } else {
        payment_detail.value.effectiveDateE = new Date(moment(payment_detail.value.effectiveDate))
      }
      pay_e.value = item
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error");
      clearItem();
    }
  });
}

async function saveEditRowItemsPay() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (payment_detail.value.uid !== "" && payment_detail.value.version !== "") {
        if (payment_detail.value.pay > 0 && (payment_detail.value.type === 'BANK_TRANSFER'
          || payment_detail.value.type === 'PROMPT_PAY_MOBILE' || payment_detail.value.type === 'PROMPT_PAY_TAX_ID')) {
          if (payment_detail.value.sourcemoney !== '0') {
            let ld = filterBank(payment_detail.value.sourcemoney)
            if (ld.length > 0) {
              payment_detail.value.shopBankUid = payment_detail.value.sourcemoney
              checkInputPay()
              if (msginput.value === '') {
                if (payment_detail.value.pay > 0 && payment_detail.value.shopBankUid !== '0') {
                  let cb = filterBank(payment_detail.value.shopBankUid)
                  if (cb.length > 0) {
                    let lc = filterCusBank(cb[0].bankUid)
                    if (lc.length > 0) {
                      if (lc[0].code === '004') {
                        if (cus_detail.value.cusShop.phoneNo1 !== '' && cus_detail.value.cusShop.phoneNo1 !== null) {
                          loading.value = true
                          updatePayment()
                        } else {
                          swal("กรุณาเพิ่มเบอร์มือถือ", "", "error");
                        }
                      }
                    }
                  }
                } else {
                  loading.value = true
                  updatePayment()
                }

              } else {
                swal(msginput.value, "", "error");
              }
            }
          } else {
            swal("กรุณาเลือกแหล่งเงิน", "", "error");
          }
        } else {
          if (payment_detail.value.payable_uid === '0') {
            payment_detail.value.toAccNameTh = ''
            payment_detail.value.toAccNameEn = ''
            payment_detail.value.bankRefId = ''
            loading.value = true
            updateItems()

          } else {
            loading.value = true
            updatePayment()

          }
        }
      }
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกแก้ไข", "", "error");
      clearItem();
    }
  });
}

async function cancelEditRowItemsPay() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem();
      getPayAbletList()
    }
  });
}

async function deleteRowItemsPay(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteItems(item)
      }
    }
  });
}


async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.addNew = false
      addCusBank()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      clearItemCB()
    }
  })
}


async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemCB()
      cus_bank.value = item
    } else if (result.dismiss == 'cancel') {
      clearItemCB()
    }
  })
}


async function deleteRowItemsCB(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteCusBank(item.uid)
      }
    }
  });
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      if (cus_bank.value.uid !== '') {
        updateCusBank()
      }
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      clearItemCB()
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemCB()
    }
  })
}


async function onUnLockDialog(item) {
  swal({
    title: "ปลดล็อกรายการ",
    text: "คุณต้องการปลดล็อกรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      onUnLock(item)
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}

function filterCash(str) {
  return listcashAcc.value.filter((c) => c.uid === str);
}

function filterBank(str) {
  return listbankpawn.value.filter((c) => c.uid === str);
}

function filterCusBank(str) {
  return listbank.value.filter((c) => c.uid === str);
}

function filterCusBankPay(str) {
  let bd = ''
  let lp = []
  lp = listcusbankAll.value.filter((c) => c.uid === str);
  if (lp.length > 0) {
    bd = filterCusBank(lp[0].bankUid)[0].nameTh + ' ' + lp[0].accNumber + ' (' + lp[0].accName + ')'
  } else {
    if (customerAflist.value !== null) {
      if (customerAflist.value.length > 0) {
        for (let i = 0; i < customerAflist.value.length; i++) {
          if (customerAflist.value[i].listbankcus !== undefined) {
            if (customerAflist.value[i].listbankcus.length > 0) {
              lp = customerAflist.value[i].listbankcus.filter((c) => c.uid === str);
              if (lp.length > 0) {
                bd = filterCusBank(lp[0].bankUid)[0].nameTh + ' ' + lp[0].accNumber + ' (' + lp[0].accName + ')'
                break
              }
            }
          }
        }
      }
    }


  }
  return bd
}


function filterCusPromtMobile(str) {
  let bd = ''
  let lp = []
  lp = listcusmobileAll.value.filter((c) => c.mobile === str);
  if (lp.length > 0) {
    bd = ' (' + listpaymentD.value.customerName + ')'
  } else {
    if (customerAflist.value !== null) {
      if (customerAflist.value.length > 0) {
        for (let i = 0; i < customerAflist.value.length; i++) {
          if (customerAflist.value[i].listpromtmobile !== undefined) {
            if (customerAflist.value[i].listpromtmobile.length > 0) {
              lp = customerAflist.value[i].listpromtmobile.filter((c) => c.mobile === str);
              if (lp.length > 0) {
                bd = ' (' + customerAflist.value[i].nameTh + ')'
                break
              }
            }
          }
        }
      }
    }
  }
  return bd
}

function filterCusPromtTax(str) {
  let bd = ''
  let lp = []
  lp = customerTaxlist.value.filter((c) => c.taxId === str);
  if (lp.length > 0) {
    bd = ' (' + listpaymentD.value.customerName + ')'
  } else {
    if (customerAflist.value !== null) {
      if (customerAflist.value.length > 0) {
        for (let i = 0; i < customerAflist.value.length; i++) {
          if (customerAflist.value[i].listpromttax !== undefined) {
            if (customerAflist.value[i].listpromttax.length > 0) {
              lp = customerAflist.value[i].listpromttax.filter((c) => c.taxId === str);
              if (lp.length > 0) {
                bd = ' (' + customerAflist.value[i].nameTh + ')'
                break
              }
            }
          }
        }
      }
    }
  }
  return bd
}



function checkPayType(str) {
  let side = ''
  if (str === 'CASH') {
    side = t("content.amncash")
  } else if (str === 'BANK_TRANSFER') {
    side = t("content.amntransfer")
  } else if (str === 'CREDIT_CARD') {
    side = t("content.amncreditcard")
  } else if (str === 'CHEQUE') {
    side = t("content.amncheque")
  } else if (str === 'PROMPT_PAY_MOBILE') {
    side = t("content.amntransfer_promt_mobile")
  } else if (str === 'PROMPT_PAY_TAX_ID') {
    side = t("content.amntransfer_promt_taxid")
  }
  return side
}

function checkMoneySource(str) {
  let side = ''
  if (str !== '0') {
    let ld = filterBank(str)
    if (ld.length > 0) {
      side = ld[0].shortName
    } else {
      ld = filterCash(str)
      if (ld.length > 0) {
        side = ld[0].name
      }
    }
  }
  return side
}



async function clearItem() {
  toAccNameTH.value = ''
  toAccNameEN.value = ''
  pay_e.value = {}
  addNewPay.value = false
  payment_detail.value = {
    uid: '',
    type: '0',
    pay: 0,
    receive: 0,
    change: '',
    cashAcctUid: '',
    acctNumber: '',
    cardNumber: '',
    cardName: '',
    bankUid: '',
    effectiveDate: new Date(),
    remark: '',
    paymentGroupUid: '',
    version: '',
    sourcemoney: '0',
    shopBankUid: '0',
    cusBankUid: '0',
    cusIdNumber: "",
    cusPhoneNumber: "0",
    toAccNameTh: "",
    toAccNameEn: "",
    chequeNumber: "",
    effectiveDateE: new Date(),
    payable_uid: '0',
    payable_version: '0',
    feeReceive: 0,
    feePay: 0,
    bankRefId: ''
  }
}

async function checkInputPay() {
  msginput.value = ''
  if (payment_detail.value.cusBankUid !== '0') {
    let lp = []
    lp = listcusbankAll.value.filter((c) => c.uid === payment_detail.value.cusBankUid);
    if (lp.length === 0 && customerAflist.value.length > 0) {
      for (let i = 0; i < customerAflist.value.length; i++) {
        if (customerAflist.value[i].listbankcus !== undefined) {
          if (customerAflist.value[i].listbankcus.length > 0) {
            lp = customerAflist.value[i].listbankcus.filter((c) => c.uid === payment_detail.value.cusBankUid);
            if (lp.length > 0) {
              break
            }
          }
        }
      }
    }
    if (lp.length > 0) {
      let lc = filterCusBank(lp[0].bankUid)
      if (lc.length > 0) {
        if (lc[0].code === '008' || lc[0].code === '009' || lc[0].code === '023' || lc[0].code === '026'
          || lc[0].code === '027' || lc[0].code === '035' || lc[0].code === '080') {
          msginput.value = 'ไม่สามารถโอนเงินไปยังบัญชีของธนาคารนี้ได้'
        } else {
          if (lc[0].code === '004' && payment_detail.value.pay > 5000000) {
            msginput.value = 'ระบุจำนวนเงินได้ไม่เกิน 5,000,000'
          } else if ((lc[0].code === '017' || lc[0].code === '030' || lc[0].code === '031' || lc[0].code === '071') && payment_detail.value.pay > 700000) {
            msginput.value = 'ระบุจำนวนเงินได้ไม่เกิน 700,000'
          } else if ((lc[0].code !== '004' && lc[0].code !== '017' && lc[0].code !== '030' && lc[0].code !== '031' && lc[0].code !== '071') && payment_detail.value.pay > 2000000) {
            msginput.value = 'ระบุจำนวนเงินได้ไม่เกิน 2,000,000'
          }
        }
      } else {
        msginput.value = 'ไม่พบข้อมูลธนาคาร'
      }
    } else {
      msginput.value = 'ไม่พบข้อมูลบัญชีธนาคาร'
    }
  } else if (payment_detail.value.cusPhoneNumber !== '' || payment_detail.value.cusIdNumber !== '') {
    if (payment_detail.value.pay > 2000000) {
      msginput.value = 'ระบุจำนวนเงินได้ไม่เกิน 2,000,000'
    }
  }

}

async function addItems(val) {

  if (payment_detail.value.sourcemoney !== '0') {
    let ld = filterBank(payment_detail.value.sourcemoney)
    if (ld.length > 0) {
      payment_detail.value.shopBankUid = payment_detail.value.sourcemoney
    } else {
      ld = filterCash(payment_detail.value.sourcemoney)
      if (ld.length > 0) {
        payment_detail.value.cashAcctUid = payment_detail.value.sourcemoney
      }
    }
  }
  payment_detail.value.paymentGroupUid = uid_edit.value
  payment_detail.value.pay = parseFloat(payment_detail.value.pay)

  if (payment_detail.value.cusPhoneNumber !== null) {
    payment_detail.value.cusPhoneNumber = payment_detail.value.cusPhoneNumber.replace(/[^\d]/g, "");
  }

  if (payment_detail.value.cusIdNumber !== null) {
    payment_detail.value.cusIdNumber = payment_detail.value.cusIdNumber.replace(/[^\d]/g, "");
  }


  // console.log('addItems payload : ' + JSON.stringify(payment_detail.value.effectiveDate) + ' new :  '+moment(payment_detail.value.effectiveDate).format("YYYY-MM-DD"));
  // payment_detail.value.effectiveDate = moment(payment_detail.value.effectiveDate).format("YYYY-MM-DD")
  const payload = payment_detail.value
  payload.effectiveDate = moment(payment_detail.value.effectiveDate).format("YYYY-MM-DD")
  payload.version = paymentE.value.version
  // console.log('addItems p : ' + JSON.stringify(payload));
  const response = await storepm.paymentnewFetch(payload);
  // console.log('addItems r : ' + JSON.stringify(response.data));
  if (response) {
    // loading.value = false
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value.version = response.data.data.version

        if ((payment_detail.value.type === 'BANK_TRANSFER' || payment_detail.value.type === 'PROMPT_PAY_MOBILE'
          || payment_detail.value.type === 'PROMPT_PAY_TAX_ID') && val !== 'pass') {
          // console.log('val : ' + val);
          if (payment_detail.value.sourcemoney !== '0') {
            let ld = filterBank(payment_detail.value.sourcemoney)
            if (ld.length > 0) {
              payment_detail.value.shopBankUid = payment_detail.value.sourcemoney
            }
          }
          if (payment_detail.value.pay > 0 && payment_detail.value.shopBankUid !== '0') {
            let cb = filterBank(payment_detail.value.shopBankUid)
            if (cb.length > 0) {
              let lc = filterCusBank(cb[0].bankUid)
              if (lc.length > 0) {
                if (lc[0].code === '004') {
                  addPayable(response.data.data, 'add')
                } else {
                  loading.value = false
                  addNewPay.value = false;
                  swal("บันทึกสำเร็จ", "", "success");
                  getPayAbletList()
                }
              }
            } else {
              loading.value = false
              addNewPay.value = false;
              swal("บันทึกสำเร็จ", "", "success");
              getPayAbletList()
            }
          } else {
            loading.value = false
            addNewPay.value = false;
            swal("บันทึกสำเร็จ", "", "success");
            getPayAbletList()
          }

        } else {
          loading.value = false
          addNewPay.value = false;
          swal("บันทึกสำเร็จ", "", "success");
          getPayAbletList()
        }
      } else {
        loading.value = false
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        if (response.data.rtnDesc === 'object already modified') {
          backToSecondTab()
        }
      }
    }
  }
}

async function updatePayment() {
  if (payment_detail.value.type === 'BANK_TRANSFER' || payment_detail.value.type === 'PROMPT_PAY_MOBILE'
    || payment_detail.value.type === 'PROMPT_PAY_TAX_ID') {
    if (payment_detail.value.sourcemoney !== '0') {
      let ld = filterBank(payment_detail.value.sourcemoney)
      if (ld.length > 0) {
        payment_detail.value.shopBankUid = payment_detail.value.sourcemoney
      }
    }
    if (payment_detail.value.payable_uid === '0') {
      if (payment_detail.value.pay > 0 && payment_detail.value.shopBankUid !== '0') {
        let cb = listbankpawn.value.filter((c) => c.uid === payment_detail.value.shopBankUid)
        if (cb.length > 0) {
          let lc = filterCusBank(cb[0].bankUid)
          if (lc.length > 0) {
            if (lc[0].code === '004') {
              addPayable(payment_detail.value, 'update')
            } else {
              payment_detail.value.toAccNameTh = ''
              payment_detail.value.toAccNameEn = ''
              payment_detail.value.bankRefId = ''
              updateItems()
            }
          }
        } else {
          payment_detail.value.toAccNameTh = ''
          payment_detail.value.toAccNameEn = ''
          payment_detail.value.bankRefId = ''
          updateItems()
        }
      } else {
        payment_detail.value.toAccNameTh = ''
        payment_detail.value.toAccNameEn = ''
        payment_detail.value.bankRefId = ''
        updateItems()
      }
    } else {
      if (payment_detail.value.pay > 0 && payment_detail.value.shopBankUid !== '0') {
        let cb = listbankpawn.value.filter((c) => c.uid === payment_detail.value.shopBankUid)
        if (cb.length > 0) {
          let lc = filterCusBank(cb[0].bankUid)
          if (lc.length > 0) {
            if (lc[0].code === '004') {
              await getPayableInfo(payment_detail.value.payable_uid, 'retrive')
              addPayable(payment_detail.value, 'update')
              // updatePayable(payment_detail.value)
            } else {
              // deletePayable(payment_detail.value.payable_uid, 'error')
              await getPayableInfo(payment_detail.value.payable_uid, 'error')
              payment_detail.value.toAccNameTh = ''
              payment_detail.value.toAccNameEn = ''
              payment_detail.value.bankRefId = ''
              updateItems()
            }
          }
        }
      } else {
        // deletePayable(payment_detail.value.payable_uid, 'error')
        await getPayableInfo(payment_detail.value.payable_uid, 'error')
        payment_detail.value.toAccNameTh = ''
        payment_detail.value.toAccNameEn = ''
        payment_detail.value.bankRefId = ''
        updateItems()
      }
    }
  } else {
    if (payment_detail.value.payable_uid !== '0') {
      // deletePayable(payment_detail.value.payable_uid, 'error')
      await getPayableInfo(payment_detail.value.payable_uid, 'error')
      payment_detail.value.toAccNameTh = ''
      payment_detail.value.toAccNameEn = ''
      payment_detail.value.bankRefId = ''
      updateItems()
    } else {
      payment_detail.value.toAccNameTh = ''
      payment_detail.value.toAccNameEn = ''
      payment_detail.value.bankRefId = ''
      updateItems()
    }
  }

}


async function retryUpdatePayment() {
  if (payment_detail.value.type === 'BANK_TRANSFER' || payment_detail.value.type === 'PROMPT_PAY_MOBILE'
    || payment_detail.value.type === 'PROMPT_PAY_TAX_ID') {
    if (payment_detail.value.sourcemoney !== '0') {
      let ld = filterBank(payment_detail.value.sourcemoney)
      if (ld.length > 0) {
        payment_detail.value.shopBankUid = payment_detail.value.sourcemoney
      }
    }
    if (payment_detail.value.payable_uid === '0') {
      if (payment_detail.value.pay > 0 && payment_detail.value.shopBankUid !== '0') {
        let cb = listbankpawn.value.filter((c) => c.uid === payment_detail.value.shopBankUid)
        if (cb.length > 0) {
          let lc = filterCusBank(cb[0].bankUid)
          if (lc.length > 0) {
            if (lc[0].code === '004') {
              addPayable(payment_detail.value, 'update')
            } else {
              payment_detail.value.toAccNameTh = ''
              payment_detail.value.toAccNameEn = ''
              payment_detail.value.bankRefId = ''
              updateItems()
            }
          }
        } else {
          payment_detail.value.toAccNameTh = ''
          payment_detail.value.toAccNameEn = ''
          payment_detail.value.bankRefId = ''
          updateItems()
        }
      } else {
        payment_detail.value.toAccNameTh = ''
        payment_detail.value.toAccNameEn = ''
        payment_detail.value.bankRefId = ''
        updateItems()
      }
    } else {
      if (payment_detail.value.pay > 0 && payment_detail.value.shopBankUid !== '0') {
        let cb = listbankpawn.value.filter((c) => c.uid === payment_detail.value.shopBankUid)
        if (cb.length > 0) {
          let lc = filterCusBank(cb[0].bankUid)
          if (lc.length > 0) {
            if (lc[0].code === '004') {
              await getPayableInfo(payment_detail.value.payable_uid, 'retrive')
              addPayable(payment_detail.value, 'update')
              // updatePayable(payment_detail.value)
            } else {
              // deletePayable(payment_detail.value.payable_uid, 'error')
              await getPayableInfo(payment_detail.value.payable_uid, 'error')
              payment_detail.value.toAccNameTh = ''
              payment_detail.value.toAccNameEn = ''
              payment_detail.value.bankRefId = ''
              updateItems()
            }
          }
        }
      } else {
        // deletePayable(payment_detail.value.payable_uid, 'error')
        await getPayableInfo(payment_detail.value.payable_uid, 'error')
        payment_detail.value.toAccNameTh = ''
        payment_detail.value.toAccNameEn = ''
        payment_detail.value.bankRefId = ''
        updateItems()
      }
    }
  }

}

async function updateItems() {

  if (payment_detail.value.sourcemoney !== '0') {
    let ld = filterBank(payment_detail.value.sourcemoney)
    if (ld.length > 0) {
      payment_detail.value.shopBankUid = payment_detail.value.sourcemoney
    } else {
      ld = filterCash(payment_detail.value.sourcemoney)
      if (ld.length > 0) {
        payment_detail.value.cashAcctUid = payment_detail.value.sourcemoney
      }
    }
  }

  payment_detail.value.pay = parseFloat(payment_detail.value.pay)

  if (payment_detail.value.cusPhoneNumber !== null) {
    payment_detail.value.cusPhoneNumber = payment_detail.value.cusPhoneNumber.replace(/[^\d]/g, "");
  }

  if (payment_detail.value.cusIdNumber !== null) {
    payment_detail.value.cusIdNumber = payment_detail.value.cusIdNumber.replace(/[^\d]/g, "");
  }

  const payload = payment_detail.value
  payload.effectiveDate = moment(payment_detail.value.effectiveDateE).format("YYYY-MM-DD")
  payload.version = paymentE.value.version
  // console.log('updateItems p : ' + JSON.stringify(payload));
  const response = await storepm.paymentupdateFetch(payload);
  // console.log('updateItems r : ' + JSON.stringify(response.data));
  if (response) {
    loading.value = false
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value.version = response.data.data.version
        swal("บันทึกสำเร็จ", "", "success");
        await getPayAbletList()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        if (response.data.rtnDesc === 'object already modified') {
          backToSecondTab()
        }
      }
    }
  }
}

async function deleteItems(item) {
  let data = "";
  data = {
    uid: item.uid,
    version: paymentE.value.version
  };
  const payload = data;
  // console.log('deleteItems p : ' + JSON.stringify(payload));
  const response = await storepm.paymentDeleteFetch(payload);
  // console.log('deleteItems r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value.version = response.data.data.version
        // console.log('payable_uid : ' + item.payable_uid);
        if (item.payable_uid !== '0') {
          // deletePayable(item.payable_uid, '')
          await getPayableInfo(item.payable_uid, '')
        } else {
          swal("บันทึกสำเร็จ", "", "success");
          getPayAbletList()
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        if (response.data.rtnDesc === 'record not found') {
          getPayAbletList()
        } else if (response.data.rtnDesc === 'object already modified') {
          backToSecondTab()
        }
      }
    }
  }
}

async function addPayable(item, status) {
  let data = "";
  let transf = ''
  let accNumber = ''
  let code = null
  let fromAccount = null
  let fromAccNameEN = null
  let mb = cus_detail.value.cusShop.phoneNo1.replace(/[^\d]/g, "");
  let bp = filterBank(payment_detail.value.shopBankUid)
  if (bp.length > 0) {
    fromAccount = bp[0].acctNumber.replace(/[^\d]/g, "");
    fromAccNameEN = bp[0].shortName.trim()
  }
  if (item.type === 'BANK_TRANSFER') {
    transf = 'BANK_ACCOUNT'
    let cb = filterCusBankAll(item.cusBankUid)
    if (cb.length > 0) {
      accNumber = cb[0].accNumber.replace(/[^\d]/g, "");
      let lc = filterCusBank(cb[0].bankUid)
      if (lc.length > 0) {
        code = lc[0].code
      }
    }
  } else if (item.type === 'PROMPT_PAY_MOBILE') {
    transf = 'MOBILE_NUMBER'
    accNumber = payment_detail.value.cusPhoneNumber.replace(/[^\d]/g, "");
  } else if (item.type === 'PROMPT_PAY_TAX_ID') {
    transf = 'CITIZEN_ID'
    accNumber = payment_detail.value.cusIdNumber.replace(/[^\d]/g, "");
  }


  data = {
    shopUid: shop_detail.value.uid,
    paymentGroupUid: item.paymentGroupUid,
    paymentUid: item.uid,
    cusUid: listpaymentD.value.customerUid,
    cusMobileNo: mb,
    transferType: transf,
    toBankCode: code,
    toAccount: accNumber,
    amount: parseFloat(payment_detail.value.pay),
    fromAccount: fromAccount,
    fromAccNameEN: fromAccNameEN,
    shopTaxId: shop_detail.value.taxId.replace(/[^\d]/g, "")
  };
  const payload = data;

  // console.log('newPayable p : ' + JSON.stringify(payload));
  const response = await storepay.payAbleNewFetch(payload);
  // console.log('newPayable r : ' + JSON.stringify(response.data));
  if (response) {
    loading.value = false
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (response.data.data !== null) {
          if (status === 'update') {
            payment_detail.value.toAccNameTh = response.data.data.toAccNameTH
            payment_detail.value.toAccNameEn = response.data.data.toAccNameEN
            payment_detail.value.bankRefId = response.data.data.bankRefId
            updateItems()
          } else {
            updateToAccountName(response.data.data)
          }
        } else {
          await getPayAbletList('check')
          if (paylist.value.length > 0) {
            let lp = paylist.value.filter((c) => c.paymentUid === item.uid);
            if (lp.length > 0) {
              let obj = {
                paymentUid: item.uid,
                toAccNameTH: lp[0].toAccNameTH,
                toAccNameEN: lp[0].toAccNameEN,
                bankRefId: lp[0].bankRefId
              }
              updateToAccountName(obj)
            }
          }
        }


      } else {
        // swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        if (response.data.data !== null) {
          payment_detail.value.payable_version = response.data.data.version
          payment_detail.value.payable_uid = response.data.data.uid
          await getPayableInfo(response.data.data.uid, 'error')
        } else {
          await getPayAbletList('check')
          if (paylist.value.length > 0) {
            let lp = paylist.value.filter((c) => c.paymentUid === item.uid);
            if (lp.length > 0) {
              payment_detail.value.payable_version = lp[0].version
              payment_detail.value.payable_uid = lp[0].uid
              await getPayableInfo(lp[0].uid, 'error')
            }
          }
        }
        if (status === 'add') {
          deletePayment(item.uid)
        } else if (status === 'update') {
          if (pay_e.value.payable_uid !== '0') {
            await getPayableInfo(payment_detail.value.payable_uid, 'error')
            retrivePayable()
          }

        }
        await onErrorPayment(response.data.rtnDesc, status)

      }
    }
  }
}

async function updatePayable(item) {
  // console.log('updatePayable item : ' + JSON.stringify(item));
  let data = "";
  let transf = ''
  let accNumber = ''
  let code = null
  let fromAccount = null
  let fromAccNameEN = null
  let mb = cus_detail.value.cusShop.phoneNo1.replace(/[^\d]/g, "");
  let bp = filterBank(payment_detail.value.shopBankUid)
  if (bp.length > 0) {
    fromAccount = bp[0].acctNumber.replace(/[^\d]/g, "");
    fromAccNameEN = bp[0].shortName.trim()

  }
  if (item.type === 'BANK_TRANSFER') {
    transf = 'BANK_ACCOUNT'
    let cb = filterCusBankAll(item.cusBankUid)
    if (cb.length > 0) {
      accNumber = cb[0].accNumber.replace(/[^\d]/g, "");
      let lc = filterCusBank(cb[0].bankUid)
      if (lc.length > 0) {
        code = lc[0].code
      }
    }
  } else if (item.type === 'PROMPT_PAY_MOBILE') {
    transf = 'MOBILE_NUMBER'
    accNumber = payment_detail.value.cusPhoneNumber.replace(/[^\d]/g, "");
  } else if (item.type === 'PROMPT_PAY_TAX_ID') {
    transf = 'CITIZEN_ID'
    accNumber = payment_detail.value.cusIdNumber.replace(/[^\d]/g, "");
  }

  data = {
    uid: payment_detail.value.payable_uid,
    shopUid: shop_detail.value.uid,
    paymentGroupUid: uid_edit.value,
    paymentUid: payment_detail.value.uid,
    cusUid: listpaymentD.value.customerUid,
    cusMobileNo: mb,
    transferType: transf,
    toBankCode: code,
    toAccount: accNumber,
    amount: parseFloat(payment_detail.value.pay),
    version: payment_detail.value.payable_version,
    fromAccount: fromAccount,
    fromAccNameEN: fromAccNameEN,
    shopTaxId: shop_detail.value.taxId.replace(/[^\d]/g, "")

  };
  const payload = data;

  // console.log('updatePayable p : ' + JSON.stringify(payload));
  const response = await storepay.payAbleUpdateFetch(payload);
  // console.log('updatePayable r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (response.data.data !== null) {
          updateToAccountName(response.data.data)
        } else {
          toAccNameTH.value = ''
          toAccNameEN.value = ''
          await getPayableInfo(payment_detail.value.payable_uid, 'info')
          let obj = {
            paymentUid: payment_detail.value.uid,
            toAccNameTH: toAccNameTH.value,
            toAccNameEN: toAccNameEN.value,
            bankRefId: payment_detail.value.bankRefId
          }
          updateToAccountName(obj)
        }

      } else {
        // swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        if (response.data.data !== null) {
          payment_detail.value.payable_version = response.data.data.version
          payment_detail.value.payable_uid = response.data.data.uid
          await getPayableInfo(payment_detail.value.payable_uid, 'retrive')
          retrivePayable()
        } else {
          await getPayableInfo(payment_detail.value.payable_uid, 'retrive')
          retrivePayable()
        }
        await onErrorPayment(response.data.rtnDesc, 'update')
        getPayAbletList
      }
    }
  }
}


async function retrivePayable() {
  let data = "";
  let transf = ''
  let accNumber = ''
  let code = null
  let fromAccount = null
  let fromAccNameEN = null
  let mb = cus_detail.value.cusShop.phoneNo1.replace(/[^\d]/g, "");
  let bp = filterBank(pay_e.value.shopBankUid)
  if (bp.length > 0) {
    fromAccount = bp[0].acctNumber.replace(/[^\d]/g, "");
    fromAccNameEN = bp[0].shortName.trim()

  }
  if (pay_e.value.type === 'BANK_TRANSFER') {
    transf = 'BANK_ACCOUNT'
    let cb = filterCusBankAll(pay_e.value.cusBankUid)
    if (cb.length > 0) {
      accNumber = cb[0].accNumber.replace(/[^\d]/g, "");
      let lc = filterCusBank(cb[0].bankUid)
      if (lc.length > 0) {
        code = lc[0].code
      }
    }
  } else if (pay_e.value.type === 'PROMPT_PAY_MOBILE') {
    transf = 'MOBILE_NUMBER'
    accNumber = pay_e.value.cusPhoneNumber.replace(/[^\d]/g, "");
  } else if (pay_e.value.type === 'PROMPT_PAY_TAX_ID') {
    transf = 'CITIZEN_ID'
    accNumber = pay_e.value.cusIdNumber.replace(/[^\d]/g, "");
  }

  data = {
    // uid: pay_e.value.payable_uid,
    shopUid: shop_detail.value.uid,
    paymentGroupUid: uid_edit.value,
    paymentUid: pay_e.value.uid,
    cusUid: listpaymentD.value.customerUid,
    cusMobileNo: mb,
    transferType: transf,
    toBankCode: code,
    toAccount: accNumber,
    amount: parseFloat(pay_e.value.pay),
    version: payment_detail.value.payable_version,
    fromAccount: fromAccount,
    fromAccNameEN: fromAccNameEN,
    shopTaxId: shop_detail.value.taxId.replace(/[^\d]/g, "")

  };
  const payload = data;
  // console.log('retrivePayable p : ' + JSON.stringify(payload));
  // const response = await storepay.payAbleUpdateFetch(payload);
  const response = await storepay.payAbleNewFetch(payload);
  // console.log('retrivePayable r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (response.data.data !== null) {
          payment_detail.value.payable_version = response.data.data.version
          payment_detail.value.payable_uid = response.data.data.uid
          await retriveToAccountName(response.data.data)
        } else {
          await getPayAbletList('check')
          if (paylist.value.length > 0) {
            let lp = paylist.value.filter((c) => c.paymentUid === pay_e.value.uid);
            if (lp.length > 0) {
              let obj = {
                paymentUid: pay_e.value.uid,
                toAccNameTH: lp[0].toAccNameTH,
                toAccNameEN: lp[0].toAccNameEN,
                bankRefId: lp[0].bankRefId
              }
              await retriveToAccountName(obj)
            }
          }
        }
      } else {
        if (response.data.data !== null) {
          payment_detail.value.payable_version = response.data.data.version
          payment_detail.value.payable_uid = response.data.data.uid
          await getPayableInfo(response.data.data.uid, 'error')
          await retriveToAccountName(response.data.data)
        } else {
          await getPayAbletList('check')
          if (paylist.value.length > 0) {
            let lp = paylist.value.filter((c) => c.paymentUid === pay_e.value.uid);
            if (lp.length > 0) {
              let obj = {
                paymentUid: pay_e.value.uid,
                toAccNameTH: lp[0].toAccNameTH,
                toAccNameEN: lp[0].toAccNameEN,
                bankRefId: lp[0].bankRefId
              }
              await getPayableInfo(lp[0].uid, 'error')
              await retriveToAccountName(obj)
            }
          }
        }
      }
    }
  }
}


async function getPayableInfo(uid, status) {
  let payload = ""
  payload = {
    uid: uid
  }

  const response = await storepay.payAbleInfoFetch(payload);
  // console.log('Info r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (response.data.data !== null) {
          if (status === 'pass') {
            await deletePayable(uid, 'error')
            payment_detail.value.toAccNameTh = ''
            payment_detail.value.toAccNameEn = ''
            payment_detail.value.bankRefId = ''
            updateItems()
          } else if (status === 'info') {
            toAccNameTH.value = response.data.data.toAccNameTH
            toAccNameEN.value = response.data.data.toAccNameEN
            payment_detail.value.bankRefId = response.data.data.bankRefId
            payment_detail.value.payable_version = response.data.data.version
            payment_detail.value.payable_uid = response.data.data.uid
          } else {
            await deletePayable(uid, status)
          }

        }
      } else if (response.data.rtnDesc === "record not found" && status === 'pass') {
        payment_detail.value.toAccNameTh = ''
        payment_detail.value.toAccNameEn = ''
        payment_detail.value.bankRefId = ''
        updateItems()
      }
    }
  }
}

async function deletePayable(uid, status) {
  let payload = ""
  payload = {
    uid: uid
  }
  // console.log('deletePayable p : ' + JSON.stringify(payload));
  const response = await storepay.payAbleDeleteFetch(payload);
  // console.log('deletePayable r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (status !== 'error' && status !== 'retrive') {
          swal("บันทึกสำเร็จ", "", "success");
          await getPayAbletList()
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deletePayment(uid) {
  let data = "";
  data = {
    uid: uid,
    version: paymentE.value.version
  };
  const payload = data;
  // console.log('deleteItems p : ' + JSON.stringify(payload));
  const response = await storepm.paymentDeleteFetch(payload);
  // console.log('deleteItems r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value.version = response.data.data.version
        // console.log('pg version : ' + JSON.stringify(paymentE.value.version));
      }
    }
  }
}


function filterCusBankAll(str) {
  let lp = []
  lp = listcusbankAll.value.filter((c) => c.uid === str);
  if (lp.length === 0) {
    if (customerAflist.value !== null) {
      if (customerAflist.value.length > 0) {
        for (let i = 0; i < customerAflist.value.length; i++) {
          if (customerAflist.value[i].listbankcus !== undefined) {
            if (customerAflist.value[i].listbankcus.length > 0) {
              lp = customerAflist.value[i].listbankcus.filter((c) => c.uid === str);
              break
            }
          }
        }
      }
    }
  }
  return lp
}

async function getPaymentList() {
  let data = "";
  data = {
    index: 0,
    size: 100,
    pguid: uid_edit.value,
  };
  const payload = data;
  const response = await storepm.paymentlistFetch(payload);
  // console.log("getPaymentList : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentDetail.value = response.data.data.list;
        balprice.value = listpaymentD.value.totalPay;

        if (paymentDetail.value.length > 0) {
          paymentDetail.value.map(function (x) {
            x.sourcemoney = null
            x.payable_uid = '0'
            x.payable_version = '0'
            x.payable_status = ""
            return x
          });

          for (let i = 0; i < paymentDetail.value.length; i++) {
            balprice.value = balprice.value - paymentDetail.value[i].pay
            balprice.value = balprice.value + paymentDetail.value[i].receive
            let sourcm = '0'
            if (paymentDetail.value[i].cashAcctUid !== '0') {
              sourcm = paymentDetail.value[i].cashAcctUid
            }
            if (paymentDetail.value[i].shopBankUid !== '0') {
              sourcm = paymentDetail.value[i].shopBankUid
            }
            paymentDetail.value[i].sourcemoney = sourcm
            let lp = paylist.value.filter((c) => c.paymentUid === paymentDetail.value[i].uid);
            if (lp.length > 0) {
              paymentDetail.value[i].payable_uid = lp[0].uid
              paymentDetail.value[i].payable_version = lp[0].version
              paymentDetail.value[i].payable_status = lp[0].transferStatus
            }
          }
        }
        canComplete()
        balprice.value = balprice.value.toFixed(2)
        // console.log('paymentDetail  xx : ' + JSON.stringify(paymentDetail.value))
      }
      clearItem();
    }
  }
}

async function getPayAbletList(status) {
  paylist.value = []
  let data = "";
  data = {
    index: 0,
    size: 100,
    paymentGroupUid: uid_edit.value,
  };
  const payload = data;
  const response = await storepay.listTxPayableFetch(payload);

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paylist.value = response.data.data.list;
        if (status !== 'check') {
          getPaymentList()
        }
        // console.log("PayAbletList : " + JSON.stringify(paylist.value));
      }
    }
  }
}


async function completeStep(item) {
  let data = "";
  data = {
    uid: item.uid,
    action: item.action,
    version: item.version,
  };
  const payload = data;
  // const response = await store.dispatch("unitUpdateFetch", payload);
  const response = await storew.completeStepFetch(payload);
  // console.log('completeStep : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        backToSecondTab()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function chkSelect() {
  let process = false
  let lockL = []
  for (let i = 0; i < listQ02.value.length; i++) {
    if (listQ02.value[i].chk) {
      process = true
      // console.log('chkSelect u : ' + JSON.stringify(listQ02.value[i].workItemUid) + ' v : ' + JSON.stringify(listQ02.value[i].version));
      lockL.push({ uid: listQ02.value[i].workItemUid, version: listQ02.value[i].version })

    }
  }

  if (!process) {
    swal("กรุณาเลือกรายการ", "", "error");
  } else {
    // console.log('lockL : ' + JSON.stringify(lockL));
    await lock(lockL)
  }

}


async function onUnLock(item) {
  // console.log('onUnLock : ' + JSON.stringify(item.workItemUid) + " version : " + JSON.stringify(item.version));
  let lockL = []
  lockL.push({ uid: item.workItemUid, version: item.version })
  await unlock(lockL)

}


async function lock(item) {
  let data = "";
  data = {
    lock: item,
  };
  const payload = data;
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storew.lockFetch(payload);
  // console.log('response lock : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataQ02Api()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function unlock(item) {
  let data = "";
  data = {
    lock: item,
  };
  const payload = data;
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storew.unlockFetch(payload);
  // console.log('response : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        // console.log('workItemUid.value : ' + workItemUid.value + ' item.uid :' + item.uid);
        if (workItemUid.value !== '' && workItemUid.value === item[0].uid) {
          backToSecondTab()
        } else {
          loadDataQ02OwnerApi()
        }

      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function detailPayment(item) {
  paymentE.value = null
  let data = "";
  data = {
    uid: item.paymentGroupUid,
  };
  const payload = data;
  const response = await storep.paymentGroupinfoFetch(payload);
  // console.log('detailPayment : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value = response.data.data
        // loadDataApi('update');
      }
    }
  }
}


async function updateRemarkPaymentGroup() {
  // console.log('detailPayment : ' + JSON.stringify(listpaymentD.value));
  let data = "";
  data = {
    uid: uid_edit.value,
    totalPay: paymentE.value.totalPay,
    roundTotalPay: paymentE.value.roundTotalPay,
    totalReceive: paymentE.value.totalReceive,
    roundTotalReceive: paymentE.value.roundTotalReceive,
    remark: detailRemark.value,
    version: paymentE.value.version,
    paySide: paymentE.value.paySide,
    totalFeeReceive: paymentE.value.totalFeeReceive,
    totalFeePay: paymentE.value.totalFeePay
  };
  const payload = data;
  // console.log('updateRemarkPaymentGroup : ' + JSON.stringify(payload));
  const response = await storep.paymentGroupupdateFetch(payload);
  // console.log('detailPayment : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        onComplete()
      }
    }
  }
}


async function splitPayment(item, sub) {
  let total = item.pay
  let obj = null
  for (var i = item.pay; i !== 0;) {
    if (total > sub) {
      total = total - sub
      i = total
      obj = item
      obj.pay = sub.toFixed(2)
    } else {
      obj = item
      obj.pay = total.toFixed(2)
      total = total - total
      i = 0
    }

    await newSplitPayment(obj)
    if (total === 0) {
      deletePayment(item.uid)
      await detailPayment(item)
      paymentDetail.value = paymentE.value.payments
      break

    }
  }
}


async function newSplitPayment(item) {
  const payload = item;
  payload.pay = parseFloat(payload.pay)
  payload.version = paymentE.value.version
  // console.log('split p : ' + JSON.stringify(payload));
  const response = await storepm.paymentnewFetch(payload);
  // console.log('split r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value.version = response.data.data.version
      }
    }
  }
}

async function updateToAccountName(item) {
  if (payment_detail.value.sourcemoney !== '0') {
    let ld = filterBank(payment_detail.value.sourcemoney)
    if (ld.length > 0) {
      payment_detail.value.shopBankUid = payment_detail.value.sourcemoney
    } else {
      ld = filterCash(payment_detail.value.sourcemoney)
      if (ld.length > 0) {
        payment_detail.value.cashAcctUid = payment_detail.value.sourcemoney
      }
    }
  }
  payment_detail.value.pay = parseFloat(payment_detail.value.pay)
  if (payment_detail.value.cusPhoneNumber !== null) {
    payment_detail.value.cusPhoneNumber = payment_detail.value.cusPhoneNumber.replace(/[^\d]/g, "");
  }
  if (payment_detail.value.cusIdNumber !== null) {
    payment_detail.value.cusIdNumber = payment_detail.value.cusIdNumber.replace(/[^\d]/g, "");
  }

  let data = payment_detail.value;
  data.uid = item.paymentUid
  data.toAccNameTh = item.toAccNameTH
  data.toAccNameEn = item.toAccNameEN
  data.bankRefId = item.bankRefId
  data.version = paymentE.value.version
  const payload = data;
  payload.effectiveDate = moment(payment_detail.value.effectiveDateE).format("YYYY-MM-DD")
  // console.log('ToAccountName p : ' + JSON.stringify(payload));
  const response = await storepm.paymentupdateFetch(payload);
  // console.log('ToAccountName r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value.version = response.data.data.version
        addNewPay.value = false;
        swal("บันทึกสำเร็จ", "", "success");
        getPayAbletList()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        if (response.data.rtnDesc === 'record not found') {
          getPayAbletList()
        } else if (response.data.rtnDesc === 'object already modified') {
          backToSecondTab()
        }
      }
    }
  }
}


async function retriveToAccountName(item) {
  if (pay_e.value.sourcemoney !== '0') {
    let ld = filterBank(pay_e.value.sourcemoney)
    if (ld.length > 0) {
      pay_e.value.shopBankUid = pay_e.value.sourcemoney
    } else {
      ld = filterCash(pay_e.value.sourcemoney)
      if (ld.length > 0) {
        pay_e.value.cashAcctUid = pay_e.value.sourcemoney
      }
    }
  }
  pay_e.value.pay = parseFloat(pay_e.value.pay)
  if (pay_e.value.cusPhoneNumber !== null) {
    pay_e.value.cusPhoneNumber = pay_e.value.cusPhoneNumber.replace(/[^\d]/g, "");
  }
  if (pay_e.value.cusIdNumber !== null) {
    pay_e.value.cusIdNumber = pay_e.value.cusIdNumber.replace(/[^\d]/g, "");
  }

  let data = pay_e.value;
  data.uid = item.paymentUid
  data.toAccNameTh = item.toAccNameTH
  data.toAccNameEn = item.toAccNameEN
  data.bankRefId = item.bankRefId
  data.version = paymentE.value.version
  const payload = data;
  payload.effectiveDate = moment(pay_e.value.effectiveDateE).format("YYYY-MM-DD")
  // console.log('ToAccountName p : ' + JSON.stringify(payload));
  const response = await storepm.paymentupdateFetch(payload);
  // console.log('retriveToAccountName r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value.version = response.data.data.version
      } else {
        // console.log('retriveToAccountName r : ' + JSON.stringify(response.data));
      }
    }
  }
}


async function onConfirmPayment() {
  swal({
    title: "ตรวจสอบรายการนี้",
    text: "คุณยืนยันการตรวจสอบรายการชำระนี้หรือไม่",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      updateRemarkPaymentGroup()
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการตรวจสอบ", "", "error");
    }
  });
}

async function onEditPayment() {
  swal({
    title: "แก้ไขรายการนี้",
    text: "ระบบจะย้อนกลับไปแก้ไขรายการใหม่อีกครั้ง",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ยืนยัน",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      swal("กลับไปแก้ไข", "", "success");
      datas.showQR = false;
      console.log(datas.showQR);
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการแก้ไขรายการ", "", "error");
      datas.showQR = true;
      console.log(datas.showQR);
    }
  });
}

async function onCancelPayment() {
  swal({
    title: "ยกเลิกรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.clos"),
  }).then((result) => {
    if (result.isConfirmed) {
      backToSecondTab()
    }
  });
}

async function onErrorPayment(msg, process) {
  swal({
    title: "บันทึกไม่สำเร็จ",
    text: msg,
    icon: "error",
    showDenyButton: true,
    showCancelButton: true,
    allowOutsideClick: false,
    showCloseButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("content.retry"),
    denyButtonText: t("content.passbk"),
    cancelButtonText: t("buttons.clos"),
    denyButtonColor: "#3085d6",
  }).then((result) => {
    if (result.isConfirmed) {
      // retry
      if (process === 'add') {
        loading.value = true
        addItems('')
      } else if (process === 'update') {
        loading.value = true
        retryUpdatePayment()
      }
    } else if (result.isDenied) {
      // pass
      if (process === 'add') {
        addItems('pass')
      } else if (process === 'update') {
        // console.log('payable_uid : ' + payment_detail.value.payable_uid)
        if (payment_detail.value.payable_uid !== '0') {
          getPayableInfo(payment_detail.value.payable_uid, 'pass')
        } else {
          payment_detail.value.toAccNameTh = ''
          payment_detail.value.toAccNameEn = ''
          payment_detail.value.bankRefId = ''
          updateItems()
        }

      }
    }
  });
}


async function onComplete() {
  // console.log('onComplete : ' + workItemUid.value + " version : " + version_edit.value + " pay : " + JSON.stringify(paymentDetail.value));
  let obj = {
    uid: workItemUid.value,
    action: 'complete',
    version: version_edit.value
  }
  if (paylist.value.length > 0) {
    let lp = paylist.value.filter((c) => c.txStatus === 'ERROR' || c.txStatus === 'FAILURE');
    if (lp.length > 0) {
      for (let i = 0; i < lp.length; i++) {
        await getPayableInfo(lp[i].uid, 'error')
        let lpm = paymentDetail.value.filter((c) => c.payable_uid === lp[i].uid);
        if (lpm.length > 0) {
          pay_e.value = lpm[0]
          pay_e.value.paymentUid = pay_e.value.uid
          pay_e.value.toAccNameTh = ''
          pay_e.value.toAccNameEn = ''
          pay_e.value.bankRefId = ''
          await retriveToAccountName(pay_e.value)
        }
      }
      await completeStep(obj)
    } else {
      await completeStep(obj)
    }
  } else {
    await completeStep(obj)
  }



}


async function backToSecondTab() {
  loading.value = false
  listpaymentE.value = []
  listpaymentD.value = {}
  version_edit.value = ''
  workItemUid.value = ''
  balprice.value = 0
  uid_edit.value = ''
  version_edit.value
  paymentDetail.value = []
  detailRemark.value = ''
  customerAflist.value = []
  listcusmobileAll.value = []
  customerTaxlist.value = []
  clearItem()
  await onClickTab('2')
}

async function getFileLoco() {
  let file_L = []
  const keyA = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = keyA.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.value.map((url) => axios.get(url));
  await axios.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {

        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            const data = URL.createObjectURL(file);
            const lf = filterFile(file_L[i].data.data.uid, lb.value)
            lf[0].thumb = data
            lf[0].file = file
            lf[0].data = data
          } else {
            // console.log('no dataFile ')
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}

function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  // const trimmedString = string64;
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  var ext = fileName.split('.').pop();
  let type = '';
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  }
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}


function filterFile(str, matchArr) {
  //  return matchArr.filter((c) => c.data.data.uid === str);
  return matchArr.filter((c) => c.logoUid === str);
}



const countdownTimer = (val) => {
  let timer = val;
  let interval = setInterval(() => {
    if (timer === 0) {
      clearInterval(interval);
      datas.paid = false;
      datas.showPaid = true;
      datas.showQR = false;
    } else {
      timer--;
      console.log(timer);
      datas.paid = true;
      datas.showPaid = false;
    }
  }, 1000);
};

async function deleteRowItemsCaf(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteCusAffiliate(item.uid);
      }
    }
  });
}

async function getCusAffiliateListApi(val) {
  let data = "";
  let list = []
  if (currentPageCuf.value !== 1) {
    if (listcusaffiliate.value.length === 1 && val === 'del') {
      currentPageCuf.value = currentPageCuf.value - 1
    }
  }
  data = {
    index: currentPageCuf.value - 1,
    size: perPageCuf.value,
    cusUid: cus_detail.value.uid,
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecusa.cusAffiliateListFetch(payload);
  // console.log("loadCusAffiliateListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        listcusaffiliate.value = list
        totalRowsCuf.value = response.data.data.ext.total
        totalRows_sCuf.value = formatNumber(totalRowsCuf.value)
        // customerAflist.value = list.filter((c) => c.affiliateCusUid === cusuid);
      }
    }
  }
};


async function updatePaginationCuf() {
  listcusaffiliate.value = []
  let data = "";
  data = {
    index: currentPageCuf.value - 1,
    size: perPageCuf.value,
    cusUid: cus_detail.value.uid,
  };

  const payload = data;
  const response = await storecusa.cusAffiliateListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcusaffiliate.value = response.data.data.list;
        totalRowsCuf.value = response.data.data.ext.total
        totalRows_sCuf.value = formatNumber(totalRowsCuf.value)
      }
    }
  }
}


async function getCustomerAfListApi() {
  customerAfList.value = [];
  currentPageCaf.value = 1
  let data = "";
  data = {
    index: currentPageCaf.value - 1,
    size: perPageCaf.value,
    nameEn: nameEnCaf.value,
    nameTh: nameThCaf.value,
    docNumber: docNumberCaf.value

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        customerAfList.value = listd;
        totalRowsCaf.value = response.data.data.ext.total
        totalRows_sCaf.value = formatNumber(totalRowsCaf.value)

        if (customerAfList.value.length > 0) {
          // visible.value = true
        } else {
          // swal("ไม่พบข้อมูล", "", "error");
        }
      }
    }
  }
}


async function updatePaginationCaf() {
  customerAfList.value = []
  let data = "";
  data = {
    index: currentPageCaf.value - 1,
    size: perPageCaf.value,
    nameEn: nameEnCaf.value,
    nameTh: nameThCaf.value,
    docNumber: docNumberCaf.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerAfList.value = response.data.data.list;
        totalRowsCaf.value = response.data.data.ext.total
        totalRows_sCaf.value = formatNumber(totalRowsCaf.value)
      }
    }
  }
}


async function openDialogCusAf() {
  nameThCaf.value = ''
  nameEnCaf.value = ''
  docNumberCaf.value = ''
  customerAfList.value = [];
  document.getElementById("searchCusAfModal").click();
}

function canselectCusAf(item) {
  let bool = true
  if (item.uid === cus_detail.value.uid) {
    bool = false
  }

  if (bool) {
    if (listcusaffiliate.value.length > 0) {
      let ld = listcusaffiliate.value.filter((c) => c.affiliateCusUid.toLowerCase().indexOf(item.uid.toLowerCase()) >= 0);
      if (ld.length > 0) {
        bool = false
      }
    }
  }

  return bool
}


async function selectCusAfl(item) {
  document.getElementById("closeAfModal").click();
  customerAfList.value = []
  await addCusAffiliate(item.uid)
}


async function addCusAffiliate(uid) {
  let data = "";
  data = {
    cusUid: cus_detail.value.uid,
    affiliateCusUid: uid,
  };
  const payload = data;
  // console.log('addCusAffiliate : ' + JSON.stringify(payload));
  const response = await storecusa.cusAffiliateAddFetch(payload);
  // console.log('addCusAffiliate : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await getCusAffiliateListApi('add')
        await loadCusAffiliateListApi()
        if (customerAflist.value.length > 0) {
          await findCusAffiliate()
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deleteCusAffiliate(uid) {
  let data = "";
  data = {
    uid: uid,
  };
  const payload = data;
  // console.log('deleteCusAffiliate : ' + JSON.stringify(payload));
  const response = await storecusa.cusAffiliateDeleteFetch(payload);
  // console.log('deleteCusAffiliate : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await getCusAffiliateListApi('del')
        await loadCusAffiliateListApi()
        if (customerAflist.value.length > 0) {
          await findCusAffiliate()
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function updateCustomerShop(action) {
  const payload = cus_detail.value.cusShop;
  if ((payload.email !== '' && payload.email !== null) && !checkformatEmail(payload.email)) {
    swal("กรุณาตรวจสอบอีเมล", "", "error");
  } else {
    if (payload.phoneNo1 !== null) {
      payload.phoneNo1 = payload.phoneNo1.replace(/[^\d]/g, "")
    }

    if (payload.phoneNo2 !== null) {
      payload.phoneNo2 = payload.phoneNo2.replace(/[^\d]/g, "")
    }

    if (payload.phoneNo3 !== null) {
      payload.phoneNo3 = payload.phoneNo3.replace(/[^\d]/g, "")
    }
    // console.log('updateCustomerShop : ' + JSON.stringify(payload));
    const response = await storecussh.cusShopUpdateFetch(payload)
    // console.log('updateCustomerShop : ' + JSON.stringify(response.data));
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          if (action === 'data') {
            swal("บันทึกสำเร็จ", "", "success");
            listcusmobileAll.value = []
            cus_detail.value.cusShop = response.data.data
            if (cus_detail.value !== null) {
              if (cus_detail.value.cusShop.phoneNo1 !== '' && cus_detail.value.cusShop.phoneNo1 !== null) {
                let obj = {
                  mobile: cus_detail.value.cusShop.phoneNo1
                }
                listcusmobileAll.value.push(obj)
              }
              if (cus_detail.value.cusShop.phoneNo2 !== '' && cus_detail.value.cusShop.phoneNo2 !== null) {

                let obj = {
                  mobile: cus_detail.value.cusShop.phoneNo2
                }
                listcusmobileAll.value.push(obj)

              }
              if (cus_detail.value.cusShop.phoneNo3 !== '' && cus_detail.value.cusShop.phoneNo3 !== null) {

                let obj = {
                  mobile: cus_detail.value.cusShop.phoneNo3
                }
                listcusmobileAll.value.push(obj)
              }
            }
          }

        } else {
          swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        }
      }
    }
  }
}

const toggleInfo = () => {
  if (!datas.hide) {
    datas.hide = true;
  } else {
    datas.hide = false;
  }
};


function checkformatEmail(email) {
  const reg = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const validRegex2 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email.match(validRegex) && email.toLowerCase().match(validRegex2)) {
    return true;
  } else {
    return false;
  }
}

function chkCusMobile() {
  let process = false
  let lca = []
  lca = paymentDetail.value.filter((c) => c.cusPhoneNumber === cus_detail.value.cusShop.phoneNo1 && c.type === 'PROMPT_PAY_MOBILE');
  if (lca.length > 0) {
    process = true
  } else {
    lca = paymentDetail.value.filter((c) => c.cusPhoneNumber === cus_detail.value.cusShop.phoneNo2 && c.type === 'PROMPT_PAY_MOBILE');
    if (lca.length > 0) {
      process = true
    } else {
      lca = paymentDetail.value.filter((c) => c.cusPhoneNumber === cus_detail.value.cusShop.phoneNo3 && c.type === 'PROMPT_PAY_MOBILE');
      if (lca.length > 0) {
        process = true
      }
    }
  }
  // console.log('chkCusMobile : ' + process);
  return process
}


function chkCusBankPay(item) {
  let process = false
  let lca = customerAflist.value.filter((c) => c.uid === item.uid);
  if (lca.length > 0) {
    if (lca[0].listbankcus !== undefined) {
      if (lca[0].listbankcus.length > 0) {
        for (let i = 0; i < lca[0].listbankcus.length; i++) {
          let lp = paymentDetail.value.filter((c) => c.cusBankUid === lca[0].listbankcus[i].uid && c.type === 'BANK_TRANSFER');
          if (lp.length > 0) {
            // console.log('lp bk : ' + JSON.stringify(lp));
            process = true
            break
          }
        }
      }
    }

  }
  return process
}


function chkCusPromtMobile(item) {
  let process = false
  let lca = customerAflist.value.filter((c) => c.uid === item.uid);
  if (lca.length > 0) {
    if (lca[0].listpromtmobile !== undefined) {
      if (lca[0].listpromtmobile.length > 0) {
        for (let i = 0; i < lca[0].listpromtmobile.length; i++) {
          let lp = paymentDetail.value.filter((c) => c.cusPhoneNumber === lca[0].listpromtmobile[i].mobile && c.type === 'PROMPT_PAY_MOBILE');
          if (lp.length > 0) {
            // console.log('lp mo : ' + JSON.stringify(lp));
            process = true
            break
          }
        }
      }
    }
  }
  return process
}


function chkCusPromtTax(item) {
  let process = false
  let lca = customerAflist.value.filter((c) => c.uid === item.uid);
  if (lca.length > 0) {
    if (lca[0].listpromttax !== undefined) {
      if (lca[0].listpromttax.length > 0) {
        for (let i = 0; i < lca[0].listpromttax.length; i++) {
          let lp = paymentDetail.value.filter((c) => c.cusIdNumber === lca[0].listpromttax[i].taxId && c.type === 'PROMPT_PAY_TAX_ID');
          if (lp.length > 0) {
            // console.log('lp promt : ' + JSON.stringify(lp));
            process = true
            break
          }
        }
      }
    }
  }
  return process
}


onMounted(() => {
  if (document.getElementById("choices-month")) {
    var month = document.getElementById("choices-month");
    setTimeout(function () {
      new Choices(month);
    }, 1);

    // eslint-disable-next-line no-unused-vars
    var d = new Date();
    var monthArray = new Array();
    monthArray[0] = "January";
    monthArray[1] = "February";
    monthArray[2] = "March";
    monthArray[3] = "April";
    monthArray[4] = "May";
    monthArray[5] = "June";
    monthArray[6] = "July";
    monthArray[7] = "August";
    monthArray[8] = "September";
    monthArray[9] = "October";
    monthArray[10] = "November";
    monthArray[11] = "December";
    for (let m = 0; m <= 11; m++) {
      var optn = document.createElement("OPTION");
      optn.text = monthArray[m];
      // server side month start from one\
      optn.value = m + 1;
      // if june selected
      if (m == 1) {
        optn.selected = true;
      }
      month.options.add(optn);
    }
  }

  if (document.getElementById("choices-day")) {
    var day = document.getElementById("choices-day");
    setTimeout(function () {
      new Choices(day);
    }, 1);

    for (let y = 1; y <= 31; y++) {
      var optn1 = document.createElement("OPTION");
      optn1.text = y;
      optn1.value = y;

      if (y == 1) {
        optn1.selected = true;
      }

      day.options.add(optn1);
    }
  }

  if (document.getElementById("choices-year")) {
    var year = document.getElementById("choices-year");
    setTimeout(function () {
      new Choices(year);
    }, 1);

    for (let y = 1900; y <= 2020; y++) {
      var optn2 = document.createElement("OPTION");
      optn2.text = y;
      optn2.value = y;

      if (y == 2020) {
        optn.selected = true;
      }

      year.options.add(optn2);
    }
  }
  if (document.getElementById("first-finance")) {
    document.getElementById("first-finance").classList.add('show', 'active')
  }

  if (document.getElementById("firstFinance")) {
    document.getElementById("firstFinance").classList.add('show', 'active')
  }
});
</script>

<style lang="scss" scoped>
div.both {
  display: flex;
}

.mybox {
  font-size: 1.2rem;
  background: transparent;
  border: none;
  outline-width: 0;
  opacity: 0;
}

.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
