<template>
  <navbar btn-background="bg-gradient-success" />
  <div class="page-header align-items-start min-vh-100 bg-sign-cover">
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mx-3 z-index-2">
              <div class="d-flex justify-content-center pt-3">
                <img :src="logo" class="sign-logo" />
              </div>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <material-input id="username" v-model="user" type="text" :label="t('heads.usr')" name="username" />
              </div>
              <div class="mb-3">
                <material-input id="password" v-model="pwd" type="password" :label="t('heads.pwd')" name="password" />
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <material-switch id="rememberMe" v-model="data.remem" name="Remember Me">
                  {{ $t("heads.rem") }}
                </material-switch>
                <a style="margin-top: -4px; text-decoration: none; color: #43a047" @click="forgetPassword">
                  {{ t("heads.fgpwd") }}
                </a>
              </div>
              <div class="text-center">
                <material-button class="my-4 mb-2" variant="gradient" color="success" size="lg" full-width
                  @click="login">
                  {{ $t("heads.log") }}
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 my-auto text-center">
            <div class="copyright text-center text-sm text-white">
              © {{ new Date().getFullYear() }} {{ $t('apps.name') }} {{ $t('apps.version') }} 1.31
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, onBeforeMount, onBeforeUnmount, ref, inject } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { useI18n } from "vue-i18n"
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min.js'

import Navbar from "@/examples/PageLayout/Navbar.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialSwitch from "@/components/MaterialSwitch.vue"
import MaterialButton from "@/components/MaterialButton.vue"

import logo from "@/assets/img/pawn/logo_new1.png"
import { useUserSecurityStore } from "@/stores/usersecurity";



const { t } = useI18n()
const swal = inject("$swal");

const store = useStore()
const userSStore = useUserSecurityStore();



const data = reactive({
  user: "",
  pwds: "",
  remem: false,
});

const user = ref("");
const pwd = ref("");

// const user = ref("nvuser01@nvtx.tech");
// const pwd = ref("P@ssw0rd");

// const user = ref("suchin.p");
// const pwd = ref("BKTP@ssw0rd");

// const user = ref("charin.s");
// const pwd = ref("NWP@ssw0rd");


const public_key = ref('');
const encryptedData = ref('');




const router = useRouter()

async function login() {
  if (user.value.trim() === '' || pwd.value.trim() === '') {
    swal("กรุณากรอก Username & Password", "", "error");
  } else {
    getpublicKey()
  }
}

function forgetPassword() {
  router.push({ name: "ForgetPassword" });
}

function getpublicKey() {
  userSStore.public().then((resdata) => {
    // console.log("public x : ", JSON.stringify(resdata));
    public_key.value = resdata.key
    getnonce(resdata)
  }).catch((err) => {
    let msg = ''
    if (err.response) {
      if (err.response.status === 408) {
        console.log("Authentication session is timeout. Please try again"); // Please change to alert or notify box.
        console.error("", err);
        msg = 'Authentication session is timeout. Please try again'
      } else if (err.response.status === 401) {
        console.log("Your credential is invalid. Please try again"); // Please change to alert or notify box.
        msg = 'Your credential is invalid. Please try again'
      } else {
        console.error("", err);
        console.log("Authentication Failed. Please try again" + JSON.stringify(err.response)); // Please change to alert or notify box.
        msg = 'Authentication Failed. Please try again'
      }

    } else {
      msg = 'Unexpected Error. Please reload and try again'
      console.log("Unexpected Error. Please reload and try again"); // Please change to alert or notify box.
    }
    swal("เข้าสู่ระบบไม่สำเร็จ", msg, "error");
  });
}

function getnonce(data) {
  userSStore.nonce(data.key).then((resdata) => {
    // console.log("nonce x : ", JSON.stringify(resdata));
    getlogin_url(resdata)
  }).catch((err) => {
    let msg = ''
    if (err.response) {
      if (err.response.status === 408) {
        console.log("Authentication session is timeout. Please try again"); // Please change to alert or notify box.
        console.error("", err);
        msg = 'Authentication session is timeout. Please try again'
      } else if (err.response.status === 401) {
        console.log("Your credential is invalid. Please try again"); // Please change to alert or notify box.
        msg = 'Your credential is invalid. Please try again'
      } else {
        console.error("", err);
        console.log("Authentication Failed. Please try again" + JSON.stringify(err.response)); // Please change to alert or notify box.
        msg = 'Authentication Failed. Please try again'
      }

    } else {
      msg = 'Unexpected Error. Please reload and try again'
      console.log("Unexpected Error. Please reload and try again"); // Please change to alert or notify box.
    }
    swal("เข้าสู่ระบบไม่สำเร็จ", msg, "error");
  });
}




async function encrypt(text) {
  encryptedData.value = ''
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(public_key.value);
  let encrypted = encrypt.encrypt(text);
  return encrypted.toString();
}

async function getlogin_url(data) {
  // const msg = "79563A065CF4C112D5EDE42AB8D06A07|:|564650609597220734|:|P@ssw0rd"
  const hmacDigest = data.nonce + '|:|' + data.aud + '|:|' + pwd.value.trim();
  // console.log("hmacDigest : " + hmacDigest);
  // await start(hmacDigest)
  // await test(hmacDigest)
  encryptedData.value = await encrypt(hmacDigest)
  if (encryptedData.value !== '') {
    let data1 = "";
    data1 = {
      encrypttext: encryptedData.value,
      user: user.value.trim(),
    }
    const payload = data1;
    // console.log("payloadl x : ", JSON.stringify(payload));
    userSStore.login_url(payload).then((logindata) => {
      // console.log("login_url x : ", JSON.stringify(logindata));
      localStorage.removeItem("userName");
      if (logindata.token) {
        // userSStore.authorize_new().then((authData) => {
        userSStore.authorize(true).then((authData) => {
          // console.log("authData x : ", JSON.stringify(authData));
          if (authData.accessToken) {
            // const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
            // console.log("exp 1 : ", key.tokenExpiry);
            localStorage.setItem("userName", user.value.trim());
            console.log("You are login success");
            router.push({ name: "Dashboard" });
          } else {
            console.log("You are not allow to access the site. Please contact admin."); // Please change to alert or notify box.
            swal("เข้าสู่ระบบไม่สำเร็จ", "You are not allow to access the site. Please contact admin", "error");
          }
          // appStore.unloading();
        });
      } else {
        console.log("Authentication Failed. Please try again.");
        swal("เข้าสู่ระบบไม่สำเร็จ", "Authentication Failed. Please try again", "error");
        // appStore.unloading();
      }
    }).catch((err) => {
      let msg = ''
      if (err.response) {
        if (err.response.status === 408) {
          console.log("Authentication session is timeout. Please try again"); // Please change to alert or notify box.
          console.error("", err);
          msg = 'Authentication session is timeout. Please try again'
        } else if (err.response.status === 401) {
          console.log("Your credential is invalid. Please try again"); // Please change to alert or notify box.
          msg = 'Your credential is invalid. Please try again'
        } else {
          console.error("", err);
          console.log("Authentication Failed. Please try again." + JSON.stringify(err.response)); // Please change to alert or notify box.
          msg = 'Authentication Failed. Please try again'
        }

      } else {
        msg = 'Unexpected Error. Please reload and try again'
        console.log("Unexpected Error. Please reload and try again."); // Please change to alert or notify box.
      }
      swal("เข้าสู่ระบบไม่สำเร็จ", msg, "error");
    });
  }
}

// function getrefresh() {
//   const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
//   // userSStore.refreshData(key.refreshToken)
//   userSStore.refresh(key.refreshToken).then((authData) => {
//     // console.log("refresh x : ", JSON.stringify(authData));
//     var d = new Date();
//     d.setSeconds(d.getSeconds() + authData.expires_in);
//     const timestamp = Date.parse(d)
//     console.log('timestamp : ' + timestamp) // => 1591606075000

//     var obj = {
//       accessToken: authData.access_token,
//       refreshToken: authData.refresh_token,
//       tokenExpiry: timestamp
//     }

//     localStorage.setItem("FR-SDK-nvtx-boapp", JSON.stringify(obj));

//     // console.log("obj x : ", JSON.stringify(obj));
//     // const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
//     // console.log("exp 2 : ", key.tokenExpiry);
//   });

// }

function mapMutations() {
  store.commit("toggleEveryDisplay");
  store.commit("toggleHideConfig");
}
mapMutations();

function setDisplayLayouts(bool) {
  store.state.showConfig = bool;
  store.state.showNavbar = bool;
  store.state.showSidenav = bool;
  store.state.showFooter = bool;

}


onBeforeMount(() => {
  // mapMutations()
  setDisplayLayouts(false);
  userSStore.clearSession();

  // console.log("onBeforeMount xxxxxx location.hash ", window.location.hash)

});

onBeforeUnmount(() => {
  // mapMutations()
  setDisplayLayouts(true);
});
</script>

<style lang="scss" scoped>
.bg-sign-cover {
  background-image: url('~@/assets/img/bg_signin.jpg');
}

.sign-logo {
  max-width: 140px;
  margin: 0 auto;
}
</style>
