<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <ul id="dfTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="tab3-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab3" type="button" role="tab"
              aria-controls="tab3" aria-selected="false" @click="onClickTab('3')">
              {{ $t("content.suminfo") }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button id="tab1-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab"
              aria-controls="tab1" aria-selected="true" @click="onClickTab('1')">
              {{ $t("content.amnstock") }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button id="tab2-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab"
              aria-controls="tab2" aria-selected="true" @click="onClickTab('2')">
              {{ $t("content.stockoverall") }}
            </button>
          </li>
        </ul>

        <div id="dfTabContent" class="tab-content">
          <div id="tab1" class="tab-pane fade" role="tabpanel" aria-labelledby="tab1-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5 class="m-0 me-2">{{ $t("buttons.sear") }}</h5>
              </div>
              <hr />
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col-12">
                    <h6>{{ $t("content.assetinfo") }}</h6>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <label class="form-label ms-0">
                        {{ $t("content.type") }} 1</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox1tp" type="search" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listtp1.length > 0"></button>
                        <ul class="dropdown-menu dropdown-menu-end data-list"
                          :style="`max-width: ${childWidth}px !important;`">
                          <li>
                            <div class="input-group">
                              <input v-model="tp1_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                autocomplete="off" @:keyup="selectTpList('1', tp1_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listtp1" :key="corea" :value="corea" @click="checktp1(a)">
                            <a v-if="a.uid !== tp1.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === tp1.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <label class="form-label ms-0">
                        {{ $t("content.type") }} 2</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox2tp" type="text" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listtp2.length > 0"></button>
                        <ul class="dropdown-menu dropdown-menu-end data-list"
                          :style="`max-width: ${childWidth}px !important;`">
                          <li>
                            <div class="input-group">
                              <input v-model="tp2_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                autocomplete="off" @:keyup="selectTpList('2', tp2_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listtp2" :key="corea" :value="corea" @click="checktp2(a)">
                            <a v-if="a.uid !== tp2.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === tp2.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <label class="form-label ms-0">
                        {{ $t("content.type") }} 3</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox3tp" type="text" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listtp3.length > 0"></button>
                        <ul class="dropdown-menu dropdown-menu-end data-list"
                          :style="`max-width: ${childWidth}px !important;`">
                          <li>
                            <div class="input-group">
                              <input v-model="tp3_inp" type="text" class="form-control auto-input-search"
                                placeholder="ค้นหา" autocomplete="off" @:keyup="selectTpList('3', tp3_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listtp3" :key="corea" :value="corea" @click="checktp3(a)">
                            <a v-if="a.uid !== tp3.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === tp3.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <label class="form-label ms-0">
                        {{ $t("content.type") }} 4</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox4tp" type="text" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listtp4.length > 0"></button>
                        <ul class="dropdown-menu dropdown-menu-end data-list"
                          :style="`max-width: ${childWidth}px !important;`">
                          <li>
                            <div class="input-group">
                              <input v-model="tp4_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                autocomplete="off" @:keyup="selectTpList('4', tp4_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listtp4" :key="corea" :value="corea" @click="checktp4(a)">
                            <a v-if="a.uid !== tp4.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === tp4.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>


                    <div class="col-lg-4">
                      <label class="form-label ms-0">
                        {{ $t("content.type") }} 5</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox5tp" type="text" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listtp5.length > 0"></button>
                        <ul class="dropdown-menu dropdown-menu-end data-list"
                          :style="`max-width: ${childWidth}px !important;`">
                          <li>
                            <div class="input-group">
                              <input v-model="tp5_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                autocomplete="off" @:keyup="selectTpList('5', tp5_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listtp5" :key="corea" :value="corea" @click="checktp5(a)">
                            <a v-if="a.uid !== tp5.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === tp5.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>


                    <div class="col-lg-4">
                      <label class="form-label ms-0">
                        {{ $t("content.type") }} 6</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox6tp" type="text" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listtp6.length > 0"></button>
                        <ul class="dropdown-menu dropdown-menu-end data-list"
                          :style="`max-width: ${childWidth}px !important;`">
                          <li>
                            <div class="input-group">
                              <input v-model="tp6_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                autocomplete="off" @:keyup="selectTpList('6', tp6_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listtp6" :key="corea" :value="corea" @click="checktp6(a)">
                            <a v-if="a.uid !== tp6.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === tp6.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <pre></pre>
                  <hr />
                  <div class="col-12">
                    <h6>{{ $t("content.amnlocation") }} &nbsp;&nbsp;&nbsp;
                      <div class="d-inline-block me-4">
                        <material-checkbox v-model="allloc" :checked="allloc" @change="onSelectAllLoc">
                          {{ $t("content.allloc") }}
                        </material-checkbox>
                      </div>
                    </h6>
                  </div>

                  <div class="row">
                    <div class="col-lg-2">
                      <label class="form-label ms-0">
                        {{ $t("content.amnlevelno") }} 1</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox1" type="text" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listloc1.length > 0 && allloc === false"></button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <div class="input-group">
                              <input v-model="loc1_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                autocomplete="off" @:keyup="selectLocList('1', loc1_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listloc1" :key="corea" :value="corea" @click="checklc1(a)">
                            <a v-if="a.uid !== loc1.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === loc1.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-lg-2">
                      <label class="form-label ms-0">
                        {{ $t("content.amnlevelno") }} 2</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox2" type="text" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listloc2.length > 0"></button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <div class="input-group">
                              <input v-model="loc2_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                autocomplete="off" @:keyup="selectLocList('2', loc2_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listloc2" :key="corea" :value="corea" @click="checklc2(a)">
                            <a v-if="a.uid !== loc2.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === loc2.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-lg-2">
                      <label class="form-label ms-0">
                        {{ $t("content.amnlevelno") }} 3</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox3" type="text" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listloc3.length > 0"></button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <div class="input-group">
                              <input v-model="loc3_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                autocomplete="off" @:keyup="selectLocList('3', loc3_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listloc3" :key="corea" :value="corea" @click="checklc3(a)">
                            <a v-if="a.uid !== loc3.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === loc3.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-lg-2">
                      <label class="form-label ms-0">
                        {{ $t("content.amnlevelno") }} 4</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox4" type="text" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listloc4.length > 0"></button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <div class="input-group">
                              <input v-model="loc4_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                autocomplete="off" @:keyup="selectLocList('4', loc4_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listloc4" :key="corea" :value="corea" @click="checklc4(a)">
                            <a v-if="a.uid !== loc4.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === loc4.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>


                    <div class="col-lg-2">
                      <label class="form-label ms-0">
                        {{ $t("content.amnlevelno") }} 5</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox5" type="text" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listloc5.length > 0"></button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <div class="input-group">
                              <input v-model="loc5_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                autocomplete="off" @:keyup="selectLocList('5', loc5_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listloc5" :key="corea" :value="corea" @click="checklc5(a)">
                            <a v-if="a.uid !== loc5.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === loc5.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>


                    <div class="col-lg-2">
                      <label class="form-label ms-0">
                        {{ $t("content.amnlevelno") }} 6</label>
                      <div class="input-group">
                        <input v-model="datas.coreBox6" type="text" class="form-control" disabled />
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false" v-if="listloc6.length > 0"></button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <div class="input-group">
                              <input v-model="loc6_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                autocomplete="off" @:keyup="selectLocList('6', loc6_inp)" />
                            </div>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li v-for="(a, corea) in listloc6" :key="corea" :value="corea" @click="checklc6(a)">
                            <a v-if="a.uid !== loc6.uid" class="dropdown-item">
                              {{ a.name }}</a>
                            <a v-if="a.uid === loc6.uid" class="dropdown-item active bg-primary text-white">
                              {{ a.name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <pre></pre>
                  <hr />

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.amnpawnval") }}</label>
                    <div class="input-group">
                      <select class="form-select group-select" style="flex-grow: 1" v-model="pawnop_search">
                        <option value="" disabled selected>{{ $t(`content.select`) }}</option>
                        <option v-for="(j, se31) in operator" :key="se31" :value="j.val">
                          {{ j.lb }}
                        </option>
                      </select>
                      <input type="text" class="form-control" style="flex-grow: 1" v-model="pawn_search"
                        @input="onlyDigits_pawn" @change="inputpawn();" id="input1" @focus="$event.target.select()" />
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.amnperiodtime") }}</label>
                    <div class="input-group">
                      <select class="form-select group-select" style="flex-grow: 2" v-model="durationop_search">
                        <option value="" disabled selected>{{ $t(`content.select`) }}</option>
                        <option v-for="(j, se31) in operator" :key="se31" :value="j.val">
                          {{ j.lb }}
                        </option>
                      </select>
                      <input type="text" class="form-control" style="flex-grow: 1" v-model="duration_search"
                        @input="onlyDigits_day" @change="inputday();" id="input2" @focus="$event.target.select()" />
                      <select class="form-select group-select" style="flex-grow: 1" disabled>
                        <option value="0" disabled selected>{{ $t('content.day') }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusName_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.ticketno") }}</label>
                    <material-input type="text" v-model="tk_search" />
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.amnassetstatus") }}</label>
                    <select class="form-select" v-model="status_search">
                      <option value="" selected>{{ $t("content.optall") }}</option>
                      <option v-for="(j, se22) in assetStatus" :key="se22" :value="j.val">
                        {{ j.lb }}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-6 d-flex justify-content-end">
                    <div class="me-3">
                      <label class="form-label ms-0 png"></label>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3" :disabled="catc_search === '' && loc_search === ''
                        && ((pawnop_search === '' && pawn_search !== 0) || (pawnop_search === '' && pawn_search === 0))
                        && ((durationop_search === '' && duration_search !== 0) || (durationop_search === '' && duration_search === 0))
                        && cusUid_search === '' && tk_search.trim() === '' && allloc === false" @click="loaddataApi">
                        <i class="material-icons material-symbols-outlined">search</i>
                        {{ $t("buttons.sear") }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <label class="form-label ms-0 png"></label>
                      <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                        @click="cancelBtn">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ $t("buttons.canc") }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <hr />
                <pre></pre>
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-space-between align-items-center">
                    <div class="col">
                      <h5 class="m-0 me-2">{{ $t("content.detail") }}</h5>
                    </div>
                    <div class="d-flex justify-content-end align-items-center">
                      <label class="form-label m-0 me-2">{{ $t("content.expfile") }} :</label>
                      <material-button v-for="(i, exp) in exportFile" :key="exp" :color="i.cl" variant="outline"
                        class="float-lg-start float-sm-end mb-0 me-2" :class="i.cs">
                        <i class="material-icons material-symbols-outlined">{{ i.ic }}</i>
                        {{ i.nm }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <pre></pre>

                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.names") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.assetinfo") }}</th>
                          <th>{{ $t("content.amnpawndate") }}</th>
                          <th>
                            {{
                              $t("content.amndaytotalpawn") + " (" + $t("content.day") + ")"
                            }}
                          </th>
                          <th>{{ $t("content.amnqtyitem") }}</th>
                          <th>{{ $t("content.stweight") }}</th>
                          <th>{{ $t("content.amntotalpawnval") }}</th>
                          <th>{{ $t("content.amnlocation") }}</th>
                          <th>{{ $t("content.amnassetstatus") }}</th>
                          <th>{{ $t("content.detail") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in datalist" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.cusName }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.tkRefNumber }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.category }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ format_datetime(row.pawnDate) }}
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            {{ formatNumberString(row.durationDays) }}
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            {{ formatNumberString(row.quantity) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.weight) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.newPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.locName }}
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            {{ filterTkstatus(row.tkStatus) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              :title="t('buttons.seed')" @click="openDetail(row)">
                              <i class="material-icons material-symbols-outlined">visibility</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lookupModal" id="detailModal">
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                      <tr v-if="datalist.length === 0">
                        <td colspan="11" class="text-sm font-weight-normal">
                          <p style="text-align: center">No Record Found</p>
                        </td>
                      </tr>
                      <tfoot>
                        <tr class="group-sub-header">
                          <td class="text-lg font-weight-bold text-end text-center" colspan="2">
                            <span v-if="datalist.length > 0">
                              <b>{{ $t("content.gtotal") }}</b>
                            </span>
                          </td>
                          <td class="text-lg font-weight-bold text-center">
                            <span v-if="datalist.length > 0">
                              <b>{{ formatNumberString(totalTicket) }}</b>
                            </span>
                          </td>
                          <td colspan="3"></td>
                          <td class="text-lg font-weight-bold text-center">
                            <span v-if="datalist.length > 0">
                              <b>{{ formatNumberString(totalQuantity) }}</b>
                            </span>
                          </td>
                          <td class="text-lg font-weight-bold">
                            <span v-if="datalist.length > 0">
                              <b>{{ formatNumberDecimalString(totalWeight) }}</b>
                            </span>
                          </td>
                          <td class="text-lg font-weight-bold">
                            <span v-if="datalist.length > 0">
                              <b>{{ formatNumberDecimalString(totalPawnValue) }}</b>
                            </span>
                          </td>
                          <td class="text-lg font-weight-bold" colspan="2"></td>
                          <td style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="datalist.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                          @click="onClickHandler" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="tab3" class="tab-pane fade" role="tabpanel" aria-labelledby="tab3-tab">
            <div class="card mt-3">
              <div class="card-header mb-0">
                <h5 class="m-0">{{ $t("content.amnlresult") }}</h5>
              </div>
              <div class="card-body pt-2">
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.assetinfo") }}</th>
                          <th>{{ $t("content.amnqtyitem") }}</th>
                          <th>{{ $t("content.amntotalpawnval") }}</th>
                          <th>{{ $t("content.amntotalpawnmax") }}</th>
                          <th>{{ $t("content.amntotalpawnmin") }}</th>
                          <th>{{ $t("content.amntotalpawnavg") }}</th>
                          <th>
                            {{
                              $t("content.amndaytotalpawnmax") +
                              " (" +
                              $t("content.day") +
                              ")"
                            }}
                          </th>
                          <th>
                            {{
                              $t("content.amndaytotalpawnmin") +
                              " (" +
                              $t("content.day") +
                              ")"
                            }}
                          </th>
                          <th>
                            {{
                              $t("content.amndaytotalpawnavg") +
                              " (" +
                              $t("content.day") +
                              ")"
                            }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in summarylist" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString(index + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.mainCatName }}
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            {{ formatNumberString(row.sumQuantity) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.sumPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.maxPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.minPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.avgPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            {{ formatNumberString(row.maxDuration) }}
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            {{ formatNumberString(row.minDuration) }}
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            {{ formatNumberDecimalString(row.avgDuration) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div id="tab2" class="tab-pane fade" role="tabpanel" aria-labelledby="tab2-tab">
            <div class="card mt-3">
              <div class="card-header mb-0">
                <h5 class="m-0">{{ $t("content.summarystock") }}</h5>
              </div>
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("buttons.sear") }}</label>
                    <select class="form-select" v-model="search_time" @change="changesearch_time">
                      <option value="" disabled selected>{{ $t("content.select") }}</option>
                      <option value="daily">{{ $t("content.byday") }}</option>
                      <option value="monthly">{{ $t("content.bymonth") }}</option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.date") }}</label>
                    <VueDatePicker v-model="search_date" :format="format" auto-apply :teleport="true"
                      :max-date="maxDateR" :enable-time-picker="false" month-name-format="long" :clearable="false" fluid
                      :disabled="search_time !== 'daily'" :text-input="textInputOptions" />
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.year") }}</label>
                    <select v-model="search_year" class="form-select" @change="changesearch_year"
                      :disabled="search_time !== 'monthly'">
                      <option value="" disabled selected>{{ $t('content.select') }}</option>
                      <option v-for="(a, sel2) in listyear" :key="sel2" :value="a">
                        {{ a }}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t("content.month") }}</label>
                    <select v-model="search_month" class="form-select"
                      :disabled="search_time !== 'monthly' || search_year === ''">
                      <option value="" disabled selected>{{ $t('content.select') }}</option>
                      <option v-for="(a, sel3) in optMonths" :key="sel3" :value="a.value"
                        :disabled="monthDiff(a.value) < 1">
                        {{ a.items }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-3 d-flex justify-content-between align-items-center"></div>
                  <div class="col-9 d-flex justify-content-end align-items-center">
                    <div class="me-3">
                      <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                        :disabled="search_time === '' || (search_time === 'daily' && search_date === null)
                          || (search_time === 'monthly' && (search_year === '' || search_month === ''))"
                        @click="loadsummaryDateApi">
                        <i class="material-icons material-symbols-outlined">search</i>
                        {{ $t("buttons.sear") }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                        @click="cancelSummarydateBtn">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ $t("buttons.canc") }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row mt-4">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.assetinfo") }}</th>
                          <th>{{ $t("content.amnqtyitem") }}</th>
                          <th>{{ $t("content.amntotalpawnval") }}</th>
                          <th>{{ $t("content.amntotalpawnmax") }}</th>
                          <th>{{ $t("content.amntotalpawnmin") }}</th>
                          <th>{{ $t("content.amntotalpawnavg") }}</th>
                          <th>
                            {{
                              $t("content.amndaytotalpawnmax") +
                              " (" +
                              $t("content.day") +
                              ")"
                            }}
                          </th>
                          <th>
                            {{
                              $t("content.amndaytotalpawnmin") +
                              " (" +
                              $t("content.day") +
                              ")"
                            }}
                          </th>
                          <th>
                            {{
                              $t("content.amndaytotalpawnavg") +
                              " (" +
                              $t("content.day") +
                              ")"
                            }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in stocklist" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString(index + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.mainCatName }}
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            {{ formatNumberString(row.sumQuantity) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.sumPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.maxPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.minPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.avgPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            {{ formatNumberString(row.maxDuration) }}
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            {{ formatNumberString(row.minDuration) }}
                          </td>
                          <td class="text-sm font-weight-normal text-center">
                            {{ formatNumberDecimalString(row.avgDuration) }}
                          </td>
                        </tr>
                        <tr v-if="stocklist.length === 0">
                          <td colspan="11" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <!-- <th style="width: 100px">{{ $t("content.ccode") }}</th> -->
                          <th>#</th>
                          <th>{{ $t("content.cnameth") }}</th>
                          <th>{{ $t("content.cnameen") }}</th>
                          <th>{{ $t("content.docno") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td></td>
                          <td>
                            <material-input v-model="nameThC" type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input v-model="nameEnC" type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input v-model="docNumberC" type="text" :label="t('content.docno')" />
                          </td>
                          <td>
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              :disabled="nameThC.length < 2 && nameEnC.length < 2 && docNumberC.length < 5"
                              @click="loadCustomerAfListApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-for="(row, index) in customerlist" :key="index" @click="selectCusAf(row)">
                          <td>{{ formatNumberString((currentPageC - 1) * perPageC + index + 1) }}</td>
                          <td>{{ row.nameTh }}</td>
                          <td>{{ row.nameEn }}</td>
                          <td colspan="2">{{ row.docNumber }}</td>
                        </tr>
                        <tr v-if="customerlist.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="customerlist.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                          :items-per-page="perPageC" @click="onClickHandlerC" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Detail Modal -->
        <div id="lookupModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width:1240px;">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">

                <div class="row">
                  <div class="col-lg-6 mt-2">
                    <div id="lookupImages" class="carousel slide" data-bs-ride="carousel" style="max-width: 520px">
                      <div class="carousel-indicators" id="demo1">
                      </div>
                      <div class="carousel-inner">
                        <ul className="list-unstyled">
                          <li v-for="(item, index) in images.length" :key="index">
                            <span v-if="index === 0">
                              <div class="carousel-item active">
                                <vue-image-zoomer :regular="images[index].src" img-class="img-fluid"
                                  class="d-block w-100" :zoom-amount="3" :click-zoom="true" close-pos="top-right"
                                  message-pos="top" />
                              </div>
                            </span>
                            <span v-else>
                              <div class="carousel-item">
                                <vue-image-zoomer :regular="images[index].src" img-class="img-fluid"
                                  class="d-block w-100" :zoom-amount="3" :click-zoom="true" close-pos="top-right"
                                  message-pos="top" />
                              </div>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#lookupImages"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#lookupImages"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-6 mt-2">
                    <div v-if="workcaseDetail !== null" style="overflow-x:auto;">
                      <div>
                        <table class="table table-sm table-borderless">
                          <tbody>
                            <tr>

                              <td class="fs-14 text-bold">{{ $t("content.ticketno") }} :
                                <span v-if="workcaseDetail.tkRefNumber !== ''">
                                  {{ workcaseDetail.tkRefNumber }} &nbsp;
                                  <span @click='copyBtn()' style="cursor: pointer">
                                    <i class="inline-icon material-icons">content_copy</i>
                                  </span>
                                </span>
                                <span v-else>-</span>
                              </td>
                              <td class="fs-14 text-bold">
                              </td>

                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.trans") }} :
                                {{ checkTransaction(workcaseDetail.transactionType) }}
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.statusticket") }} :
                                <span v-if="workcaseDetail.status === 'FINISH'" style="color:green;">
                                  {{ tk_status }}
                                </span>
                                <span
                                  v-else-if="workcaseDetail.status === 'FREEZE' || workcaseDetail.status === 'TERMINATE'"
                                  style="color:red;">
                                  {{ tk_status }}
                                </span>
                                <span v-else>
                                  {{ tk_status }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount === 0">
                              <td class="fs-14 text-bold"> {{ $t("content.lasttrf") }} : {{
                                format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.amnduedate") }} :
                                <span style="color:red;">
                                  {{ format_date(workcaseDetail.dueDate) }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.lasttrf") }} : {{
                                format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2">
                                {{ $t("content.amnduedate") }} :
                                <span style="color:red;">
                                  {{ format_date(workcaseDetail.dueDate) }} (ขอเลื่อนเป็นวันที่ {{
                                    format_date(workcaseDetail.postponeDate) }})
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2">
                                {{ $t("content.deferpast") }} :
                                <span @click='NewTab()' style="text-decoration: underline;color:red; cursor: pointer">
                                  {{ workcaseDetail.postponeCount }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">
                                {{ $t("content.monthlyprof") }} : {{
                                  formatNumberDecimalString(workcaseDetail.interestPerMonth) }}
                              </td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.currentprof") }} :
                                <span style="color:red;">
                                  {{ formatNumberDecimalString(int_cal) }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">
                                {{ $t("content.prointerest") }} :
                                <span
                                  v-if="workcaseDetail.interestPromotion === null || workcaseDetail.interestPromotion === 0">
                                  -
                                </span>
                                <span style="color:blue;" v-else>
                                  {{ formatNumberDecimalString(workcaseDetail.interestPromotion) }}
                                </span>
                              </td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.periodpromotion") }} :
                                <span v-if="workcaseDetail.promotionDuration === 0">
                                  -
                                </span>
                                <span style="color:blue;" v-else>
                                  {{ workcaseDetail.promotionDuration }} เดือน
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.periodpawn") }} :
                                <span style="color:blue;">
                                  {{ int_period }} เดือน
                                </span>
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.amnbookno") }}
                                <span style="color:blue;">
                                  {{ workcaseDetail.tkVolNo }}
                                </span>
                                &nbsp;{{ $t("content.amnno") }}
                                <span style="color:blue;">
                                  {{ workcaseDetail.tkNo }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.amnqtypiece") }} :
                                {{ formatNumberString(workcaseDetail.ticket.totalQuantity) }}
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.allweight") }} :
                                {{ formatNumberDecimalString(workcaseDetail.ticket.totalWeight) }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.pawnvalpv") }} :
                                <span v-if="workcaseDetail.transactionType === 'PAWN'">
                                  {{ formatNumberDecimalString(0) }}
                                </span>
                                <span v-else>
                                  {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                </span>
                                <!-- <span v-if="workcaseDetail.transactionType === 'REDEEM'">
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span>
                                  <span v-else-if="workcaseDetail.transactionType === 'PAWN'">
                                    {{ formatNumberDecimalString(0) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  </span> -->
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.actpawn3") }}/{{ $t("content.actpawn4") }} :
                                {{ formatNumberDecimalString(workcaseDetail.diffValue) }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.amnanalysval") }} :
                                {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                <!-- <span v-if="workcaseDetail.transactionType !== 'REDEEM'">
                                    {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span> -->
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.amnmaxvalall") }} :
                                {{ formatNumberDecimalString(workcaseDetail.ticket.totalFullValue) }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.statusticketlost") }} :
                                <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> ไม่มี
                                </span>
                                <span v-else style="color:red;">
                                  มี ({{ workcaseDetail.lostCount }})
                                </span>
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.refnolost") }} :
                                <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> -
                                </span>
                                <span v-else style="color:red;">
                                  {{ workcaseDetail.lostRefNumber }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.represent || workcaseDetail.shopRepresent">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.represent") }} :
                                <span style="color:red;">
                                  {{ workcaseDetail.workItems[0].customerName }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.changeName">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.changeName") }} :
                                <span style="color:red;">
                                  {{ workcaseDetail.workItems[0].tkCustomerName }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.amnlocationasset") }} :
                                <span v-if="location !== ''">
                                  {{ location.fullName }}
                                </span>
                                <span v-else>
                                  -
                                </span>

                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.stockno") }} :
                                <span v-if="workcaseDetail.ticket.stockNumber !== null">
                                  {{ workcaseDetail.ticket.stockNumber }}
                                </span>
                                <span v-else>
                                  -
                                </span>

                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.migraref") }} :
                                <span v-if="workcaseDetail.migrateRefNumber !== null">
                                  {{ workcaseDetail.migrateRefNumber }}
                                </span>
                                <span v-else>
                                  -
                                </span>

                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <span v-if="listinfo.length !== 0">
                          <table class="table table-sm table-borderless">
                            <tbody>
                              <tr>
                                <td class="fs-14 text-bold">
                                  <span style="text-decoration:underline;">
                                    {{ $t("content.amnassetdetail") }}
                                  </span>
                                </td>
                              </tr>

                              <tr v-for="(row, index) in listinfo" :key="index">
                                <td class="fs-14 text-bold">
                                  {{ formatNumberString(index + 1) }}. {{ row.remark }}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                        </span>
                        <span v-else>
                          <tr>
                            <td class="fs-14 text-bold">
                              <span style="text-decoration:underline;">
                                {{ $t("content.amnassetdetail") }}
                              </span>
                              : -
                            </td>

                          </tr>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr v-if="paymentE !== ''" />
                <div class="col-12 mt-2" v-if="paymentE !== ''">
                  <div class="fs-14 text-bold">
                    <span style="text-decoration:underline;">
                      {{ $t("content.amnfinanceinfo") }}
                    </span>
                  </div>
                  <pre></pre>
                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-flush">
                        <thead class="thead-light">
                          <tr>
                            <th>#</th>
                            <th>{{ $t("content.ticketno") }}</th>
                            <th>{{ $t("content.amnbiz") }}</th>
                            <th colspan="5" class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amnincome") }}
                              </span>
                            </th>
                            <th colspan="4" class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amnoutcome") }}
                              </span>
                            </th>
                            <th colspan="4" class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.fee") }}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th colspan="3"></th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amncash") }}
                              </span>
                            </th>
                            <th colspan="2" class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amnbanktransfer") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amncreditcard") }}
                              </span>

                            </th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amncheque") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amncash") }}
                              </span>
                            </th>
                            <th colspan="3" class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amnbanktransfer") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amncash") }}
                              </span>
                            </th>
                            <th colspan="2" class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amnbanktransfer") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amncreditcard") }}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th colspan="3"></th>
                            <th style="background-color:#dfffdd;"></th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amnbankacc") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                QR Code
                              </span>
                            </th>
                            <th colspan="2" style="background-color:#dfffdd;"></th>
                            <th style="background-color:#fecac7;"></th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amnbankacc") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amntransfer_promt_mobile") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amntransfer_promt_taxid") }}
                              </span>
                            </th>
                            <th style="background-color:#CFEBFD;"></th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amnbankacc") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                QR Code
                              </span>
                            </th>
                            <th style="background-color:#CFEBFD;"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, index) in paymentE.workCases" :key="index">
                            <template v-if="row.ticket !== null">
                              <td class="text-sm font-weight-normal text-center">{{ formatNumberString(index + 1) }}
                              </td>
                              <td class="text-sm font-weight-normal text-center">{{ row.tkRefNumber }}</td>
                              <td class="text-sm font-weight-normal text-center">{{
                                checkTransaction(row.transactionType) }}</td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[0].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[0].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[1].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[1].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[6].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[6].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[2].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[2].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[3].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[3].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[0].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[0].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[1].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[1].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[4].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[4].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[5].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[5].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[0].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[0].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[1].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[1].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[6].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[6].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[2].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[2].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import ProgressSpinner from 'primevue/progressspinner';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, reactive, inject, onMounted, onUpdated, computed } from "vue";
import { useI18n } from "vue-i18n";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ExampleFive from "@/examples/ExampleOne";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


import axios from "axios";
import * as st from "@/config/setting.js";
import moment from 'moment'
import noImg from "@/assets/img/noimg.png"

import { useCustomerStore } from '@/stores/customer'
import { useStockStore } from '@/stores/stock'
import { useLocationStore } from '@/stores/location'
import { useCashAccountStore } from '@/stores/cashaccount'
import { useCategoryStore } from '@/stores/category'
import { usePaymentGroupStore } from '@/stores/paymentgroup'
import { useInterestStore } from '@/stores/interest'
import { useWorkCaseStore } from '@/stores/workcase'


const { t } = useI18n();
const swal = inject("$swal");

const storecus = useCustomerStore()
const storest = useStockStore()
const storeloc = useLocationStore()
const storec = useCashAccountStore()
const storecat = useCategoryStore()
const storep = usePaymentGroupStore()
const storewc = useWorkCaseStore()
const storei = useInterestStore()


const datas = reactive({
  coreBox1: "",
  coreBox2: "",
  coreBox3: "",
  coreBox4: "",
  coreBox5: "",
  coreBox6: "",
  coreBox1tp: "",
  coreBox2tp: "",
  coreBox3tp: "",
  coreBox4tp: "",
  coreBox5tp: "",
  coreBox6tp: "",
});

const exportFile = reactive([
  {
    cl: "info",
    cs: "me-2",
    ic: "picture_as_pdf",
    nm: "pdf",
    ac: "",
  },
  {
    cl: "info",
    cs: "",
    ic: "table_rows",
    nm: "excel",
    ac: "",
  },
]);


const assetStatus = ref([
  { val: 'ACTIVE', lb: 'ปกติ' },
  { val: 'FREEZE', lb: 'อายัด' },]
);

const operator = ref([
  { val: 'EQUAL', lb: 'เท่ากับ' },
  { val: 'MORE_THAN', lb: 'มากกว่า' },
  { val: 'LESS_THAN', lb: 'น้อยกว่า' },]
);


const catc_search = ref('');
const loc_search = ref('');
const tk_search = ref('');
const pawn_search = ref(0);
const pawnop_search = ref('');
const duration_search = ref(0);
const durationop_search = ref('');
const status_search = ref('');
const cusUid_search = ref('');
const cusName_search = ref('');

const customerlist = ref([])
const summarylist = ref([])
const datalist = ref([])

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();
const totalQuantity = ref(0);
const totalPawnValue = ref(0);
const totalWeight = ref(0);
const totalTicket = ref(0);

const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRows_sC = ref();

const nameThC = ref('')
const nameEnC = ref('')
const docNumberC = ref('')

const shop_detail = ref(null)
const parentWidth = ref(0);
const childWidth = ref(500);
const parentRef = ref(null);

const tplist = ref([])
const listtp1 = ref([])
const listtp2 = ref([])
const listtp3 = ref([])
const listtp4 = ref([])
const listtp5 = ref([])
const listtp6 = ref([])
const listtp1o = ref([])
const listtp2o = ref([])
const listtp3o = ref([])
const listtp4o = ref([])
const listtp5o = ref([])
const listtp6o = ref([])
const tp1_inp = ref('')
const tp2_inp = ref('')
const tp3_inp = ref('')
const tp4_inp = ref('')
const tp5_inp = ref('')
const tp6_inp = ref('')
const tp1 = ref('')
const tp2 = ref('')
const tp3 = ref('')
const tp4 = ref('')
const tp5 = ref('')
const tp6 = ref('')

const loclist = ref([])
const listloc1 = ref([])
const listloc2 = ref([])
const listloc3 = ref([])
const listloc4 = ref([])
const listloc5 = ref([])
const listloc6 = ref([])
const loc1_inp = ref('')
const loc2_inp = ref('')
const loc3_inp = ref('')
const loc4_inp = ref('')
const loc5_inp = ref('')
const loc6_inp = ref('')
const loc1 = ref('')
const loc2 = ref('')
const loc3 = ref('')
const loc4 = ref('')
const loc5 = ref('')
const loc6 = ref('')


const info_cat = ref('');
const workcaseDetail = ref(null);
const tk_status = ref('');
const int_cal = ref(0);
const location = ref('');
const paymentE = ref('');
const images = ref([])
const images_l = ref([])
const payment_l = ref([])
const loading = ref(false);
const listinfo = ref([]);
const allloc = ref(false);
const int_period = ref('');


const stocklist = ref([])
const search_time = ref('')
const search_month = ref('')
const search_year = ref('')
const search_date = ref(new Date(new Date().setDate(new Date().getDate() - 1)))

const optMonths = ref([
  { items: computed(() => t("opts.mon1")), value: '01' },
  { items: computed(() => t("opts.mon2")), value: '02' },
  { items: computed(() => t("opts.mon3")), value: '03' },
  { items: computed(() => t("opts.mon4")), value: '04' },
  { items: computed(() => t("opts.mon5")), value: '05' },
  { items: computed(() => t("opts.mon6")), value: '06' },
  { items: computed(() => t("opts.mon7")), value: '07' },
  { items: computed(() => t("opts.mon8")), value: '08' },
  { items: computed(() => t("opts.mon9")), value: '09' },
  { items: computed(() => t("opts.mon10")), value: '10' },
  { items: computed(() => t("opts.mon11")), value: '11' },
  { items: computed(() => t("opts.mon12")), value: '12' },
])

const listyear = computed(() =>
  Array.from({ length: 11 }, (_, i) => formatyear(new Date(new Date().setFullYear(new Date().getFullYear() - i))))
);

const maxDateR = new Date((new Date()).valueOf() - 1000 * 3600 * 24);

const onClickHandler = () => {
  updatePagination()
};

const onClickHandlerC = () => {
  updatePaginationC()
};

const formatyear = (date) => {
  return moment(String(date)).local().format('YYYY');
}

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};



const shopInfoApi = async (val) => {
  shop_detail.value = null
  const response = await storec.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        shop_detail.value = response.data.data
        await loadLocationList()
        for (let i = 0; i < 100; i++) {
          if (tplist.value.length === 100 || i === 0) {
            await loadAssetList('null', 1, i)
          } else {
            break
          }
        }
      }
    }
  }
}

shopInfoApi()


const loadLocationList = async () => {
  loclist.value = []
  let data = "";
  data = {
    shopUid: shop_detail.value.uid,
    lastUpdate: '0',
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storeloc.locationListAllFetch(payload);
  // console.log("response : " + JSON.stringify(response.data.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        loclist.value = response.data.data.list
        // console.log("loclist : " + JSON.stringify(loclist.value));
        clearloc()
        listloc1.value = filterloclv(1)
      }
    }
  };
}

const loadAssetList = async (uid, lv, index) => {
  tplist.value = []
  let data = "";
  data = {
    index: index,
    size: 100,
    uid: uid,
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecat.categoryListFetch(payload);
  // console.log("response : " + JSON.stringify(response.data.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        tplist.value = response.data.data.list
        if (lv === 1 && tplist.value.length > 0) {
          const newArr = [...listtp1o.value, ...tplist.value];
          listtp1o.value = newArr
          listtp1.value = newArr
        } else if (lv === 2 && tplist.value.length > 0) {
          const newArr = [...listtp2o.value, ...tplist.value];
          listtp2o.value = newArr
          listtp2.value = newArr
        } else if (lv === 3 && tplist.value.length > 0) {
          const newArr = [...listtp3o.value, ...tplist.value];
          listtp3o.value = newArr
          listtp3.value = newArr
        } else if (lv === 4 && tplist.value.length > 0) {
          const newArr = [...listtp4o.value, ...tplist.value];
          listtp4o.value = newArr
          listtp4.value = newArr
        } else if (lv === 5 && tplist.value.length > 0) {
          const newArr = [...listtp5o.value, ...tplist.value];
          listtp5o.value = newArr
          listtp5.value = newArr
        } else if (lv === 6 && tplist.value.length > 0) {
          const newArr = [...listtp6o.value, ...tplist.value];
          listtp6o.value = newArr
          listtp6.value = newArr
        }
        // listloc1.value = filterloclv(1)
      }
    }
  };
}

function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm:ss')
  }
}

function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}


function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

async function onlyDigits_pawn() {
  if (pawn_search.value !== "") {
    if (pawn_search.value.match(/^[0-9]+$/)) {
      pawn_search.value = pawn_search.value.match(/^[0-9]+$/);
    } else {
      pawn_search.value = pawn_search.value.slice(0, -1);
    }
  } else {
    pawn_search.value = 0;
  }
}

async function onlyDigits_day() {
  if (duration_search.value !== "") {
    if (duration_search.value.match(/^[0-9]+$/)) {
      duration_search.value = duration_search.value.match(/^[0-9]+$/);
    } else {
      duration_search.value = duration_search.value.slice(0, -1);
    }
  } else {
    duration_search.value = 0;
  }
}


async function inputpawn() {
  if (pawn_search.value !== '') {
    // document.getElementById(`input${input}`).value = parseFloat(pawn_search.value).toFixed(2).toLocaleString();
  }
}


async function inputday() {
  if (duration_search.value !== '') {
    // document.getElementById(`input${input}`).value = parseFloat(duration_search.value).toLocaleString();
  }
}

function filterTkstatus(str) {
  let st = ''
  st = assetStatus.value.filter((c) => c.val === str);
  return st[0].lb;
}

function filterloclv(num) {
  let loc = []
  loc = loclist.value.filter((c) => c.level === num);
  return loc;
}

function filterlocparent(uid) {
  let loc = []
  loc = loclist.value.filter((c) => c.parentUid === uid);
  return loc;
}

function filterMatch(str, matchArr) {
  return matchArr.filter((c) => c.name.toLowerCase().includes(str.toLowerCase()));
}

async function selectTpList(num, inp) {
  if (num === '1') {
    listtp1.value = filterMatch(inp, listtp1o.value)
  } else if (num === '2') {
    listtp2.value = filterMatch(inp, listtp2o.value)
  } else if (num === '3') {
    listtp3.value = filterMatch(inp, listtp3o.value)
  } else if (num === '4') {
    listtp4.value = filterMatch(inp, listtp4o.value)
  } else if (num === '5') {
    listtp5.value = filterMatch(inp, listtp5o.value)
  } else if (num === '6') {
    listtp6.value = filterMatch(inp, listtp6o.value)
  }
}



async function selectLocList(num, inp) {
  if (num === '1') {
    listloc1.value = filterMatch(inp, filterloclv(1))
  } else if (num === '2') {
    listloc2.value = filterMatch(inp, filterloclv(2))
  } else if (num === '3') {
    listloc3.value = filterMatch(inp, filterloclv(3))
  } else if (num === '4') {
    listloc4.value = filterMatch(inp, filterloclv(4))
  } else if (num === '5') {
    listloc5.value = filterMatch(inp, filterloclv(5))
  } else if (num === '6') {
    listloc6.value = filterMatch(inp, filterloclv(6))
  }
}


async function checktp1(item) {
  datas.coreBox1tp = ''
  datas.coreBox2tp = ''
  datas.coreBox3tp = ''
  datas.coreBox4tp = ''
  datas.coreBox5tp = ''
  datas.coreBox6tp = ''


  tp1.value = ''
  tp2.value = ''
  tp3.value = ''
  tp4.value = ''
  tp5.value = ''
  tp6.value = ''


  tp2_inp.value = ''
  tp3_inp.value = ''
  tp4_inp.value = ''
  tp5_inp.value = ''
  tp6_inp.value = ''

  listtp2.value = []
  listtp3.value = []
  listtp4.value = []
  listtp5.value = []
  listtp6.value = []

  listtp2o.value = []
  listtp3o.value = []
  listtp4o.value = []
  listtp5o.value = []
  listtp6o.value = []

  tp1.value = item
  datas.coreBox1tp = item.name
  catc_search.value = item.code
  for (let i = 0; i < 100; i++) {
    if (tplist.value.length === 100 || i === 0) {
      await loadAssetList(tp1.value.uid, 2, i)
    } else {
      break
    }
  }

}


async function checktp2(item) {

  datas.coreBox2tp = ''
  datas.coreBox3tp = ''
  datas.coreBox4tp = ''
  datas.coreBox5tp = ''
  datas.coreBox6tp = ''

  tp2.value = ''
  tp3.value = ''
  tp4.value = ''
  tp5.value = ''
  tp6.value = ''

  tp3_inp.value = ''
  tp4_inp.value = ''
  tp5_inp.value = ''
  tp6_inp.value = ''

  listtp3.value = []
  listtp4.value = []
  listtp5.value = []
  listtp6.value = []

  listtp3o.value = []
  listtp4o.value = []
  listtp5o.value = []
  listtp6o.value = []

  tp2.value = item
  datas.coreBox2tp = item.name
  catc_search.value = item.code
  for (let i = 0; i < 100; i++) {
    if (tplist.value.length === 100 || i === 0) {
      await loadAssetList(tp2.value.uid, 3, i)
    } else {
      // console.log('size 3 : ' + listtp3o.value.length)
      break
    }
  }

}


async function checktp3(item) {

  datas.coreBox3tp = ''
  datas.coreBox4tp = ''
  datas.coreBox5tp = ''
  datas.coreBox6tp = ''

  tp3.value = ''
  tp4.value = ''
  tp5.value = ''
  tp6.value = ''

  tp4_inp.value = ''
  tp5_inp.value = ''
  tp6_inp.value = ''

  listtp4.value = []
  listtp5.value = []
  listtp6.value = []

  listtp4o.value = []
  listtp5o.value = []
  listtp6o.value = []

  tp3.value = item
  datas.coreBox3tp = item.name
  catc_search.value = item.code
  for (let i = 0; i < 100; i++) {
    if (tplist.value.length === 100 || i === 0) {
      await loadAssetList(tp3.value.uid, 4, i)
    } else {
      break
    }
  }

}

async function checktp4(item) {

  datas.coreBox4tp = ''
  datas.coreBox5tp = ''
  datas.coreBox6tp = ''

  tp4.value = ''
  tp5.value = ''
  tp6.value = ''

  tp5_inp.value = ''
  tp6_inp.value = ''

  listtp5.value = []
  listtp6.value = []

  listtp5o.value = []
  listtp6o.value = []

  tp4.value = item
  datas.coreBox4tp = item.name
  catc_search.value = item.code
  for (let i = 0; i < 100; i++) {
    if (tplist.value.length === 100 || i === 0) {
      await loadAssetList(tp4.value.uid, 5, i)
    } else {
      break
    }
  }
}


async function checktp5(item) {

  datas.coreBox5tp = ''
  datas.coreBox6tp = ''

  tp5.value = ''
  tp6.value = ''

  tp6_inp.value = ''

  listtp6.value = []

  listtp6o.value = []

  tp5.value = item
  datas.coreBox5tp = item.name
  catc_search.value = item.code
  for (let i = 0; i < 100; i++) {
    if (tplist.value.length === 100 || i === 0) {
      await loadAssetList(tp5.value.uid, 6, i)
    } else {
      break
    }
  }

}

async function checktp6(item) {
  datas.coreBox6tp = ''
  tp6.value = ''
  tp6.value = item
  catc_search.value = item.code
  datas.coreBox6tp = item.name
}


async function onSelectAllLoc() {
  if (allloc.value) {
    datas.coreBox1 = ''
    datas.coreBox2 = ''
    datas.coreBox3 = ''
    datas.coreBox4 = ''
    datas.coreBox5 = ''
    datas.coreBox6 = ''

    loc1.value = ''
    loc2.value = ''
    loc3.value = ''
    loc4.value = ''
    loc5.value = ''
    loc6.value = ''

    loc2_inp.value = ''
    loc3_inp.value = ''
    loc4_inp.value = ''
    loc5_inp.value = ''
    loc6_inp.value = ''

    listloc2.value = []
    listloc3.value = []
    listloc4.value = []
    listloc5.value = []
    listloc6.value = []
    loc_search.value = ''
  }
}

async function checklc1(item) {
  datas.coreBox1 = ''
  datas.coreBox2 = ''
  datas.coreBox3 = ''
  datas.coreBox4 = ''
  datas.coreBox5 = ''
  datas.coreBox6 = ''


  loc1.value = ''
  loc2.value = ''
  loc3.value = ''
  loc4.value = ''
  loc5.value = ''
  loc6.value = ''


  loc2_inp.value = ''
  loc3_inp.value = ''
  loc4_inp.value = ''
  loc5_inp.value = ''
  loc6_inp.value = ''

  listloc2.value = []
  listloc3.value = []
  listloc4.value = []
  listloc5.value = []
  listloc6.value = []

  loc1.value = item
  datas.coreBox1 = item.name
  loc_search.value = item.code
  listloc2.value = filterlocparent(loc1.value.uid)

}


async function checklc2(item) {

  datas.coreBox2 = ''
  datas.coreBox3 = ''
  datas.coreBox4 = ''
  datas.coreBox5 = ''
  datas.coreBox6 = ''

  loc2.value = ''
  loc3.value = ''
  loc4.value = ''
  loc5.value = ''
  loc6.value = ''

  loc3_inp.value = ''
  loc4_inp.value = ''
  loc5_inp.value = ''
  loc6_inp.value = ''

  listloc3.value = []
  listloc4.value = []
  listloc5.value = []
  listloc6.value = []

  loc2.value = item
  datas.coreBox2 = item.name
  loc_search.value = item.code
  listloc3.value = filterlocparent(loc2.value.uid)

}


async function checklc3(item) {

  datas.coreBox3 = ''
  datas.coreBox4 = ''
  datas.coreBox5 = ''
  datas.coreBox6 = ''

  loc3.value = ''
  loc4.value = ''
  loc5.value = ''
  loc6.value = ''

  loc4_inp.value = ''
  loc5_inp.value = ''
  loc6_inp.value = ''


  listloc4.value = []
  listloc5.value = []
  listloc6.value = []

  loc3.value = item
  datas.coreBox3 = item.name
  loc_search.value = item.code
  listloc4.value = filterlocparent(loc3.value.uid)

}

async function checklc4(item) {


  datas.coreBox4 = ''
  datas.coreBox5 = ''
  datas.coreBox6 = ''


  loc4.value = ''
  loc5.value = ''
  loc6.value = ''


  loc5_inp.value = ''
  loc6_inp.value = ''



  listloc5.value = []
  listloc6.value = []

  loc4.value = item
  datas.coreBox4 = item.name
  loc_search.value = item.code
  listloc5.value = filterlocparent(loc4.value.uid)

}


async function checklc5(item) {


  datas.coreBox5 = ''
  datas.coreBox6 = ''

  loc5.value = ''
  loc6.value = ''

  loc6_inp.value = ''

  listloc6.value = []

  loc5.value = item
  datas.coreBox5 = item.name
  loc_search.value = item.code
  listloc6.value = filterlocparent(loc5.value.uid)

}

async function checklc6(item) {
  datas.coreBox6 = ''
  loc6.value = item
  datas.coreBox6 = item.name
  loc_search.value = item.code
}

function cancelBtn() {
  totalQuantity.value = 0;
  totalPawnValue.value = 0;
  totalWeight.value = 0
  datalist.value = []
  totalRows_s.value = ''
  currentPage.value = 1
  catc_search.value = ''
  loc_search.value = ''
  pawnop_search.value = ''
  pawn_search.value = 0
  durationop_search.value = ''
  duration_search.value = 0
  cusUid_search.value = ''
  tk_search.value = ''
  status_search.value = ''
  cusName_search.value = '';
  datas.coreBox1 = ''
  datas.coreBox2 = ''
  datas.coreBox3 = ''
  datas.coreBox4 = ''
  datas.coreBox5 = ''
  datas.coreBox6 = ''
  listloc2.value = []
  listloc3.value = []
  listloc4.value = []
  listloc5.value = []
  listloc6.value = []
  loc1_inp.value = ''
  loc2_inp.value = ''
  loc3_inp.value = ''
  loc4_inp.value = ''
  loc5_inp.value = ''
  loc6_inp.value = ''
  loc1.value = ''
  loc2.value = ''
  loc3.value = ''
  loc4.value = ''
  loc5.value = ''
  loc6.value = ''
  listtp2.value = []
  listtp3.value = []
  listtp4.value = []
  listtp5.value = []
  listtp6.value = []
  listtp2o.value = []
  listtp3o.value = []
  listtp4o.value = []
  listtp5o.value = []
  listtp6o.value = []
  tp1_inp.value = ''
  tp2_inp.value = ''
  tp3_inp.value = ''
  tp4_inp.value = ''
  tp5_inp.value = ''
  tp6_inp.value = ''
  tp1.value = ''
  tp2.value = ''
  tp3.value = ''
  tp4.value = ''
  tp5.value = ''
  tp6.value = ''
  datas.coreBox1tp = ''
  datas.coreBox2tp = ''
  datas.coreBox3tp = ''
  datas.coreBox4tp = ''
  datas.coreBox5tp = ''
  datas.coreBox6tp = ''
  allloc.value = false
  totalTicket.value = 0
}

function cleartp() {
  listtp1.value = []
  listtp2.value = []
  listtp3.value = []
  listtp4.value = []
  listtp5.value = []
  listtp6.value = []
  listtp1o.value = []
  listtp2o.value = []
  listtp3o.value = []
  listtp4o.value = []
  listtp5o.value = []
  listtp6o.value = []
  tp1_inp.value = ''
  tp2_inp.value = ''
  tp3_inp.value = ''
  tp4_inp.value = ''
  tp5_inp.value = ''
  tp6_inp.value = ''
  tp1.value = ''
  tp2.value = ''
  tp3.value = ''
  tp4.value = ''
  tp5.value = ''
  tp6.value = ''
}

function clearloc() {
  listloc1.value = []
  listloc2.value = []
  listloc3.value = []
  listloc4.value = []
  listloc5.value = []
  listloc6.value = []
  loc1_inp.value = ''
  loc2_inp.value = ''
  loc3_inp.value = ''
  loc4_inp.value = ''
  loc5_inp.value = ''
  loc6_inp.value = ''
  loc1.value = ''
  loc2.value = ''
  loc3.value = ''
  loc4.value = ''
  loc5.value = ''
  loc6.value = ''
}

async function loaddataApi() {
  let data = "";

  if (catc_search.value !== '' || loc_search.value !== '' || pawnop_search.value !== '' || pawn_search.value !== ''
    || durationop_search.value !== '' || duration_search.value !== '' || cusUid_search.value !== '' || tk_search.value.trim() !== ''
    || status_search.value !== ''
  ) {
    currentPage.value = 1
  }

  let duration = duration_search.value
  let pawn = pawn_search.value

  if (duration !== '') {
    duration = Number(duration)
  } else {
    duration = 0
  }

  if (pawn !== '') {
    pawn = Number(pawn)
  } else {
    pawn = 0
  }

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    catCode: catc_search.value,
    locCode: loc_search.value,
    pawnValueOperator: pawnop_search.value,
    pawnValue: pawn,
    durationOperator: durationop_search.value,
    duration: duration,
    cusUid: cusUid_search.value,
    tkRefNumber: tk_search.value.trim(),
    status: status_search.value,

  };
  const payload = data;
  // console.log("loadApi p : " + JSON.stringify(payload));
  const response = await storest.stockListFetch(payload);
  // console.log("loadApi r : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        datalist.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        totalQuantity.value = response.data.data.ext.totalQuantity;
        totalPawnValue.value = response.data.data.ext.totalNewPawnValue;
        totalWeight.value = response.data.data.ext.totalWeight;
        totalTicket.value = response.data.data.ext.numOfTicket;
      }
    }
  }
}


async function updatePagination() {
  datalist.value = []
  let duration = duration_search.value
  let pawn = pawn_search.value
  if (duration !== '') {
    duration = Number(duration)
  } else {
    duration = 0
  }

  if (pawn !== '') {
    pawn = Number(pawn)
  } else {
    pawn = 0
  }
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    catCode: catc_search.value,
    locCode: loc_search.value,
    pawnValueOperator: pawnop_search.value,
    pawnValue: pawn,
    durationOperator: durationop_search.value,
    duration: duration,
    cusUid: cusUid_search.value,
    tkRefNumber: tk_search.value.trim(),
    status: status_search.value,

  };
  const payload = data;
  // console.log("loadApi p : " + JSON.stringify(payload));
  const response = await storest.stockListFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        datalist.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        totalQuantity.value = response.data.data.ext.totalQuantity;
        totalPawnValue.value = response.data.data.ext.totalNewPawnValue;
        totalWeight.value = response.data.data.ext.totalWeight;
        totalTicket.value = response.data.data.ext.numOfTicket;
      }
    }
  }
}

async function loadsummaryApi() {
  summarylist.value = []
  const response = await storest.summaryStockFetch();
  // console.log("loadsummaryApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        summarylist.value = response.data.data;
        // console.log("loadsummaryApi : " + JSON.stringify(summarylist.value));
      }
    }
  }
}

loadsummaryApi()

async function openDialogCus() {
  nameThC.value = ''
  nameEnC.value = ''
  docNumberC.value = ''
  cusUid_search.value = ''
  cusName_search.value = ''
  customerlist.value = []
  document.getElementById("searchNameCus").click();
}


async function selectCusAf(item) {
  // console.log('selectCusAf : ' + JSON.stringify(item));
  cusUid_search.value = item.uid
  cusName_search.value = item.nameTh
  document.getElementById("closeModal").click();
}


async function loadCustomerAfListApi() {
  currentPageC.value = 1
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        customerlist.value = listd;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}

async function updatePaginationC() {
  customerlist.value = []
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerlist.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}


async function openDetail(item) {
  workcaseDetail.value = null
  images.value = []
  images_l.value = []
  listinfo.value = []
  info_cat.value = ''
  tk_status.value = ''
  int_cal.value = 0
  location.value = ''
  paymentE.value = ''
  int_period.value = ''
  await getworkCaseInfo(item.wcUid)
  if (workcaseDetail.value != null) {
    loading.value = true
    // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
    await detailPayment(workcaseDetail.value.paymentGroupUid)

    images_l.value = workcaseDetail.value.ticket.images
    listinfo.value = workcaseDetail.value.ticket.collaterals
    await locationInfo()

    if (workcaseDetail.value.transactionType !== 'REDEEM') {
      await calIntInt();
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ปกติ'
      } else if (workcaseDetail.value.status === 'FREEZE') {
        tk_status.value = 'อายัด'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      } else if (workcaseDetail.value.status === 'ACTIVE') {
        tk_status.value = 'กำลังดำเนินการ'
      }

    } else {
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ไถ่ถอน'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      }
    }

    if (images_l.value.length > 0) {
      getTicketImage()
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }
  } else {
    // console.log('workcaseDetail null : ' + item.uid);
    loading.value = false
  }
}


function setGallery() {

  var i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      // console.log('itemImg xxx : ' + i + " " + JSON.stringify(images.value[i].src))

      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      // console.log('itemImg zzz : ' + i + " " + JSON.stringify(images.value[i].src))

      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo1").innerHTML = y;


}


async function getTicketImage() {
  if (images.value.length === 0) {
    let url = []
    images.value = []
    for (let i = 0; i < images_l.value.length; i++) {
      // console.log("logoUid : " + item.images[i].imageUid);
      if (images_l.value[i].imageUid !== "0") {
        url.push(String(st.url_api + "/doc-svc/document/info/" + images_l.value[i].imageUid));
      }
    }
    if (url.length > 0) {
      await getFile(url)
      // setTimeout(3000);
      if (images.value.length > 0) {
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      } else {
        let obj = {
          src: noImg,
          thumbnailImageSrc: noImg,
          alt: '',
          title: ''
        }
        images.value.push(obj)
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      }
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }
  } else {
    setGallery()
    document.getElementById("detailModal").click();
    loading.value = false
  }
}


async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {
        // console.log('file_L : ' + JSON.stringify(file_L))
        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: ''
            }
            images.value.push(obj)
          } else {
            // console.log('no dataFile ')
            loading.value = false
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}

function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  // const trimmedString = string64;
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  var ext = fileName.split('.').pop();
  let type = '';
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  }
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}

async function getworkCaseInfo(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;

  const response = await storewc.workCaseinfoFetch(payload);
  // console.log("getworkCaseInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value = response.data.data
      }
    }
  }
}

async function calIntInt() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
  let postponeDate = ''
  if (workcaseDetail.value.postponeCount != 0) {
    postponeDate = moment(workcaseDetail.value.postponeDate).format('YYYY-MM-DD')
  }
  let data = "";
  data = {
    pawnDate: moment(workcaseDetail.value.workItems[0].startTime).format('YYYY-MM-DD'),
    previousTxDate: '',
    postponeDate: postponeDate,
    pawnValue: workcaseDetail.value.newPawnValue,
    promotionUid: workcaseDetail.value.promotionUid,
    interestUid: workcaseDetail.value.interestUid,
  };
  const payload = data;
  // console.log("calIntInt p : " + JSON.stringify(payload));
  const response = await storei.interestCalculateFetch(payload);
  // console.log("calIntInt r : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        res = response.data.data
        int_cal.value = Number(res.overdueInterest) + Number(res.dueInterest)
        int_period.value = res.interestPeriod
      }
    }
  }
}

async function locationInfo() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));

  let data = "";
  data = {
    shopUid: '',
    uid: workcaseDetail.value.ticket.locationUid,
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storeloc.locationInfoFetch(payload);
  // console.log("locationInfo : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        location.value = response.data.data
      }
    }
  }
}



async function detailPayment(item) {
  // console.log('detailPayment : ' + item);
  let data = "";
  data = {
    uid: item,
  };
  const payload = data;
  // const response = await store.dispatch("unitUpdateFetch", payload);

  const response = await storep.paymentGroupinfoFetch(payload);

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value = response.data.data
        filterPayType()
        // console.log('detailPayment : ' + JSON.stringify(paymentE.value));
      }
    }
  }
}

function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  }
  return side
}

function filterPayType() {
  payment_l.value = []
  let pt = [{ name: 'CASH' },
  { name: 'BANK_TRANSFER' },
  { name: 'CREDIT_CARD' },
  { name: 'CHEQUE' },
  { name: 'PROMPT_PAY_MOBILE' },
  { name: 'PROMPT_PAY_TAX_ID' },
  { name: 'QR_CODE' },
  ]
  let lsum = []
  for (let i = 0; i < pt.length; i++) {
    lsum = filterSumPay(pt[i].name)
    if (lsum.length > 0) {
      payment_l.value.push({ name: pt[i].name, pay: lsum[0].pay, receive: lsum[0].receive, fee: lsum[0].fee })
    } else {
      payment_l.value.push({ name: pt[i].name, pay: 0, receive: 0, fee: 0 })
    }
  }
}

function filterSumPay(str) {
  let nump = 0, numr = 0, numf = 0
  let lp = paymentE.value.payments.filter((c) => c.type === str);
  if (lp.length > 0) {
    let pay = 0
    let receive = 0
    let fee = 0
    for (let i = 0; i < lp.length; i++) {
      pay = pay + lp[i].pay
      receive = receive + lp[i].receive
      fee = fee + lp[i].feeReceive
      // fee = fee + lp[i].fee
    }
    nump = pay
    numr = receive
    numf = fee
  }
  let lockL = []
  lockL.push({ pay: nump, receive: numr, fee: numf })
  // console.log('lockL : ' + JSON.stringify(lockL));
  return lockL
}


async function onClickTab(val) {
  if (val === '1') {
    await setfirstTab()
  } else if (val === '3') {
    await loadsummaryApi()
    await setthirdTab()
  } else if (val === '2') {
    if (search_time.value !== '') {
      if ((search_time.value === 'daily' && search_date.value !== null)
        || (search_time.value === 'monthly' && search_year.value !== '' && search_month.value !== '')) {
        await loadsummaryDateApi()
      }
    }
    await setsecondTab()
  }
}


async function setfirstTab() {

  if (document.getElementById("tab2-tab")) {
    document.getElementById("tab2-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab2")) {
    document.getElementById("tab2").classList.remove('show', 'active')
  }

  if (document.getElementById("tab3-tab")) {
    document.getElementById("tab3-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab3")) {
    document.getElementById("tab3").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.add('show', 'active')
  }
}

async function setthirdTab() {

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.remove('show', 'active')
  }

  if (document.getElementById("tab2-tab")) {
    document.getElementById("tab2-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab2")) {
    document.getElementById("tab2").classList.remove('show', 'active')
  }


  if (document.getElementById("tab3-tab")) {
    document.getElementById("tab3-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab3")) {
    document.getElementById("tab3").classList.add('show', 'active')
  }
}


async function setsecondTab() {

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.remove('show', 'active')
  }


  if (document.getElementById("tab3-tab")) {
    document.getElementById("tab3-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab3")) {
    document.getElementById("tab3").classList.remove('show', 'active')
  }


  if (document.getElementById("tab2-tab")) {
    document.getElementById("tab2-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab2")) {
    document.getElementById("tab2").classList.add('show', 'active')
  }
}

function getLstDayOfMonFnc(date) {
  return new Date(date.getFullYear(), date.getMonth(), 0).getDate()
}

function monthDiff(months) {
  let dateTo, dateFrom
  dateTo = new Date(new Date(new Date().getFullYear()), new Date().getMonth())
  dateFrom = new Date(new Date(new Date(search_year.value).getFullYear()), new Date(months).getMonth())
  return dateTo.getMonth() - dateFrom.getMonth() +
    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}


async function loadsummaryDateApi() {
  stocklist.value = []
  let date = ''
  let data = "";
  if (search_time.value === 'daily') {
    date = moment(new Date(search_date.value)).format('YYYY-MM-DD')
  } else if (search_time.value === 'monthly') {
    let year = Number(search_year.value)
    let endd = getLstDayOfMonFnc(new Date(year, Number(search_month.value), 21))
    date = year + '-' + search_month.value + '-' + endd
  }
  data = {
    date: date
  };
  const payload = data;
  // console.log("summaryDate p : " + JSON.stringify(payload));
  const response = await storest.summaryStockDateFetch(payload);
  // console.log("summaryDate r : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        stocklist.value = response.data.data;
        // console.log("loadsummaryApi : " + JSON.stringify(summarylist.value));
      }
    }
  }
}

function cancelSummarydateBtn() {
  search_time.value = ''
  search_date.value = new Date(new Date().setDate(new Date().getDate() - 1));
  search_year.value = ''
  search_month.value = ''
  stocklist.value = []
}

function changesearch_time() {
  search_date.value = new Date(new Date().setDate(new Date().getDate() - 1));
  search_year.value = ''
  search_month.value = ''
}

function changesearch_year() {
  search_month.value = ''
}

const updateWidths = () => {
  if (parentRef.value && listtp3.value) {
    parentWidth.value = parentRef.value.clientWidth;
    childWidth.value = parentRef.value.clientWidth;
  }
}



onUpdated(() => {
  updateWidths();
});


onMounted(() => {
  updateWidths();
  if (document.getElementById("tab3-tab")) {
    document.getElementById("tab3-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab3")) {
    document.getElementById("tab3").classList.add('show', 'active')
  }
});



</script>

<style lang="scss" scoped>
.item {
  white-space: nowrap;
  display: inline
}

.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #ddd;
  border-top-color: orange;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.data-list {
  position: absolute !important;
  max-height: 200px;
  width: 100% !important;
  overflow-y: auto !important;
}

.cs-auto-input {
  background-color: #fff0ee !important;
  width: 100%;

  .auto-input-search {
    appearance: none;
    background-color: #fff;
    margin-bottom: 0 !important;
  }

  .data-list {
    position: absolute;
    z-index: 100;
    background-color: white;
    box-shadow: 1px 1px 4px rgba($color: #000000, $alpha: 0.2);
    border-radius: 0 0 5px 5px;
    border-top: none;
    font-family: sans-serif;
    padding: 8px;
    max-height: 340px;
    overflow-y: auto;

    li {
      list-style: none;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;
    }

    li:hover,
    li:focus {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
  }
}
</style>
