/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useStockStore = defineStore("stock", {
  actions: {
    async summaryStockFetch() {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/stock/summary";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async stockListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          catCode: payload.catCode, // all under
          locCode: payload.locCode, // all under
          pawnValueOperator: payload.pawnValueOperator, // EQUAL, MORE_THAN, LESS_THAN
          pawnValue: payload.pawnValue, // collateral pawnValue
          durationOperator: payload.durationOperator, // EQUAL, MORE_THAN, LESS_THAN
          duration: payload.duration,
          cusUid: payload.cusUid,
          tkRefNumber: payload.tkRefNumber,
          status: payload.status, // ACTIVE, FREEZE
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/stock/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async summaryStockDateFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/stock/summary/" + payload.date;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
