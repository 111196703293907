<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <ul id="dfTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="tab1-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab"
              aria-controls="tab1" aria-selected="true" @click="onClickTab('1')">
              {{ $t("content.amndeferitems") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="tab3-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab3" type="button" role="tab"
              aria-controls="tab3" aria-selected="false" @click="onClickTab('3')">
              {{ $t("content.deferhis") }}
            </button>
          </li>
        </ul>

        <div id="dfTabContent" class="tab-content">
          <div id="tab1" class="tab-pane fade" role="tabpanel" aria-labelledby="tab1-tab">
            <div class="card mt-3">
              <div class="card-body pt-3">
                <div class="row">
                  <div class="col-lg-3  mt-2">
                    <fieldset>
                      <div class="item">
                        <material-checkbox v-model="time" :checked="time" name="autoSelect" @change="onserachDate">
                          <label class="form-label m-0">{{ $t("content.issuedate") }}</label>
                        </material-checkbox>
                        <VueDatePicker v-model="start_date" :format="format" auto-apply :teleport="true"
                          :disabled="!time" :max-date="new Date()" :enable-time-picker="false" month-name-format="long"
                          :clearable="false" fluid :text-input="textInputOptions" />
                      </div>
                    </fieldset>
                  </div>

                  <div class="col-lg-4 mt-3">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusName_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-4 mt-3">
                    <label class="form-label ms-0">{{ $t("content.ticketno") }}</label>
                    <material-input type="text" v-model="tk_search" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-3 d-flex justify-content-between align-items-center"></div>
                  <div class="col-9 d-flex justify-content-end align-items-center">
                    <div class="me-3">
                      <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                        @click="loadDataApi" :disabled="!time && cusName_search === '' && tk_search === ''">
                        <i class="material-icons material-symbols-outlined">search</i>
                        {{ search }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                        @click="cancelBtn">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ cancel }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.issuedate") }}</th>
                          <th>{{ $t("content.names") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.amnweightvalall") }}</th>
                          <th>{{ $t("content.amnqtyunitall") }}</th>
                          <th>{{ $t("content.amnanalysvalall") }}</th>
                          <th>{{ $t("content.cintrate") }}</th>
                          <th>{{ $t("content.amnduedate") }}</th>
                          <th>{{ $t("content.deferpast") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(row, index) in listpostpone" :key="index">
                          <td class="text-sm font-weight-normal">{{ formatNumberString((currentPage - 1) * perPage +
                            index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.createDate) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.customerName }}
                            </span>
                            <span v-else>
                              {{ row.tkCustomerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.refNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberString(row.quantity) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.newPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.interestPerMonth) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.postponeCount === 0">
                              {{ format_date(row.dueDate) }}
                            </span>
                            <span v-else>
                              {{ format_date(row.postponeDate) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberString(row.postponeCount) }}</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              :title="t('buttons.seed')" @click="openDetail(row)">
                              <i class="material-icons material-symbols-outlined">visibility</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lookupModal" id="detailModal">
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              :title="t('content.managedefer')" @click="showModal(row)">
                              <i class="material-icons material-symbols-outlined">edit_note</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#updateModal" id="addpostpone">
                            </material-button>
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              @click="getHistory(row.uid)" :title="t('content.historyview')">
                              <i class="material-icons material-symbols-outlined">manage_history</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="listpostpone.length === 0">
                          <td colspan="10" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="11" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listpostpone.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                          @click="onClickHandler" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="tab3" class="tab-pane fade" role="tabpanel" aria-labelledby="tab3-tab">
            <div class="card mt-3">
              <div class="card-body pt-3">
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.issuedate") }}</th>
                          <th>{{ $t("content.names") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.amnweightvalall") }}</th>
                          <th>{{ $t("content.amnqtyunitall") }}</th>
                          <th>{{ $t("content.amnanalysvalall") }}</th>
                          <th>{{ $t("content.cintrate") }}</th>
                          <th>{{ $t("content.amnduedate") }}</th>
                          <th>{{ $t("content.amnduedatenew") }}</th>
                          <th>{{ $t("content.amnduedateadd") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>
                          <!-- <th>{{ $t("content.status") }}</th> -->
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(row, index) in listhistory" :key="index">
                          <td class="text-sm font-weight-normal">{{ formatNumberString(index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.createDate) }}</td>
                          <td class="text-sm font-weight-normal">{{ row.customerName }}</td>
                          <td class="text-sm font-weight-normal">{{ row.refNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberString(row.total) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.newPawnValue) }}
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.interestPerMonth) }}
                          </td>
                          <td class="text-sm font-weight-normal">{{ sub_date(row) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_date(row.postponeDate) }}</td>
                          <td class="text-sm font-weight-normal">{{ row.days }}</td>
                          <td class="text-sm font-weight-normal">{{ row.userName }}</td>
                          <!-- <td class="text-sm font-weight-normal">{{ row.status }}</td> -->
                        </tr>
                        <tr v-if="listhistory.length === 0">
                          <td colspan="11" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Update Dialog -->
        <div id="updateModal" class="modal fade" tabindex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.update") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.oldduedate") }}
                        </label>
                      </div>
                      <!-- <material-input id="oldduedate" :value="alertData.oldduedate" type="date" :disabled="true" /> -->
                      <VueDatePicker v-model="postpone_detail.oldduedate" :format="format" auto-apply :teleport="true"
                        :disabled="true" :enable-time-picker="false" month-name-format="long" :clearable="false"
                        :text-input="textInputOptions" fluid />

                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.newduedate") }}
                        </label>
                      </div>
                      <!-- <material-input id="newduedate" :value="alertData.newduedate" type="date" /> -->
                      <VueDatePicker v-model="postpone_detail.postponeDate" :format="format" auto-apply :teleport="true"
                        :enable-time-picker="false" month-name-format="long" :clearable="false" fluid
                        :text-input="textInputOptions" :min-date="new Date(postpone_detail.oldduedate)"
                        @update:model-value="diffDate" />
                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.amnduedateadd") }}
                        </label>
                      </div>
                      <material-input id="increaseday" v-model="postpone_detail.days" type="text" :disabled="true" />
                    </div>

                    <div class="col-12 mt-2">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.note") }}
                        </label>
                      </div>
                      <!-- <material-textarea id="duenote" :value="duenote" class="w-100" type="text" rows="3"
                        multi-line="true" /> -->
                      <Textarea rows="3" v-model="postpone_detail.remark" class="w-100" />

                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="addPostpone" :disabled="postpone_detail.oldduedate >= postpone_detail.postponeDate || postpone_detail.days === '0'
                  || postpone_detail.remark.trim() === ''">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>



        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <!-- <th style="width: 100px">{{ $t("content.ccode") }}</th> -->
                          <th>#</th>
                          <th>{{ $t("content.cnameth") }}</th>
                          <th>{{ $t("content.cnameen") }}</th>
                          <th>{{ $t("content.docno") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td></td>
                          <td>
                            <material-input v-model="nameThC" type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input v-model="nameEnC" type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input v-model="docNumberC" type="text" :label="t('content.docno')" />
                          </td>
                          <td>
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              :disabled="nameThC.length < 2 && nameEnC.length < 2 && docNumberC.length < 5"
                              @click="loadCustomerAfListApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-for="(row, index) in customerlist" :key="index" @click="selectCusAf(row)">
                          <td>{{ formatNumberString((currentPageC - 1) * perPageC + index + 1) }}</td>
                          <td>{{ row.nameTh }}</td>
                          <td>{{ row.nameEn }}</td>
                          <td colspan="2">{{ row.docNumber }}</td>
                        </tr>
                        <tr v-if="customerlist.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="customerlist.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                          :items-per-page="perPageC" @click="onClickHandlerC" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Detail Modal -->
        <div id="lookupModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width:1240px;">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">

                <div class="row">
                  <div class="col-lg-6 mt-2">
                    <div id="lookupImages" class="carousel slide" data-bs-ride="carousel" style="max-width: 520px">
                      <div class="carousel-indicators" id="demo1">
                      </div>
                      <div class="carousel-inner">
                        <ul className="list-unstyled">
                          <li v-for="(item, index) in images.length" :key="index">
                            <span v-if="index === 0">
                              <div class="carousel-item active">
                                <vue-image-zoomer :regular="images[index].src" img-class="img-fluid"
                                  class="d-block w-100" :zoom-amount="3" :click-zoom="true" close-pos="top-right"
                                  message-pos="top" />
                              </div>
                            </span>
                            <span v-else>
                              <div class="carousel-item">
                                <vue-image-zoomer :regular="images[index].src" img-class="img-fluid"
                                  class="d-block w-100" :zoom-amount="3" :click-zoom="true" close-pos="top-right"
                                  message-pos="top" />
                              </div>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#lookupImages"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#lookupImages"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-6 mt-2">
                    <div v-if="workcaseDetail !== null" style="overflow-x:auto;">
                      <div>
                        <table class="table table-sm table-borderless">
                          <tbody>
                            <tr>

                              <td class="fs-14 text-bold">{{ $t("content.ticketno") }} :
                                <span v-if="workcaseDetail.tkRefNumber !== ''">
                                  {{ workcaseDetail.tkRefNumber }} &nbsp;
                                  <span @click='copyBtn()' style="cursor: pointer">
                                    <i class="inline-icon material-icons">content_copy</i>
                                  </span>
                                </span>
                                <span v-else>-</span>
                              </td>
                              <td class="fs-14 text-bold">
                              </td>

                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.trans") }} :
                                {{ checkTransaction(workcaseDetail.transactionType) }}
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.statusticket") }} :
                                <span v-if="workcaseDetail.status === 'FINISH'" style="color:green;">
                                  {{ tk_status }}
                                </span>
                                <span
                                  v-else-if="workcaseDetail.status === 'FREEZE' || workcaseDetail.status === 'TERMINATE'"
                                  style="color:red;">
                                  {{ tk_status }}
                                </span>
                                <span v-else>
                                  {{ tk_status }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount === 0">
                              <td class="fs-14 text-bold"> {{ $t("content.lasttrf") }} : {{
                                format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.amnduedate") }} :
                                <span style="color:red;">
                                  {{ format_date(workcaseDetail.dueDate) }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.lasttrf") }} : {{
                                format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2">
                                {{ $t("content.amnduedate") }} :
                                <span style="color:red;">
                                  {{ format_date(workcaseDetail.dueDate) }} (ขอเลื่อนเป็นวันที่ {{
                                    format_date(workcaseDetail.postponeDate) }})
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2">
                                {{ $t("content.deferpast") }} :
                                <span @click='NewTab()' style="text-decoration: underline;color:red; cursor: pointer">
                                  {{ workcaseDetail.postponeCount }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">
                                {{ $t("content.monthlyprof") }} : {{
                                  formatNumberDecimalString(workcaseDetail.interestPerMonth) }}
                              </td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.currentprof") }} :
                                <span style="color:red;">
                                  {{ formatNumberDecimalString(int_cal) }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">
                                {{ $t("content.prointerest") }} :
                                <span
                                  v-if="workcaseDetail.interestPromotion === null || workcaseDetail.interestPromotion === 0">
                                  -
                                </span>
                                <span style="color:blue;" v-else>
                                  {{ formatNumberDecimalString(workcaseDetail.interestPromotion) }}
                                </span>
                              </td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.periodpromotion") }} :
                                <span v-if="workcaseDetail.promotionDuration === 0">
                                  -
                                </span>
                                <span style="color:blue;" v-else>
                                  {{ workcaseDetail.promotionDuration }} เดือน
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.periodpawn") }} :
                                <span style="color:blue;">
                                  {{ int_period }} เดือน
                                </span>
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.amnbookno") }}
                                <span style="color:blue;">
                                  {{ workcaseDetail.tkVolNo }}
                                </span>
                                &nbsp;{{ $t("content.amnno") }}
                                <span style="color:blue;">
                                  {{ workcaseDetail.tkNo }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.amnqtypiece") }} :
                                {{ formatNumberString(workcaseDetail.ticket.totalQuantity) }}
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.allweight") }} :
                                {{ formatNumberDecimalString(workcaseDetail.ticket.totalWeight) }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.pawnvalpv") }} :
                                <span v-if="workcaseDetail.transactionType === 'PAWN'">
                                  {{ formatNumberDecimalString(0) }}
                                </span>
                                <span v-else>
                                  {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                </span>
                                <!-- <span v-if="workcaseDetail.transactionType === 'REDEEM'">
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span>
                                  <span v-else-if="workcaseDetail.transactionType === 'PAWN'">
                                    {{ formatNumberDecimalString(0) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  </span> -->
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.actpawn3") }}/{{ $t("content.actpawn4") }} :
                                {{ formatNumberDecimalString(workcaseDetail.diffValue) }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.amnanalysval") }} :
                                {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                <!-- <span v-if="workcaseDetail.transactionType !== 'REDEEM'">
                                    {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                  </span> -->
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.amnmaxvalall") }} :
                                {{ formatNumberDecimalString(workcaseDetail.ticket.totalFullValue) }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.statusticketlost") }} :
                                <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> ไม่มี
                                </span>
                                <span v-else style="color:red;">
                                  มี ({{ workcaseDetail.lostCount }})
                                </span>
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.refnolost") }} :
                                <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> -
                                </span>
                                <span v-else style="color:red;">
                                  {{ workcaseDetail.lostRefNumber }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.represent || workcaseDetail.shopRepresent">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.represent") }} :
                                <span style="color:red;">
                                  {{ workcaseDetail.workItems[0].customerName }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.workItems[0].changeName">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.changeName") }} :
                                <span style="color:red;">
                                  {{ workcaseDetail.workItems[0].tkCustomerName }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.amnlocationasset") }} :
                                <span v-if="location !== ''">
                                  {{ location.fullName }}
                                </span>
                                <span v-else>
                                  -
                                </span>

                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.stockno") }} :
                                <span v-if="workcaseDetail.ticket.stockNumber !== null">
                                  {{ workcaseDetail.ticket.stockNumber }}
                                </span>
                                <span v-else>
                                  -
                                </span>

                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.migraref") }} :
                                <span v-if="workcaseDetail.migrateRefNumber !== null">
                                  {{ workcaseDetail.migrateRefNumber }}
                                </span>
                                <span v-else>
                                  -
                                </span>

                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <span v-if="listinfo.length !== 0">
                          <table class="table table-sm table-borderless">
                            <tbody>
                              <tr>
                                <td class="fs-14 text-bold">
                                  <span style="text-decoration:underline;">
                                    {{ $t("content.amnassetdetail") }}
                                  </span>
                                </td>
                              </tr>

                              <tr v-for="(row, index) in listinfo" :key="index">
                                <td class="fs-14 text-bold">
                                  {{ formatNumberString(index + 1) }}. {{ row.remark }}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                        </span>
                        <span v-else>
                          <tr>
                            <td class="fs-14 text-bold">
                              <span style="text-decoration:underline;">
                                {{ $t("content.amnassetdetail") }}
                              </span>
                              : -
                            </td>

                          </tr>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr v-if="paymentE !== ''" />
                <div class="col-12 mt-2" v-if="paymentE !== ''">
                  <div class="fs-14 text-bold">
                    <span style="text-decoration:underline;">
                      {{ $t("content.amnfinanceinfo") }}
                    </span>
                  </div>
                  <pre></pre>
                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-flush">
                        <thead class="thead-light">
                          <tr>
                            <th>#</th>
                            <th>{{ $t("content.ticketno") }}</th>
                            <th>{{ $t("content.amnbiz") }}</th>
                            <th colspan="5" class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amnincome") }}
                              </span>
                            </th>
                            <th colspan="4" class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amnoutcome") }}
                              </span>
                            </th>
                            <th colspan="4" class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.fee") }}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th colspan="3"></th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amncash") }}
                              </span>
                            </th>
                            <th colspan="2" class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amnbanktransfer") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amncreditcard") }}
                              </span>

                            </th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amncheque") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amncash") }}
                              </span>
                            </th>
                            <th colspan="3" class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amnbanktransfer") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amncash") }}
                              </span>
                            </th>
                            <th colspan="2" class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amnbanktransfer") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amncreditcard") }}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th colspan="3"></th>
                            <th style="background-color:#dfffdd;"></th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                {{ $t("content.amnbankacc") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#dfffdd;">
                              <span style="color:black;">
                                QR Code
                              </span>
                            </th>
                            <th colspan="2" style="background-color:#dfffdd;"></th>
                            <th style="background-color:#fecac7;"></th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amnbankacc") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amntransfer_promt_mobile") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#fecac7;">
                              <span style="color:black;">
                                {{ $t("content.amntransfer_promt_taxid") }}
                              </span>
                            </th>
                            <th style="background-color:#CFEBFD;"></th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                {{ $t("content.amnbankacc") }}
                              </span>
                            </th>
                            <th class="align-top text-center" style="background-color:#CFEBFD;">
                              <span style="color:black;">
                                QR Code
                              </span>
                            </th>
                            <th style="background-color:#CFEBFD;"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, index) in paymentE.workCases" :key="index">
                            <template v-if="row.ticket !== null">
                              <td class="text-sm font-weight-normal text-center">{{ formatNumberString(index + 1) }}
                              </td>
                              <td class="text-sm font-weight-normal text-center">{{ row.tkRefNumber }}</td>
                              <td class="text-sm font-weight-normal text-center">{{
                                checkTransaction(row.transactionType) }}</td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[0].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[0].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[1].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[1].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[6].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[6].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[2].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[2].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[3].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[3].receive) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[0].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[0].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[1].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[1].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[4].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[4].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[5].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[5].pay) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[0].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[0].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[1].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[1].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[6].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[6].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[2].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[2].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import ProgressSpinner from 'primevue/progressspinner';
import Textarea from 'primevue/textarea';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, reactive, computed, inject, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import ExampleFive from "@/examples/ExampleOne";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';


import axios from "axios";
import * as st from "@/config/setting.js";
import moment from 'moment'
import noImg from "@/assets/img/noimg.png"

import { usePostponeStore } from '@/stores/postpone'
import { useCustomerStore } from '@/stores/customer'
import { useWorkCaseStore } from '@/stores/workcase'
import { useCategoryStore } from '@/stores/category'
import { useInterestStore } from '@/stores/interest'
import { useLocationStore } from '@/stores/location'
import { usePaymentGroupStore } from '@/stores/paymentgroup'

const storep = usePostponeStore()
const storecus = useCustomerStore()
const storewc = useWorkCaseStore()
const storec = useCategoryStore()
const storei = useInterestStore()
const storel = useLocationStore()
const storepay = usePaymentGroupStore()

const { t } = useI18n();
const swal = inject('$swal')

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRows_sC = ref();

const nameThC = ref('')
const nameEnC = ref('')
const docNumberC = ref('')


const save = computed(() => t("buttons.save"));
const cancel = computed(() => t("buttons.canc"));
const search = computed(() => t("buttons.sear"));


const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val);
};

const diffDate = () => {
  var start = moment(new Date(postpone_detail.value.oldduedate)).format('YYYY-MM-DD');
  var end = moment(new Date(postpone_detail.value.postponeDate));
  var diff = end.diff(start, "days")
  postpone_detail.value.days = String(Number(diff + 1))
  // postpone_detail.value.days = String(end.diff(start, "days"))
  return;
};

const btnSearch = computed(() => t("buttons.sear"));
const btnHistory = computed(() => t('buttons.seed') + t("content.deferhis"));

const start_date = ref(new Date());
const cusUid_search = ref('');
const cusName_search = ref('');
const tk_search = ref('');

const listprint = ref([]);
const listpostpone = ref([]);
const listhistory = ref([]);
const listinfo = ref([]);
const customerlist = ref([])
const workcaseDetail = ref(null);
const info_cat = ref('');
const int_cal = ref(0);
const time = ref(false);
const tk_status = ref('');
const location = ref('');
const int_period = ref('');

const images = ref([])
const images_l = ref([])

const payment_l = ref([])
const paymentE = ref('');

const loading = ref(false);


const postpone_detail = ref({
  uid: '',
  workCaseUid: '', // always required
  postponeDate: '',
  days: '',
  remark: '',
  version: '',
  oldduedate: '',
  increaseday: '0',
  postponeCount: '0'
})

const onClickHandler = () => {
  updatePagination()
};

const onClickHandlerC = () => {
  updatePaginationC()
};

const onserachDate = () => {
  start_date.value = new Date();
};

const NewTab = async () => {
  sessionStorage.removeItem('deferWorkcaseUid');
  sessionStorage.setItem("deferWorkcaseUid", workcaseDetail.value.uid);
  if (sessionStorage.getItem('deferWorkcaseUid') !== null) {
    const win = window.open("#/generals/defer_items", "_blank");
    win.focus();
    sessionStorage.removeItem('deferWorkcaseUid');
  }
}

function copyBtn() {
  navigator.clipboard.writeText(workcaseDetail.value.tkRefNumber);
}


function cancelBtn() {
  time.value = false;
  start_date.value = new Date(new Date().setHours(0, 0, 0, 0));
  cusUid_search.value = '';
  cusName_search.value = '';
  tk_search.value = '';
  currentPage.value = 1
  listpostpone.value = []
  listhistory.value = []
  totalRows_s.value = ''
}


async function onClickTab(val) {
  if (val === '1') {
    if (time.value || cusName_search.value !== '' || tk_search.value !== '') {
      loadDataApi()
    }
    await setfirstTab()
  } else if (val === '3') {
    await setthirdTab()
  }
}



const loadDataApi = async (val) => {
  let data = "";
  let sd = ''
  let ed = ''
  if (time.value) {
    sd = moment(new Date(start_date.value)).format('YYYY-MM-DD') + "T00:00:00"
    ed = moment(new Date(start_date.value)).format('YYYY-MM-DD') + "T23:59:59"
  }
  if (time.value || cusUid_search.value !== '' || tk_search.value) {
    currentPage.value = 1
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    cusUid: cusUid_search.value,
    refNumber: tk_search.value,
    fromDate: sd,
    toDate: ed,
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storep.postPonesearchFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listpostpone.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}


const checkNewTab = async () => {
  if (sessionStorage.getItem('deferWorkcaseUid') !== null) {
    let data = sessionStorage.getItem('deferWorkcaseUid')
    sessionStorage.removeItem('deferWorkcaseUid');
    await getHistory(data)
    // console.log("deferWorkcaseUid : " + sessionStorage.getItem('deferWorkcaseUid'));
  } else {
    await onClickTab('1')
    // console.log('2222')
  }
}

checkNewTab()

// const loadDataPrintApi = async () => {
//   // await new Promise((res) => setTimeout(res, 500));
//   let data = "";
//   data = {
//     uid: "",
//   };
//   const payload = data;
//   // const response = await store.dispatch("categorylistPrintFetch", payload);
//   const response = await storec.categorylistPrintFetch(payload);
//   if (response) {
//     if (response.status === 200) {
//       if (response.data.rtnDesc === "success") {
//         listprint.value = response.data.data;
//         // console.log('Listdata : ' + JSON.stringify(listprint.value))
//       }
//     }
//   }
// };

// loadDataPrintApi()


async function openDetail(item) {
  workcaseDetail.value = null
  images.value = []
  images_l.value = []
  listinfo.value = []
  info_cat.value = ''
  tk_status.value = ''
  int_cal.value = 0
  location.value = ''
  int_period.value = ''
  await getworkCaseInfo(item.uid)
  if (workcaseDetail.value != null) {
    loading.value = true
    // console.log('workcaseDetail : ' + JSON.stringify(item));
    await detailPayment(workcaseDetail.value.paymentGroupUid)
    images_l.value = workcaseDetail.value.ticket.images
    listinfo.value = workcaseDetail.value.ticket.collaterals
    await locationInfo()
    if (workcaseDetail.value.transactionType !== 'REDEEM') {
      await calIntInt();
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ปกติ'
      } else if (workcaseDetail.value.status === 'FREEZE') {
        tk_status.value = 'อายัด'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      } else if (workcaseDetail.value.status === 'ACTIVE') {
        tk_status.value = 'กำลังดำเนินการ'
      }

    } else {
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ไถ่ถอน'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      }
    }

    if (images_l.value.length > 0) {
      getTicketImage()
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }
  } else {
    // console.log('workcaseDetail null : ' + item.uid);
    loading.value = false
  }
}


async function openDialogCus() {
  nameThC.value = ''
  nameEnC.value = ''
  docNumberC.value = ''
  cusUid_search.value = ''
  cusName_search.value = ''
  customerlist.value = []
  document.getElementById("searchNameCus").click();
}


async function getTicketImage() {

  if (images.value.length === 0) {
    let url = []
    images.value = []
    for (let i = 0; i < images_l.value.length; i++) {
      // console.log("logoUid : " + item.images[i].imageUid);
      if (images_l.value[i].imageUid !== "0") {
        url.push(String(st.url_api + "/doc-svc/document/info/" + images_l.value[i].imageUid));
      }
    }

    if (url.length > 0) {
      await getFile(url)
      if (images.value.length > 0) {
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      } else {
        let obj = {
          src: noImg,
          thumbnailImageSrc: noImg,
          alt: '',
          title: ''
        }
        images.value.push(obj)
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      }
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }

  } else {
    setGallery()
    document.getElementById("detailModal").click();
    loading.value = false
  }
}

async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {

        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: ''
            }
            images.value.push(obj)
          } else {
            // console.log('no dataFile ')
            loading.value = false
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}

function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  // const trimmedString = string64;
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  var ext = fileName.split('.').pop();
  let type = '';
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  }
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}

async function getworkCaseInfo(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;

  const response = await storewc.workCaseinfoFetch(payload);
  // console.log("getworkCaseInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value = response.data.data
      }
    }
  }
}

function setGallery() {

  var i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      // console.log('itemImg xxx : ' + i + " " + JSON.stringify(images.value[i].src))
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      // console.log('itemImg zzz : ' + i + " " + JSON.stringify(images.value[i].src))
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo1").innerHTML = y;


}

async function getHistory(uid) {
  listhistory.value = []
  let data = "";
  data = {
    uid: uid,
  };

  const payload = data;
  const response = await storep.postPonehistoryFetch(payload);
  // console.log("getHistory : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data;
      }
    }

    await setthirdTab()
  }
}

async function calIntInt() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
  let postponeDate = ''
  if (workcaseDetail.value.postponeCount != 0) {
    postponeDate = moment(workcaseDetail.value.postponeDate).format('YYYY-MM-DD')
  }
  let data = "";
  data = {
    pawnDate: moment(workcaseDetail.value.workItems[0].startTime).format('YYYY-MM-DD'),
    previousTxDate: '',
    postponeDate: postponeDate,
    pawnValue: workcaseDetail.value.newPawnValue,
    promotionUid: workcaseDetail.value.promotionUid,
    interestUid: workcaseDetail.value.interestUid,
  };
  const payload = data;
  const response = await storei.interestCalculateFetch(payload);
  // console.log("calIntInt : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        res = response.data.data
        int_cal.value = Number(res.overdueInterest) + Number(res.dueInterest)
        int_period.value = res.interestPeriod
      }
    }
  }
}

async function locationInfo() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));

  let data = "";
  data = {
    shopUid: '',
    uid: workcaseDetail.value.ticket.locationUid,
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storel.locationInfoFetch(payload);
  // console.log("locationInfo : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        location.value = response.data.data
      }
    }
  }
}

async function detailPayment(item) {
  // console.log('detailPayment : ' + item);
  let data = "";
  data = {
    uid: item,
  };
  const payload = data;
  // const response = await store.dispatch("unitUpdateFetch", payload);

  const response = await storepay.paymentGroupinfoFetch(payload);

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value = response.data.data
        filterPayType()
        // console.log('detailPayment : ' + JSON.stringify(paymentE.value));
      }
    }
  }
}


function filterPayType() {
  payment_l.value = []
  let pt = [{ name: 'CASH' },
  { name: 'BANK_TRANSFER' },
  { name: 'CREDIT_CARD' },
  { name: 'CHEQUE' },
  { name: 'PROMPT_PAY_MOBILE' },
  { name: 'PROMPT_PAY_TAX_ID' },
  { name: 'QR_CODE' },
  ]
  let lsum = []
  for (let i = 0; i < pt.length; i++) {
    lsum = filterSumPay(pt[i].name)
    if (lsum.length > 0) {
      payment_l.value.push({ name: pt[i].name, pay: lsum[0].pay, receive: lsum[0].receive, fee: lsum[0].fee })
    } else {
      payment_l.value.push({ name: pt[i].name, pay: 0, receive: 0, fee: 0 })
    }
  }
}

function filterSumPay(str) {
  let nump = 0, numr = 0, numf = 0
  let lp = paymentE.value.payments.filter((c) => c.type === str);
  if (lp.length > 0) {
    let pay = 0
    let receive = 0
    let fee = 0
    for (let i = 0; i < lp.length; i++) {
      pay = pay + lp[i].pay
      receive = receive + lp[i].receive
      fee = fee + lp[i].feeReceive
      // fee = fee + lp[i].fee
    }
    nump = pay
    numr = receive
    numf = fee
  }
  let lockL = []
  lockL.push({ pay: nump, receive: numr, fee: numf })
  // console.log('lockL : ' + JSON.stringify(lockL));
  return lockL
}



function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm:ss')
  }
}

function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}

function sub_date(item) {
  if (item) {
    var d = new Date(item.postponeDate);
    // var x = Number(item.days);
    var x = Number(item.days) - 1;
    d.setDate(d.getDate() - x);
    return moment(String(d)).local().format('DD/MM/YYYY')
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}



async function setfirstTab() {
  if (document.getElementById("tab3-tab")) {
    document.getElementById("tab3-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab3")) {
    document.getElementById("tab3").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.add('show', 'active')
  }
}

async function setthirdTab() {

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.remove('show', 'active')
  }

  if (document.getElementById("tab3-tab")) {
    document.getElementById("tab3-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab3")) {
    document.getElementById("tab3").classList.add('show', 'active')
  }

}

function showModal(item) {
  clearItem();
  postpone_detail.value.workCaseUid = item.uid
  postpone_detail.value.postponeCount = item.postponeCount
  postpone_detail.value.days = '0'
  if (postpone_detail.value.postponeCount > 0) {
    postpone_detail.value.postponeDate = new Date(item.postponeDate)
    postpone_detail.value.oldduedate = new Date(item.postponeDate)
  } else {
    postpone_detail.value.postponeDate = new Date(item.dueDate)
    postpone_detail.value.oldduedate = new Date(item.dueDate)
  }
  document.getElementById("addpostpone").click();
}

function clearItem() {
  postpone_detail.value = {
    uid: '',
    workCaseUid: '', // always required
    postponeDate: '',
    days: '',
    remark: '',
    version: '',
    oldduedate: '',
    increaseday: '0',
    postponeCount: '0'
  }
}




async function addPostpone() {
  const payload = postpone_detail.value;
  // console.log('addPostpone p : ' + JSON.stringify(payload));
  const response = await storep.postPoneAddFetch(payload);
  // console.log('addPostpone r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }

}

async function selectCusAf(item) {
  // console.log('selectCusAf : ' + JSON.stringify(item));
  cusUid_search.value = item.uid
  cusName_search.value = item.nameTh
  document.getElementById("closeModal").click();
}


async function loadCustomerAfListApi() {
  currentPageC.value = 1
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        customerlist.value = listd;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}

async function updatePagination() {
  listpostpone.value = []
  let data = "";
  let sd = ''
  let ed = ''
  if (time.value) {
    sd = moment(new Date(start_date.value)).format('YYYY-MM-DD') + "T00:00:00"
    ed = moment(new Date(start_date.value)).format('YYYY-MM-DD') + "T23:59:59"
  }

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    cusUid: cusUid_search.value,
    refNumber: tk_search.value,
    fromDate: sd,
    toDate: ed,
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storep.postPonesearchFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listpostpone.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}

async function updatePaginationC() {
  customerlist.value = []
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerlist.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}

function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  }
  return side
}

onMounted(() => {
  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.add('show', 'active')
  }
});



</script>

<style lang="scss" scoped>
.item {
  white-space: nowrap;
  display: inline
}

.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #ddd;
  border-top-color: orange;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}
</style>
