/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useCloseBankStore = defineStore("closebank", {
  actions: {
    async closeBankListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "closingDateTime:ge": payload.start,
          "closingDateTime:le": payload.end,
          bankAccUid: payload.bankAccUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/bank/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeBankInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/bank/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeBankAddFetch(payload) {
      const body = {
        bankAccUid: payload.bankAccUid,
        remark: payload.remark,
        closingDateTime: payload.closingDateTime, //"2024-11-10T15:00:00",
        totalAmount: payload.totalAmount,
        refDocUid: payload.refDocUid,
        bankAccName: payload.bankAccName,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/bank/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeBankUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        bankAccUid: payload.bankAccUid,
        closingDateTime: payload.closingDateTime,
        totalBillCount: payload.totalBillCount,
        totalAmount: payload.totalAmount,
        remark: payload.remark,
        fundStatus: payload.fundStatus,
        version: payload.version,
        refDocUid: payload.refDocUid,
        bankAccName: payload.bankAccName,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/bank/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeBankApproveFetch(payload) {
      const body = {};
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/bank/approve/" + payload.uid;
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeBankDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/bank/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeBankOpenBalanceFetch(payload) {
      const body = {
        bankAccUid: payload.bankAccUid,
        closingDateTime: payload.closingDateTime,
        bankAccName: payload.bankAccName,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/bank/open-balance";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async closeBankNewOpenshopFetch(payload) {
      const body = {
        bankAccUid: payload.bankAccUid,
        closingDateTime: payload.closingDateTime,
        remark: "Open Shop",
        totalAmount: payload.totalAmount,
        bankAccName: payload.bankAccName,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/close-tx/bank/new/open-shop";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
