/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useDashboardStore = defineStore("dashboard", {
  actions: {
    async dashboardInfoFetch(payload) {
      const body = {
        fromDate: payload.fromDate,
        toDate: payload.toDate,
        shopUid: payload.shopUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/dashboard/info";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async dashboardDueFetch(payload) {
      const body = {
        dueDate: payload.dueDate,
        lastOpenDate: payload.lastOpenDate,
        shopUid: payload.shopUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/dashboard/due";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async overdueListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          lastOpenDate: payload.lastOpenDate,
          shopUid: payload.shopUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/dashboard/overdue/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async redeemReportFetch(payload) {
      const body = {
        fromDate: payload.fromDate,
        toDate: payload.toDate,
        shopUid: payload.shopUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/dashboard/redeem/report";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
